import { createSlice } from '@reduxjs/toolkit';

export const showMcategorySlice = createSlice({
  name: 'showCategory',
  initialState: false,
  reducers: {
    openCategory: (state) => true,
    closeCategory: (state) => false,
  },
});

export const { openCategory, closeCategory } = showMcategorySlice.actions;

export default showMcategorySlice.reducer;