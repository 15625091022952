import React,{useState,useEffect} from "react";
import ScrollButton from "../../../../components/scrollbutton/ScrollButton";
import Subheader from "../../../../components/subheader/Subheader";
import { Footer } from "../../../../components/footer/footer";
import firstitem from "../../../../assets/dummy-movie.png";
import { ReactComponent as YourIcon } from "../../../../assets/mdi_calendar.svg";
import { ReactComponent as YourIcon1 } from "../../../../assets/time.svg";
import "./Viewdetails.css";
import { ReactComponent as YourIcon2 } from "../../../../assets/code-compare-solid.svg"
import  ViewdetailGraph from "./ViewdetailGraph";
import {ModalTrading} from "./trade/ModalTrading";
import Navbarr from "../../../../components/header/Navbarr";
import {cookieNames} from "../../../../Config/config";
import {imgpath} from "../../../../Config/config";
import {leaguedetailsGet,livedetailsplayerscGet,playermoviesGet,weeklyscoresGet,weeklyscoresGett,templatedetailsGet} from "../../../../api/handleapi"
import { Errormsg } from "../../../../components/tostifymsg/Errrormsg";
import { ToastContainer } from "react-toastify";
import { setSecondpmovie } from "../../../../feature/showSlice/secondpmovieSlice";
import { setLoginpmovie} from "../../../../feature/showSlice/loginpmovieSlice";
import { useSelector,useDispatch } from "react-redux";
import { openModal } from '../../../../feature/showSlice/showmodaltradeSlice';
import { openModalmdetails } from '../../../../feature/showSlice/showmodaltrademoviedetailsSlice';
import { useNavigate } from "react-router-dom";
import profileimg1 from "../../../../assets/large profileicon.svg";
import Dummyads from "../../../../components/google-AdSense/Dummyads";
import { ModalTradeMoviedetails } from "./trade/ModalTradeMoviedetails";
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import {setTrademoviedetailsforid} from '../../../../feature/showSlice/trademoviedetailsforidSlice';
import { useCategory } from "../../../../components/useCategory";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import Loader from "../../../../components/loader/Loader";
import { reloginMsg,nomoviesfound, errorstatuscheck } from "../../../../components/language/Language";
import { handle401Error } from "../../../../components/handle401Error";
import Googleadshorizontal from "../../../../components/google-AdSense/Googleadshorizontal";


function MyleagueliveDetailpage() {
const Navigate=useNavigate();
const { id } = useParams();
  const League_id=id
  const trademoviedetailsforid=useSelector((state) => state.trademoviedetailsforid);
  const togglestate=useSelector((state) => state.togglestate);
  const secondpusername=Cookies.get("secondpusername")
  const [allplayerweeklyscores,setallPlayerweeklyscores]=useState([]);
 const [playerweeklyscores,setPlayerweeklyscores]=useState([]);
 const [otherplayerweeklyscores,setotherPlayerweeklyscores]=useState([]);
 const [templatename,setTemplatename]=useState();
 const userid=Cookies.get("id")
  const [l_details, setL_details] = useState([]);
  const [playersc, setPlayersc] = useState([]);
  const [otherplayername, setOtherPlayername] = useState("");
  const dispatch=useDispatch();
  const secondpmovie=useSelector((state) => state.secondpmovie);
  const loginpmovie=useSelector((state) => state.loginpmovie);
  const [showModalswap, setShowModalswap] = useState(false);
  const [showModaldrop, setShowModaldrop] = useState(false);
const [loading,setLoading]=useState(false)
const Categoryoptions=useCategory();


  const openModaldrop = () => {
    setShowModaldrop(true); 
  };

  const openModalswap = () => {
    setShowModalswap(true); 
  };

  const openModalTrading = () => {
         dispatch(openModal(true)); 
  };


  const openModalMdetails = (movieId) => {
    dispatch(openModalmdetails(true)); 
  
    dispatch(setTrademoviedetailsforid(movieId))
};

  const [isOtherDivOpen, setOtherDivOpen] = useState(false);

  const handleIconClick = () => {
    setOtherDivOpen(!isOtherDivOpen);
  };


  const templateFun = async (template_id) => {
    try {
      const response = await templatedetailsGet(template_id); 
          setTemplatename(response?.data?.data?.templateName)
    } catch (error) {
      if(error?.message==errorstatuscheck){         
        await Errormsg(reloginMsg);
        await handle401Error(cookieNames)
        await Navigate("/");
      }
    
    }
  };

  
  useEffect(() => {
    const leaguedFun = async (League_id) => {
      try {
        setLoading(true);
        const response = await leaguedetailsGet(League_id); 
        setL_details(response?.data?.data);
        templateFun(response?.data?.data?.templateTypeId)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if(error?.message==errorstatuscheck){         
          await Errormsg(reloginMsg);
          await handle401Error(cookieNames)
          await Navigate("/");
        }
        
      }
    };
    leaguedFun(League_id);
      }, [League_id]);

      
      const pweeklyScores= async (League_id) => {
        try {
          const response = await weeklyscoresGett(League_id);
          if(response?.status === 200){
            setallPlayerweeklyscores(response?.data?.data)
        }
                    
        } catch (error) {
          if(error?.message==errorstatuscheck){         
            await Errormsg(reloginMsg);
            await handle401Error(cookieNames)
            await Navigate("/");
          }
          
        }
      };

      const pweeklyScoress= async (League_id,userid) => {
        try {
          const response = await weeklyscoresGet(League_id,userid);
          if(response?.status === 200){
          if(userid==Cookies.get("id")){
            setPlayerweeklyscores(response?.data?.data[0]?.scores);
          } 
        }     
        } catch (error) {
          if(error?.message==errorstatuscheck){         
            await Errormsg(reloginMsg);
            await handle401Error(cookieNames)
            await Navigate("/");
          }
         
        }
      };

      const pweeklyScoresss= async (League_id,userid) => {
        try {
          const response = await weeklyscoresGet(League_id,userid);
          if(response?.status === 200){
            setotherPlayerweeklyscores(response?.data?.data[0]?.scores)
            
        }     
        } catch (error) {
          if(error?.message==errorstatuscheck){         
            await Errormsg(reloginMsg);
            await handle401Error(cookieNames)
            await Navigate("/");
          }
      
        }
      };
      
    
  useEffect(() => {
    pweeklyScores(League_id)
      }, [League_id]);

      useEffect(() => {
        pweeklyScoress(League_id,userid);
        pweeklyScoresss(League_id,userid);
          }, [League_id,userid]);
    
      useEffect(() => {
        const playersc_Fun = async (League_id) => {
          try {
            const response = await livedetailsplayerscGet(League_id); 
            setPlayersc(response?.data?.data);
         
          } catch (error) {
            return;
          }
        };
        playersc_Fun(League_id);
          }, [League_id]);

          const movieslist_Fun = async (League_id,userid) => {
            try {
              const response = await playermoviesGet(League_id,userid); 
 
              dispatch(setLoginpmovie(response?.data?.data?.logedInPlayerMovies));
            } catch (error) {
              return;
            }
          }

          useEffect(() => {
            movieslist_Fun(League_id,userid);
            },[]);

            const comparemovie = async(League_id,s_userid,s_username) => {
              try {
                const response = await playermoviesGet(League_id,s_userid); 
                if (response?.status === 200) {
                dispatch(setSecondpmovie(response?.data?.data?.logedInPlayerMovies));
                pweeklyScoress(League_id,userid);
                pweeklyScoresss(League_id,s_userid);
        
                Cookies.set("secondpusername",s_username)
                setOtherPlayername(s_username)
            
                Cookies.set("secondpid",response?.data?.data?.playerId)
                handleIconClick()
              }
              else{
                Errormsg(nomoviesfound);
              }
              } catch (error) {
               
                return;
              }
            };
        
  
const noofjoinplayers=playersc?.length
const filteredData = playersc?.filter(player => player?.userId == userid);

  return (
    <div>
      <Navbarr />
      <ToastContainer />
     {togglestate?<ModalTrading League_id={League_id}  showModalswap={showModalswap} setShowModalswap={setShowModalswap} showModaldrop={showModaldrop} setShowModaldrop={setShowModaldrop} trademdetailsforid={trademoviedetailsforid} setTrademdetailsforid={setTrademoviedetailsforid} />:null}
      <ModalTradeMoviedetails trademdetailsforid={trademoviedetailsforid} setTrademdetailsforid={setTrademoviedetailsforid} />
      <div className="after-nav-dsubheader containers con_div">
        <Subheader />
        <div className="detailp-league-card">
          <div className="card d-Cardf">
            <div className="row g-0 main-row ">
              <div className="col-xl-1 col-lg-3 col-md-12 col-sm-12 imag-cardclass">
                <img src={imgpath+l_details?.image }  onError={(e) => {
    e.target.onerror = null;
    e.target.src = firstitem }}alt="img" className="detleague-card-img" />
              </div>
              <div className="col-xl-11 col-lg-10 col-md-12 col-sm-12">

       <div className="league-card-content content_live">
       <div className="three_Item_live">
                  <div className="items blockitem">
                    <p className="card-title">{l_details?.leagueName}</p>
                    <div className="hello">
                    <button className="view_live-btn">LIVE</button>
                    </div>
                    <div className="custom-detail">
                      <h6 className="card-title">
                       
                        {templatename}
                        </h6>
                      <h6 className="card-title"> No of Players: {noofjoinplayers}/{l_details?.entries}</h6>
                    </div>
                  </div>
                  <div className="mt-20">
                    <a
                      href="##"
                      className="btn btn-primary detail-PP-btn">
                      {l_details?.visibility}
                    </a>
                  </div>
                  <div className="leaguedatetime2">
                  <div className="dleague-card-tdate">
                  <span>Created:</span>
                    <div className="view_live_time" >
                    
                      <YourIcon />
                      <span className="data"> 
                      { moment(new Date(l_details?.createdDate + 'Z')).format("dddd, MMMM D, YYYY")}
                       </span>
                    </div>
                    <div className="view_live_time">
                      <YourIcon1 />
                      <span className="time font_time">
                      { moment(new Date(l_details?.createdDate + 'Z')).format("h:mm a")}
                         </span>
                    </div>
                  </div>
                  <div className="dleague-card-tdate">
                  <span>League End:</span>
                    <div className="view_live_time" >
                      <YourIcon />
                      <span className="data"> 
                      { moment(new Date(l_details?.endDateTime + 'Z')).format("dddd, MMMM D, YYYY")}
                       </span>
                    </div>
                    <div className="view_live_time">
                      <YourIcon1 />
                      <span className="time font_time">
                      { moment(new Date(l_details?.endDateTime + 'Z')).format("h:mm a")}
                         </span>
                    </div>

                  </div>
                  </div>
                </div>
       </div>
                
              </div>
            </div>
            <hr className="dleague-card-hr" />
            <nav className="dleague-card-nav">
              <p className="cdetail-text-content">
                {l_details?.description}
              </p>
            </nav>
          </div>
        </div>
      
        {/* <Dummyads/> */}
        <Googleadshorizontal props="pt-2 pb-2 text-center"/>
<div className="row">
  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
        <div className="card position_card">
          <nav className="navbar  nav-positionn">
                Your Position
              </nav>
              <div className="Position_seconddiv ">
                <table className="all_playerlist">
  <thead>
  {filteredData?.map(player => (
    <tr key={player?.userId}>
      <td className="itemss your-position"> <img
              src={imgpath+player?.userImage}
              className="profileicon "
             
              alt="proimg" onError={(e) => {
                e.target.onerror = null;
                e.target.src = profileimg1 }}/></td>
      <td className="itemss items-name">
        {player?.userName?.charAt(0).toUpperCase()+ player?.userName?.slice(1)}
        </td>
      <td className="score itemss your-position">{player?.finalScore}</td>
    </tr>
  ))}
  </thead>
</table> 
             
              </div> 
            </div>
            <div className="card position_card">
              <nav className="navbar  nav-positionn ">
                 All Players
              </nav>
            <div className="table-container"> 
    <table className="all_playerlist">
  <thead>
    <tr>
      <td className="itemss">Rank</td>
      <td className="itemss">Profile</td>
      <td className="itemss nameitems" >Name</td>
      <td className="itemss">Score</td>
      <td className="itemss">Compare</td>
    </tr>
  </thead>
  <tbody >
 {playersc?.map((data,index)=>(
    <tr className="Player_titlee" key={index} >
      <td className="itemss">{data?.rank}</td>
      <td className="itemss"><img
              src={imgpath+data?.userImage}
              className="profileicon"
           
              alt="proimg" onError={(e) => {
                e.target.onerror = null;
                e.target.src = profileimg1 }}/></td>               
                      <td className="itemsss">
                        {data?.userName?.charAt(0).toUpperCase()+ data?.userName?.slice(1)}
                        </td>
      <td className="scoree itemsss">{data?.finalScore}</td>
      {data?.userId==userid?"":<td className="campareicons itemsss"><YourIcon2 onClick={()=>comparemovie(data?.leagueId,data?.userId,data?.userName)} className="campareicons"/></td>}
    </tr>  ))} 
  </tbody>
</table>
            </div>
  
            </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div className="card position_card">
                <nav className="navbar  nav-positionn ">
                Players Progress
              </nav>
              <ViewdetailGraph playersc={playersc} otherplayername={otherplayername} allplayerweeklyscores={allplayerweeklyscores} isOtherDivOpen={isOtherDivOpen} playerweeklyscores={playerweeklyscores} otherplayerweeklyscores={otherplayerweeklyscores}/>
            </div>
            </div>
            </div>
<div className="compare_list_div">
  <div className="row">
    <div className= "col-xl-6 col-lg-12 col-md-12 col-sm-12">
      <div className="heading_live_list">
        <h6>My Movies</h6>
        <div className="dropdown">
  <button className="btn btn-secondary dropdown-toggle manage_movies-btns" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  Manage Roster
  </button>
  <div className="dropdown-menu view-details-dropdown items_dropdown_view_details" aria-labelledby="dropdownMenuButton">
    <button disabled={isOtherDivOpen==true?false:true} className="dropdown-item" onClick={openModalTrading}>Trades</button>
    <a className="dropdown-item" onClick={openModaldrop}>Pickups</a>
    <a className="dropdown-item" onClick={openModalswap}>Swaps</a>
  </div>
</div>
        </div>
   
    <div className="card position_cardd">
   <div className="table-containerr"> 
    <table className="all_playerlist">
  <thead>
    <tr className="movieslist_headings">
      <td className="itemss">Movie</td>
      <td className="itemss">Category</td>
      <td className="itemss nameitems" >Release Date</td>
      <td className="itemss">Score</td>
      <td className="itemss">
      Production Status</td>
    </tr>
  </thead>
  <tbody >
  {loginpmovie?.length!=0?
    loginpmovie?.map((data,index)=>(
    <tr className="Player_title" key={index} >
      <td className="itemss moviesnamefordetails" onClick={()=>openModalMdetails(data?.movieId)}>
      
     {data?.movieName.length > 30 ? data?.movieName.substring(0, 30) + '...' : data?.movieName}
      </td>
      <td className="itemss">{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>            
      <td className="itemsss">
      { (moment(new Date(data?.releaseDate + 'Z')).format("MMMM D, YYYY")!=
"Invalid date"? moment(new Date(data?.releaseDate + 'Z')).format("MMMM D, YYYY"):"N/A")}
        
          </td>
  
      <td className="campareicons itemsss">{data?.finalScore}</td>
      <td className="campareicons itemsss">   {data?.production}</td>
    </tr>
    )):<div className="no_movies_found">No Movies found</div>}  
  </tbody>
</table>
            </div>
   </div>
    </div>
    {isOtherDivOpen &&  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
    <div className="heading_live_list otherplyer_list">
      <h6 >

         {secondpusername?.charAt(0).toUpperCase()+ secondpusername?.slice(1)}'s Movies</h6>
    </div>
  <div className="card position_card">
  <div className="table-containerr"> 
    <table className="all_playerlist">
  <thead  >
    <tr className="movieslist_headings">
      <td className="itemss">Movie</td>
      <td className="itemss">Category</td>
      <td className="itemss nameitems" >Release Date</td>
  
      <td className="itemss">Score</td>
      <td className="itemss">
      Production Status</td>
    </tr>
  </thead>
  <tbody >
  {secondpmovie?.length!=0?
  secondpmovie?.map((data,index)=>(
    <tr className="Player_title" key={index} >
      <td className="itemss moviesnamefordetails" onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName.length > 30 ? data?.movieName.substring(0, 30) + '...' : data?.movieName}</td>
      <td className="itemss">{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>               
      <td className="itemsss">
      { (moment(new Date(data?.releaseDate + 'Z')).format("MMMM D, YYYY")!=
"Invalid date"? moment(new Date(data?.releaseDate + 'Z')).format("MMMM D, YYYY"):"N/A")}
       
        </td>
      
      <td className="campareicons itemsss">{data?.finalScore}</td>
      <td className="campareicons itemsss">  {data?.production}</td>
    </tr>
    )):<div className="no_movies_found">No Movies found</div>}        
  </tbody>
</table>
            </div>
          </div>
  </div> }
  </div>
</div>
      </div>
      <Footer />
      {loading?<Loader/>:null}
      <ScrollButton />
    </div>
  );
}

export default MyleagueliveDetailpage;
