import React, { useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import { MdClose } from "react-icons/md";

import {  useSelector,useDispatch } from 'react-redux';
import {  closeModalmdetails } from '../../../../../feature/showSlice/showmodaltrademoviedetailsSlice';

import "./ModalTrading.css";
import TradeMoviesdetails from "./TradeMoviesdetails";
import {setTrademoviedetailsforid} from '../../../../../feature/showSlice/trademoviedetailsforidSlice';


export const ModalTradeMoviedetails= ({trademdetailsforid}) => {
  const dispatch = useDispatch();
  const modalRef = useRef();
  const showmodaltrademoviedetails = useSelector((state) => state.showmodaltrademoviedetails);

  const animation = useSpring({
    config: {
      duration: 350,
    },
    opacity: showmodaltrademoviedetails  ? 1 : 0,
    transform: showmodaltrademoviedetails    ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showmodaltrademoviedetails) {
        dispatch(closeModalmdetails());
      }
    },
    [dispatch, showmodaltrademoviedetails]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    const disableScroll = (e) => {
      e.preventDefault(); // Prevent the default scroll behavior
      window.scroll(0, 0); // Keep the window at the top position
    };

    if (showmodaltrademoviedetails) {
      document.body.style.overflow = "hidden"; // Disable scrolling on the body
      window.addEventListener("scroll", disableScroll); // Prevent scrolling on the window
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling on the body
      window.removeEventListener("scroll", disableScroll); // Enable scrolling on the window
    }

    return () => {
      document.body.style.overflow = "auto"; // Make sure to enable scrolling on the body when the component is unmounted
      window.removeEventListener("scroll", disableScroll); // Enable scrolling on the window when the component is unmounted
    };
  }, [showmodaltrademoviedetails]);
  return (
    <div>
      {showmodaltrademoviedetails ? (
        <div ref={modalRef} className="Background_trade">
          <animated.div style={animation}>
            <div showmodaltrademoviedetails={showmodaltrademoviedetails} className="tradingmdetails_wrapper ModalWrapper_trade">
              <div className="d-flex w-100">
              <h4>Movie Details</h4>
               <div
               className="CloseModalButton_trade"               
                aria-label="Close modal"
                onClick={() => {dispatch(closeModalmdetails()); 
                  dispatch(setTrademoviedetailsforid(null));

                }}
              >
                <MdClose className="close_btn"/>
                </div>
              </div>
              <div className="ModalContent_trade">
                <TradeMoviesdetails trademdetailsforid={trademdetailsforid} />
              </div>
            </div>
          </animated.div>
        </div>
      ) : null}
            
    </div>
  );
};
