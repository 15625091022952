import { createSlice } from '@reduxjs/toolkit';

export const msgSlice = createSlice({
  name: 'msgdata', // Slice name
  initialState: [], // Initial state (empty array in this case)
  reducers: {
    setMsgdata: (state, action) => {
      // Update the state with the received data
      return action.payload;
    },
  },
});

// Export the action creator
export const { setMsgdata } = msgSlice.actions;

// Export the reducer
export default msgSlice.reducer;