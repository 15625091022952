import { createSlice } from '@reduxjs/toolkit';

export const signalrDataSlice = createSlice({
  name: 'signalrData', // Slice name
  initialState: [], // Initial state (empty array in this case)
  reducers: {
    setSignalrData: (state, action) => {
      // Update the state with the received data
      return action.payload; // Assuming action.payload contains the new data
    },
  },
});

// Export the action creator
export const { setSignalrData } = signalrDataSlice.actions;

// Export the reducer
export default signalrDataSlice.reducer;