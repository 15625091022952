import React,{useState,useEffect} from 'react'
import imdblogo from "../../../../../assets/IMDB_Logo_2016.png";
import {Errormsg} from "../../../../../components/tostifymsg/Errrormsg";
import {movieposterpath } from "../../../../../Config/config";
import firstitem from "../../../../../assets/dummy-movie.png";
import {movieaddWatchlistost, moviedetailsGet} from "../../../../../api/handleapi";
import { useSelector } from "react-redux";
import { useCategory } from '../../../../../components/useCategory';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { addedtoWatchlist } from '../../../../../components/language/Language';
import { Succesmsg } from "../../../../../components/tostifymsg/Succesmsg";
import { setWatchlistcount } from '../../../../../feature/showSlice/watchlistcountSlice';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';


function TradeMoviesdetails({trademdetailsforid}) {
  const Categoryoptions=useCategory();
  const dispatch = useDispatch();
  const trademoviedetailsforid=useSelector((state) => state.trademoviedetailsforid);
  const watchlistcount = useSelector((state) => state.watchlistcount);
    const [movie_d,setMovie_d]=useState();

    const moviedetailsFun = async (trademdetailsforid,trademoviedetailsforid) => {
        try {
          const response = await moviedetailsGet(trademdetailsforid||trademoviedetailsforid); // You might want to pass some parameters to get
          setMovie_d(response?.data?.data);
         
        } catch (error) {
          return;
        }
      };
      useEffect(() => {
        moviedetailsFun(trademdetailsforid ||trademoviedetailsforid);
          }, []);


          const addWatchlist = async(movieId) => {
            var bodyFormData = new FormData();
            bodyFormData.append("movieId", movieId);
            bodyFormData.append("UserId", Cookies.get("id"));
            
            try {
              const response =  await movieaddWatchlistost(bodyFormData);
              if (response?.status === 200) { 
                Succesmsg(addedtoWatchlist);
                dispatch(setWatchlistcount(watchlistcount+1));
              } 
            } catch (error) {
              if (error.response) {
                if (error.response.data.message) {
                  // Show an alert with the message
                  Errormsg(error?.response?.data?.message);
              }
                // The request was made and the server responded with a status code
              } 
            }
          };



 return (
    <div>  <div className=" row g-0 ">
    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
   <div className="moviedetails_banner">
  {movie_d?.poster?.includes("https://m.media-amazon.com/images") ? 
    <img src={movie_d?.poster} alt="img" className="photo_div_images moviesd_bannerimg" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} /> : 
    <img src={movieposterpath + movie_d?.poster} alt="img"className="photo_div_images moviesd_bannerimg" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} />
  }

   </div>
    </div>
    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12">
    {movie_d && 
    <div className="movie-details-content ">
<div   className="card-body M_details_Card M_de_cardbody ">
        <h4 className="card-title mt-3">
        {movie_d?.name}</h4>
        <div className="bbtnaora">
        {movie_d?.category.split(',').map((categoryId) => {
const category = Categoryoptions?.find((opt) => opt?.id == categoryId);
if (category) {
  return (
    <span className="btn btn-primary aadbtn_category" key={category?.id}>
      {category?.category}
    </span>
  );
}
return null;
})}

         
        </div>
     
        <div className="M_de_card_scores row">
          <span className="details_head_Para col-md-4">
            <h4 className="t_score">
              {movie_d?.tomatoMeter}
              </h4>
            <p className="para_t_Score">TOMATOMETER</p>
          </span>
          <span className="details_head_Para col-md-4">
            <h4 className="t_score">
             
              {movie_d?.audienceScore}
              </h4>
            <p className="para_t_Score">AUDIENCE SCORE</p>
          </span>
          <span className="details_head_Para col-md-4">
            <h4 className="t_score">
              
              {movie_d?.metaCritic}
              </h4>
            <p className="para_t_Score">METASCORE</p>
          </span>
        </div>
        <div className="M_de_card_scoress  row">
        <span className="details_head_Para col-md-4">
            <a  className="imdbrating_tag" target="_blank" href={`https://www.imdb.com/title/${movie_d?.movieId}/ratings/?ref_=tt_ov_rt`}>
            <h4 className="t_score">
            <img src={imdblogo} alt="img" className="imdblogo_d img-fluid" />{" "}
              {movie_d?.imDbRating}/10
              </h4>
              </a>
            <p className="para_t_Score">IMDb RATING</p>
          </span>
         
          <span className="details_head_Para col-md-4">
            <h4 className="t_score">
            {movie_d?.letterBoxdScore}
              </h4>
            <p className="para_t_Score">LETTERBOXD SCORE</p>
          </span>
          <span className="details_head_Para col-md-4">
            <h4 className="t_score">
            
              {movie_d?.letterboxdBonus}
              </h4>
            <p className="para_t_Score">LETTERBOXD BONUS</p>
          </span>
        </div>
        <div className="M_de_card_scoress row">
        <span className="details_head_Para col-md-4 ">
          
            <h4 className="t_score">
              {movie_d?.boxOffice}
              </h4>
             
            <p className="para_t_Score">BOX OFFICE SCORE</p>
          </span>
         
          <span className="details_head_Para col-md-4" >
            <h5 className="t_score">
            {movie_d?.boxOfficeBonus}
              </h5>
            <p className="para_t_Score">BOX OFFICE BONUS</p>
          </span>
          <span className="details_head_Para col-md-4">
            <h5 className="t_score">
            
            {movie_d?.h2HBonusBoxOffice>0?5:0}
              </h5>
            <p className="para_t_Score">HEAD 2 HEAD BONUS (BOX OFFICE)</p>
          </span>
        </div>
        <div className="M_de_card_scores_last row">
        <span className="details_head_Para col-md-4" >
          
            <h4 className="t_score">
           
            {movie_d?.h2HBonusLetterBoxd >0?5:0}
              </h4>
             
            <p className="para_t_Score">HEAD 2 HEAD BONUS (LETTERBOXD)</p>
          </span>
         
          <span className="details_head_Para col-md-4">
            <h5 className="t_score">
            {movie_d?.oscarBonus}
              </h5>
            <p className="para_t_Score">OSCAR BONUS</p>
          </span>
        </div>
        <div className="row justify-content-between mt-2" >
<div className="col-md-6">

        <div className="head_w_para mt-3">
          <h6 className="H_forbold">Production Status</h6>

          <p className="para_t_Score">{movie_d?.production==null || undefined?"N/A":movie_d?.production}</p>
        
        </div>
        <div className="head_w_para mt-3">

          <h6 className="H_forbold">Release Date</h6>
          <p className="para_t_Score">
          { (moment(new Date(movie_d?.releaseDate + 'Z')).format("MMMM D, YYYY")!=
"Invalid date"? moment(new Date(movie_d?.releaseDate + 'Z')).format("MMMM D, YYYY"):"N/A")}
            </p>
        </div>
        <div className="head_w_para mt-3">
        <h6 className="H_forbold">Runtime</h6>
          <p className="para_t_Score">
            {movie_d?.runTime == null || undefined?"N/A":movie_d?.runTime}
            </p>
        </div>
        <div className="head_w_para mt-3">
        <h6 className="H_forbold">Rating</h6>
        <p className="para_t_Score">{movie_d?.rating==null || undefined?"N/A":movie_d?.rating}</p>
        </div>
        </div>
        <div className="col-md-4"> 
        <span className="details_head_Para details_total_para">
            <h1 className={movie_d?.totalScore < 30 ? 'total_score_red' : movie_d?.totalScore <= 39.9 ? 'total_score_yellow' : 'total_score_green'}
            >
            {movie_d?.totalScore}
                                 </h1>
            <h5 className="para_t_Score ">TOTAL SCORE</h5>
          </span>
          </div>
       <div className='col-12'>
       <button className="moviedetails_addtowatchlist" onClick={() => addWatchlist(trademoviedetailsforid)}>
                  {" "}
                  Add to Watchlist
                </button>
        </div>
        </div>
        <div className="head_w_para mt-3">
        <h6 className="H_forbold">Description</h6>
         <p className="card-text">
            {movie_d?.description}
          </p>
          </div>
      </div>

    </div>
     
    } 
    </div>
  </div></div>
  )
}

export default TradeMoviesdetails