import { createSlice } from '@reduxjs/toolkit';

export const showmodaltradeSlice = createSlice({
  name: 'showmodaltrade',
  initialState: false,
  reducers: {
    openModal: (state) => true,
    closeModal: (state) => false,
  },
});

export const { openModal, closeModal } = showmodaltradeSlice.actions;

export default showmodaltradeSlice.reducer;