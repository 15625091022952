import { useState } from "react";
import {
  commentValidation,
  senderNameValidation,
  senderEmailValidation,
} from "../../../components/validation/Validation";
const touchErrors = (errors) => {
  return Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {});
};
export const UseCommentformvalidation = (commentt) => {
  const [errors, setErrors] = useState({
    comment: {
      dirty: false,
      error: false,
      message: "",
    },
    senderName: {
      dirty: false,
      error: false,
      message: "",
    },
    senderEmail: {
      dirty: false,
      error: false,
      message: "",
    },
  });

  const validateForm = ({ commentt, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    // Create a deep copy of the errors
    let nextErrors = JSON.parse(JSON.stringify(errors));

    // Force validate all the fields
    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const {
      comment,
      senderName,
      senderEmail,
    } = commentt;

    if (
      nextErrors.comment.dirty &&
      (field ? field === "comment" : true)
    ) {
      const commentMessage = commentValidation(comment, commentt);
      nextErrors.comment.error = !!commentMessage;
      nextErrors.comment.message = commentMessage;
      if (!!commentMessage) isValid = false;
    }

    if (nextErrors.senderName.dirty && (field ? field === "senderName" : true)) {
      const senderNameMessage = senderNameValidation(senderName, commentt);
      nextErrors.senderName.error = !!senderNameMessage;
      nextErrors.senderName.message = senderNameMessage;
      if (!!senderNameMessage) isValid = false;
    }

    if (nextErrors.senderEmail.dirty && (field ? field === "senderEmail" : true)) {
      const senderEmailMessage =senderEmailValidation(senderEmail, commentt);
      nextErrors.senderEmail.error = !!senderEmailMessage;
      nextErrors.senderEmail.message = senderEmailMessage;
      if (!!senderEmailMessage) isValid = false;
    }
    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({ commentt, field, errors: updatedErrors });
  };

  return {
    validateForm,
    onBlurField,
    errors,
  };
};
