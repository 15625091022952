import { createSlice } from '@reduxjs/toolkit';

export const paymentdetailSlice = createSlice({
  name: 'paymentdetail', // Slice name
  initialState: {  
     
  }
  , // Initial state (empty array in this case)
  reducers: {
    setPaymentdetail: (state, action) => {
      // Update the state with the received data
      return action.payload;
    },
  },
});

// Export the action creator
export const { setPaymentdetail } = paymentdetailSlice.actions;

// Export the reducer
export default paymentdetailSlice.reducer;