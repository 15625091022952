import React,{useState,useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import firstitem from "../../assets/dummy-movie.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import "./Trandingmovie.css";
import {movieposterpath, settings } from "../../Config/config"
import {newreleasemovieGet} from "../../api/handleapi";
import {  useDispatch,useSelector } from 'react-redux';
import { openModal } from '../../feature/showSlice/showModalSlice';
import {movieaddWatchlistost} from "../../api/handleapi"
import { Link, NavLink } from "react-router-dom";
import Dummyads from "../../components/google-AdSense/Dummyads";
import { setWatchlistcount } from '../../feature/showSlice/watchlistcountSlice';
import Cookies from 'js-cookie';
import { useCategory } from "../../components/useCategory";
import { addedtoWatchlist, loginfirslyMsg } from "../../components/language/Language";
import Googleads from "../../components/google-AdSense/Googleads";
import Googleadshorizontal from "../../components/google-AdSense/Googleadshorizontal";


function Newreleasec({Errormsg,Succesmsg}) {
const[newrelease,setNewrelease]=useState([]);
const watchlistcount = useSelector((state) => state.watchlistcount);

const dispatch = useDispatch();
const [loadingnewr, setLoadingnewr] = useState(true);
const handleOpen = () => {
  dispatch(openModal());
};
const Categoryoptions=useCategory();
  const [hiddenButtons, setHiddenButtons] =useState({});

  const createPost = async(movieId) => {
    var bodyFormData = new FormData();
    bodyFormData.append("movieId", movieId);
    bodyFormData.append("userId", Cookies.get("id") );
    try {
      const response =  await movieaddWatchlistost(bodyFormData);
      if (response?.status === 200) {
        Succesmsg(addedtoWatchlist)
        setHiddenButtons(prevState => ({ ...prevState, [movieId]: true }));  
        dispatch(setWatchlistcount(watchlistcount+1));
      } 
    } 
    catch (error) {
      if (error.response) {
        if (error.response.data.message) {
          // Show an alert with the message
          Errormsg(error.response.data.message);
      }
          handleOpen();
          Cookies.set("navivalue", "/");
          Errormsg(loginfirslyMsg);
        // The request was made and the server responded with a status code

      } 
    }
  };


  useEffect(() => {
    const NewreleaseFun = async () => {
      try {
        const response = await newreleasemovieGet(); 
        setNewrelease(response?.data);
        setLoadingnewr(false);
      } catch (error) {
        return;
      }
    };
    NewreleaseFun();
      }, []);

  return (
  
    <div className="section_main">
       
      <div className="containers con_div">
  
        <div className="heading">
          <h6>New Releases</h6>
          <NavLink to="/Movieslist" className="viewheading" onClick={() => window.scrollTo(0, 0)}>
            View all{" "}
            <FontAwesomeIcon className="greatericon" icon={faGreaterThan} />
          </NavLink>
        </div>

        <div className="secondchild">
        {loadingnewr ? (
            <div className="spinner-border text-primary " role="status">
  <span className="visually-hidden">Loading...</span>
</div>):newrelease?.length>0?
          <Slider {...settings}>
          {newrelease?.map((item, index) => (
                      <div className="first_card trending-movie1">
            <div key={index}className="cardd  moviecard">
            
            <Link className="movieheading_ele" to={`/Moviedetails/${item?.id}`}>
  {item?.poster?.includes("https://m.media-amazon.com/images") ? 
    <img src={item?.poster} alt="img" className="w-100 imgw-100" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} /> : 
    <img src={movieposterpath + item?.poster} alt="img" className="w-100 imgw-100" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} />
  }
</Link>  
              
              {!hiddenButtons[item?.id] && <button className="btn card-btn card-cstm-btn"  onClick={() => createPost(item?.id)}>Add to Watchlist</button>}
              <div className="card-body cbodyheading moviebody">

  
              <Link  className="movieheading_ele"  to={`/Moviedetails/${item?.id}`}>
  <h5>{item?.name}</h5>
</Link>
                <span className="text-offw"> Release year : {item?.releaseYear}</span>
                <div className="mdetails">
                  <ul className="Tren_m_ul">
                   
                 
            {item?.category && item?.category?.split(',')?.map((categoryId, index) => (
  <span key={index} className="text-offw">
    {Categoryoptions?.find(cat => cat?.id == categoryId)?.category}
  </span>
))}

  
                  </ul>
                </div>
              </div>
            </div>
            </div>
          ))}
          </Slider>:<p className="text-white m-0">No Movies found</p>}
          
        </div>
        {/* <Dummyads/> */}
        <Googleadshorizontal props="pt-2 pb-2 text-center"/>
      </div>
   
    </div>
  );
}

export default Newreleasec;
