
// this  URL's are used for UAT environment
export const imgpath="https://api.gafferapp.com/files/";
export const movieposterpath="https://admin.gafferapp.com/files/";
export const REACTAPPSignalRPREFIX="https://api.gafferapp.com/chatHub"
export const leagueinviteURl= "https://gafferapp.com/Myleagueinvites"
export const dataadClient="ca-pub-8759251703965355"

export const dataadSlot="5093245935"


export const dataadFormat="auto"
export const datafullwidthResponsive="true"
export const stripepaymentPublickey='pk_live_51PsAkxP8AzLcztsuFIrGaV84xfbGTiOQSeRHG1cJy6iLHIROG8WrRxNZ111bwIDWEl8bx65izJAUqZi5jm8skeJe002McRITCW'
  export const option = { 
    hour: 'numeric', 
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/Chicago' 
  };
  export const options = { 
    weekday: 'long', 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric' ,
    time:'numeric',
  };
  export const optionsdatewithyear = { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric' ,
    time:'numeric',
  };

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style }} onClick={onClick} />;
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style }} onClick={onClick} />;
}


export const settings = {
  dots: false,
  infinite: false,
  speed: 250,
  slidesToShow: 5.5,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 5.5,
        slidesToScroll: 1,
        
        
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 1,
        
        
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },

  ],
  
};


export const cookieNames = ['token', 'rrefreshToken','email','name','id','username','userprofileimage','Paymentdetail','noofjoinplayers',"secondpid","secondpusername",'navivalue'];
