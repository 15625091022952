import React from 'react';
import ReactPaginate from 'react-paginate';
import "./pagination.css"
const Pagination = ({ pageCount, handlePageClick }) => {
  return (
    <div className="pagination">
    <ReactPaginate
      previousLabel={'❮'}
      nextLabel={'❯'}
      pageCount={pageCount}
      onPageChange={handlePageClick}
      containerClassName={'pagination'}
      activeClassName={'active_pagination'}
    />
    </div>
  );
};

export default Pagination;
