import { createSlice } from '@reduxjs/toolkit';

export const loginpmovieSlice = createSlice({
  name: 'loginpmovie', // Slice name
  initialState: [], // Initial state (empty array in this case)
  reducers: {
    setLoginpmovie: (state, action) => {
      // Update the state with the received data
      return action.payload; // Assuming action.payload contains the new data
    },
  },
});

// Export the action creator
export const { setLoginpmovie } = loginpmovieSlice.actions;

// Export the reducer
export default loginpmovieSlice.reducer;