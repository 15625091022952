import { createSlice } from '@reduxjs/toolkit';

export const showmodaltrademoviedetailsSlice = createSlice({
  name: 'showmodaltrademoviedetails',
  initialState: false,
  reducers: {
    openModalmdetails: (state) => true,
    closeModalmdetails: (state) => false,
  },
});

export const { openModalmdetails, closeModalmdetails } = showmodaltrademoviedetailsSlice.actions;

export default showmodaltrademoviedetailsSlice.reducer;