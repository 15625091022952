import React, { useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import { MdClose } from "react-icons/md";
import  Joinlcategory  from "./Joinlcategory";
import "./CategoryModal.css"


export const CategoryModal = ({indexs,setIndex,showModalcategory,setShowModalcategory,categoryDataMovieCountClick,fetchdata,setFetchData,setHiddenButtons,value,setValue,draftordertime,setDraftordertime}) => {

  const modalRef = useRef();
  const animation = useSpring({
    config: {
      duration: 350,
    },
    opacity: showModalcategory ? 1 : 0,
    transform: showModalcategory ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModalcategory) {
        setShowModalcategory(false);

        
      }
    },
    [setShowModalcategory, showModalcategory]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    const disableScroll = (e) => {
      e.preventDefault(); // Prevent the default scroll behavior
      window.scroll(0, 0); // Keep the window at the top position
    };

    if (showModalcategory) {
      document.body.style.overflow = "hidden"; // Disable scrolling on the body
      window.addEventListener("scroll", disableScroll); // Prevent scrolling on the window
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling on the body
      window.removeEventListener("scroll", disableScroll); // Enable scrolling on the window
    }

    return () => {
      document.body.style.overflow = "auto"; // Make sure to enable scrolling on the body when the component is unmounted
      window.removeEventListener("scroll", disableScroll); // Enable scrolling on the window when the component is unmounted
    };
  }, [showModalcategory]);


  return (
    <div>
      {showModalcategory ? (
        <div ref={modalRef} className="Background_Mcategory">
          <animated.div style={animation}>
            <div showModalcategory={showModalcategory} className="trading_wrapperc ModalWrapper_Mcategory">
              <div
              className="CloseModalButton_Mcategory"
                aria-label="Close modal"
                onClick={() => setShowModalcategory((prev) => !prev)} 
              >
                <MdClose className="close_btn"/>
                </div>
              <div className="ModalContent_Mcategory">
                <Joinlcategory  indexs={indexs} setIndex={setIndex} setShowModalcategory={setShowModalcategory} categoryDataMovieCountClick={categoryDataMovieCountClick}fetchdata={fetchdata}setFetchData={setFetchData}setHiddenButtons={setHiddenButtons}value={value} setValue={setValue} draftordertime={draftordertime} setDraftordertime={setDraftordertime}/>
              </div>
            </div>
          </animated.div>
        </div>
      ) : null}
    </div>
  );
};
