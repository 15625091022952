import React from "react";
import "./Latestnewsbanner.css";
function Latestnewsbanner({text}) {
  return (
    <div className="Lbanner bg-dark ">
      <div >
        <h1 className="card-title text-white jbcard-text">{text}</h1>
      </div>
    </div>
  );
}

export default Latestnewsbanner;
