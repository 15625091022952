import React,{useState,useEffect} from 'react'
import swal from 'sweetalert';
import "./Dropmovie.css";
import { useSelector,useDispatch } from 'react-redux';
import Tradebtn from "./Tradebtn";
import { setLoginpmovie} from "../../../../../feature/showSlice/loginpmovieSlice";
import {upcomingpollmoviesGet,dropmoviewithpoolPost,playermoviesGet} from "../../../../../api/handleapi";
import { openModalmdetails } from '../../../../../feature/showSlice/showmodaltrademoviedetailsSlice';
import Cookies from 'js-cookie';
import { useCategory } from '../../../../../components/useCategory';
import Searchmovies from '../../../../../components/searchmovies/Searchmovies';
import { moviesscoregetMsgforpickups, pickupAuthcheck, pickupMsg, pickupsMsg, successfullyTitle } from '../../../../../components/language/Language';



// Drop/Pickup movie component
function Dropmovie({setShowModaldrop,League_id,setTrademdetailsforid}) {
    const firstpid=Cookies.get("id");
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCategoryy, setSelectedCategoryy] = useState('All');
   
    const [movieslist,setMoviesList] = useState([]);
    const [pollmovies,setPollmovies]=useState([]);
    const dispatch=useDispatch();
    
    const Categoryoptions=useCategory();
    const [categoryname,setcategoryName]=useState([]);

    const movieslistFun = async (League_id,firstpid) => {
      try {
        const response = await playermoviesGet(League_id,firstpid); 
        dispatch(setLoginpmovie(response?.data?.data?.logedInPlayerMovies));
      } catch (error) {
        return;
      }
    }
    useEffect(() => {
     movieslistFun(League_id,firstpid);
    }, []);


    const openModalMdetails = (movieId) => {
      dispatch(openModalmdetails(true)); 
      dispatch(setTrademdetailsforid(movieId));
  };
  
    const pollmoviesGet = async (League_id) => {
      try {
    const response = await upcomingpollmoviesGet(League_id);
     setPollmovies(response?.data?.data)
    } catch (error) {
       return;
    }
    };

      useEffect(() => {
       pollmoviesGet(League_id);
      }, [League_id]);
      
    const [fpick,setFpick]=useState({
      MovieId :"",
      MovieCategoryId:"",
    })
  
    const [pollpick,setPollpick]=useState({
      PoolMovieId :"",
      PoolMovieCategoryId   :"",
    })
  
    const loginpmovie=useSelector((state) => state.loginpmovie);
  
    useEffect(() => {
      const updatedMoviesArray = loginpmovie?.map((movie) => ({
        ...movie,
        checked: false,
    }));
    const updatedMoviesforfinals = updatedMoviesArray?.filter(movie => movie.finalScore === 0);
      setMoviesList(updatedMoviesforfinals);
    
    },[]);
    useEffect(() => {
      const updatedMoviesArrayy = pollmovies?.map((movie) => ({
        ...movie,
        checked: false,
    }));
    setPollmovies(updatedMoviesArrayy);
    },[]);


    function categoryNameFind(id) {
      const categoryOption = Categoryoptions?.find((option) => option?.id == id);
      if (categoryOption) {
        setcategoryName(categoryOption?.category);
      }
    }
    
    const handleIconClick = (movieid) => {
      
      let temp_movielist = [...movieslist]
      let updatedData = temp_movielist?.map((each)=>{
        if(each.movieId==movieid){
          setFpick({ MovieId:each?.movieId,MovieCategoryId:each?.categoryId})
          categoryNameFind(each?.categoryId)
          return {...each,checked:!each.checked}
        }
        return {...each,checked:false}
    })
      setMoviesList(updatedData);
    };
  
    const handleIconClickk = (movieId,category) => {
      let temp_movielist = [...pollmovies]
      let movieCategoryId = fpick?.MovieCategoryId;
      let updatedData = temp_movielist?.map((each)=>{
        if(each?.id==movieId){
          if (category?.includes(movieCategoryId)) {
            setPollpick ({
              PoolMovieId:each?.id,
              PoolMovieCategoryId:movieCategoryId
             }); 
             return {...each,checked:!each.checked}
          }
          else{
            setPollpick ({
            PoolMovieId:each?.id,
           }); 
          return {...each,checked:!each.checked}
          }
        }
        return {...each,checked:false}
    })
      setPollmovies(updatedData);
    };
 
    const pickupMovie = async() => {
     
      if(!fpick?.MovieId){
        swal({
          title: "",
          text:pickupsMsg ,
          icon: "error",
          closeOnClickOutside:false,
          buttons: "ok",
          dangerMode: false
        })
      }
      else{
      var bodyFormData = new FormData();
      bodyFormData.append("LeagueId",League_id );
      bodyFormData.append("PlayerId", firstpid );
      bodyFormData.append("MovieId", fpick?.MovieId);
      bodyFormData.append("MovieCategoryId",fpick?.MovieCategoryId);
      bodyFormData.append("PoolMovieId", pollpick?.PoolMovieId);
      let movieCategoryId = fpick?.MovieCategoryId;
      if ([2065, 2066, 2094, 2095]?.includes(movieCategoryId)) {
        movieCategoryId = fpick?.MovieCategoryId;
      } else {
        movieCategoryId = pollpick?.PoolMovieCategoryId;
      }
      bodyFormData.append("PoolMovieCategoryId", movieCategoryId);     
      try {
        const response =  await dropmoviewithpoolPost(bodyFormData);
        if (response?.status === 200) {
          movieslistFun(League_id,firstpid)
          swal({
            title: successfullyTitle,
            text: pickupMsg,
            icon: "success",
            closeOnClickOutside:false,
            buttons: "ok",
            dangerMode: false
          }).then((value) => {
            if(value){
              setShowModaldrop(false);
            }})
        } 
      } 
      catch (error) {
        if (error.response.data?.message!=undefined && error.response.data?.message==pickupAuthcheck) {
                  swal({
                    title: "",
                    text:moviesscoregetMsgforpickups,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  })                  
                }         
                else if (error.response.data?.message===undefined) {
                  swal({
                      title: "",
                      text: `Select movies that are categorized as ${categoryname} to pickup`,
                      icon: "error",
                      buttons: "ok",
                      closeOnClickOutside:false,
                      dangerMode: false
                    })
                }else {
                    return;
                }
      }
    }
    }
   
  
    const [inputText, setInputText] = useState("");


    let inputHandler = (e) => {
      //convert input text to lower case
      var lowerCase = e.target.value.toLowerCase();
      if (true) {
      setInputText(lowerCase);
      }
    };

   
    const filteredData = pollmovies?.filter((el) => {
      const nameMatch = el?.name?.toLowerCase()?.includes(inputText);
      const categoryMatch = el.category?.includes(selectedCategory);
      if (inputText !== '' && selectedCategory !== 'All') {
        // Scenario 1: Filter by movie name and category
        return nameMatch && categoryMatch;
      } else if (inputText !== '' && (!selectedCategory || selectedCategory === 'All')) {
        // Scenario 2: Filter by movie name only
        return nameMatch;
      } else if (inputText === '' && selectedCategory !== 'All') {
        // Scenario 3: Filter by category only
        return categoryMatch;
      } else {
        // If both inputText and selectedCategory are empty, return all movies
        return true;
      }
    })


    const [inputTextt, setInputTextt] = useState("");
    let inputHandlerr = (e) => {
      //convert input text to lower case
      var lowerCase = e.target.value.toLowerCase();
      if (true) {
      setInputTextt(lowerCase);
      }
    };



const filteredDataa = movieslist?.filter((el) => {
  const nameMatch = el?.movieName?.toLowerCase()?.includes(inputTextt);
  const categoryMatch = el.categoryId == parseInt(selectedCategoryy);
  if(selectedCategoryy=="All" && inputTextt == '') {
    // If both inputText and selectedCategory are empty, return all movies
    return true;
  }
 else if (inputTextt !== '' && selectedCategoryy != 'All') {
    // Scenario 1: Filter by movie name and category
    return nameMatch && categoryMatch;
  } else if (inputTextt != '' && (!selectedCategoryy || selectedCategoryy == 'All')) {
    // Scenario 2: Filter by movie name only
    return nameMatch;
  } else if (inputTextt == '' && selectedCategoryy != 'All') {

    // Scenario 3: Filter by category only
    return categoryMatch;
  } 
})


  return (
<div>
  <div className="row">
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div className="heading_live_listt"><h6>My Movies</h6></div>
             <div className="position_card">
  <div className="table_containerr_trade scrollbarcstm"> 
    <table className="playermovie_list">
  <thead  >
    <tr className="trade_movieslist_headings">
      <td >
        
        <Searchmovies inputHandler={inputHandlerr}/>
        
        </td>
      <td >
        
        <select
                  className="form-select pickups-input-groupp"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  onChange={(e) => setSelectedCategoryy(e.target.value)}
                  value={selectedCategoryy}
                >
                  <option selected value="All">Select Category</option>
                
                  {Categoryoptions?.sort((a, b) => a?.category?.localeCompare(b?.category))?.map((matchingData,item)=>(
                  <option key={item} value={matchingData?.id}>
                 {matchingData?.category}
                </option>
                  ))}
                  
                </select> 
                
                </td>
      <td >Score</td>
      <td >Actions</td>
    </tr>
  </thead>
  <tbody >
  {filteredDataa?.length!=0?
  filteredDataa?.map((data,index)=>(
    <tr className="Player_title" key={index} >
          <td>
             <div className='d-flex gap-2'>
           

             <span  className='moviesnamefordetails'onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 30 ? data?.movieName.substring(0, 30) + '...' : data?.movieName}</span>
             </div>
             </td>   
      <td>{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
      <td>{data?.finalScore}</td>
      <td className="campareicons">
      <input type="checkbox" checked={data?.checked} onClick={() => handleIconClick(data?.movieId)}/>
      </td>
    </tr>
    )):<div className='no_dropm_found'>No movies found</div>
    } 
  </tbody>
</table>
            </div>
          </div>

    </div>
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div className="heading_live_listt">
        <h6> Available Movies </h6>
      </div>
            <div className="position_card">
  <div className="table_containerr_trade pool_tables scrollbarcstm"> 
    <table className="playermovie_list ">
  <thead  >
    <tr className="trade_movieslist_headings">
      <td >
        <Searchmovies inputHandler={inputHandler}/>
             </td>
      <td >  
         <select
                  className="form-select pickups-input-groupp"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  value={selectedCategory}
                >
                  <option selected value="All">Select Category</option>
                                      {Categoryoptions?.sort((a, b) => a?.category?.localeCompare(b?.category))?.filter((item) => !['2065', '2066', '2094', '2095']?.includes(item?.id.toString()))?.map((matchingData, item) => (
                  <option key={item} value={matchingData?.id}>
                 {matchingData?.category}
                </option>
                  ))}
                  
                </select>
                
                  </td>
      <td >Actions</td>
    </tr>
  </thead>
  <tbody>
    {filteredData?.length !=0?
 
  filteredData?.map((data,index)=>(

    <tr className="Player_title" key={index} >
          <td>
             <div className='d-flex gap-2'>
             <span  className='moviesnamefordetails'onClick={()=>openModalMdetails(data?.id)}>{data?.name?.length > 30 ? data?.name.substring(0, 30) + '...' : data?.name}</span>          
             </div>
             </td>   
      <td><div className='listcategory'>
                {data?.category && data?.category.split(',').map((categoryId, index) => (
                          <span key={index} className="listcategory">
                            {Categoryoptions?.find(cat => cat?.id == categoryId)?.category}
                            </span>
                          ))}
                
                </div></td>
      <td className="campareicons">
      <input type="checkbox" checked={data?.checked} onChange={()=> handleIconClickk(data?.id,data?.category)} />
      </td>
    </tr>
    )):<div className='no_dropm_found'>No movies found</div>
    } 
  </tbody>
</table>
            </div>
          </div>
    </div>
  </div>
  <div className='Trade_modal_btn' onClick={pickupMovie}>
  {/*  */}
    <Tradebtn Trade={"Pickup"}/>
  </div>
  
</div>
  )
}

export default Dropmovie