import { createSlice } from '@reduxjs/toolkit';

export const trademoviedetailsforidSlice = createSlice({
  name: 'trademoviedetailsforid', // Slice name
  initialState: 
    null
  , // Initial state (empty array in this case)
  reducers: {
    setTrademoviedetailsforid: (state, action) => {
      // Update the state with the received data
      return action.payload;
    },
  },
});

// Export the action creator
export const { setTrademoviedetailsforid } = trademoviedetailsforidSlice.actions;

// Export the reducer
export default trademoviedetailsforidSlice.reducer;