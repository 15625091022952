import React, { useRef, useEffect, useCallback,useState } from "react";
import { useSpring, animated } from "react-spring";
import { MdClose } from "react-icons/md";
import Trading from "./Trading";
import {  useSelector,useDispatch } from 'react-redux';
import {  closeModal } from '../../../../../feature/showSlice/showmodaltradeSlice';
import Swapmovies from "./Swapmovies";
import Dropmovie from "./Dropmovie";
import Trading1 from "./Trading1";
import "./ModalTrading.css";
import { ModalTradeMoviedetails } from "./ModalTradeMoviedetails";
import Tradeaccept from "./Tradeaccept";
import { pickupMsg } from "../../../../../components/language/Language";



export const ModalTrading= ({  setNotificationget,setNotificationlength,playername,League_id ,showModalswap ,setShowModalswap,showModaldrop, setShowModaldrop,showModalTrading, setShowModalTrading, trademovies,trademdetailsforid,setTrademdetailsforid,setTrademovies, notificationlength,showtradeaccpet, setShowTradeaccpet}) => {
  const dispatch = useDispatch();
  const modalRef = useRef();
  const showModaltrade = useSelector((state) => state.showmodaltrade);
  const [togglestate,setTogglestate]=useState(true);
  const [togglepickup,setTogglepickup]=useState(false);
  const [toggleadjust,setToggleAdjust]=useState(false);
  const animation = useSpring({
    config: {
      duration: 350,
    },
    opacity: showModaltrade || showModalswap ||showModaldrop || showModalTrading ? 1 : 0,
    transform: showModaltrade || showModalswap ||showModaldrop || showModalTrading   ? `translateY(0%)` : `translateY(-100%)`,
  });

 
  useEffect(() => {
    const disableScroll = (e) => {
      e.preventDefault(); // Prevent the default scroll behavior
      window.scroll(0, 0); // Keep the window at the top position
    };

    if (showModaltrade || showModalswap || showModaldrop || showModalTrading) {
      document.body.style.overflow = "hidden"; // Disable scrolling on the body
      window.addEventListener("scroll", disableScroll); // Prevent scrolling on the window
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling on the body
      window.removeEventListener("scroll", disableScroll); // Enable scrolling on the window
    }

    return () => {
      document.body.style.overflow = "auto"; // Make sure to enable scrolling on the body when the component is unmounted
      window.removeEventListener("scroll", disableScroll); // Enable scrolling on the window when the component is unmounted
    };
  }, [showModaltrade,showModalswap,showModaldrop,showModalTrading]);
  return (
    <div>
        <ModalTradeMoviedetails trademdetailsforid={trademdetailsforid}/>
      {showModaltrade ? (
        <div ref={modalRef} className="Background_trade">
          <animated.div style={animation}>
            <div showModaltrade={showModaltrade} className="trading_wrapper ModalWrapper_trade">
              <div className="d-flex w-100">
              <h4>Trades</h4>
               <div
               className="CloseModalButton_trade"               
                aria-label="Close modal"
                onClick={() => 
                  dispatch(closeModal())
                
                }
              >
                <MdClose className="close_btn"/>
                </div>
              </div>
              <div className="ModalContent_trade">
                <Trading  League_id={League_id} trademdetailsforid={trademdetailsforid} setTrademdetailsforid={setTrademdetailsforid} trademovies={trademovies} setTrademovies={setTrademovies}/>
              </div>
            </div>
          </animated.div>
        </div>
      ) : null}
       {showModalswap ? (
        <div ref={modalRef} className="Background_trade">
          <animated.div style={animation}>
            <div showModalswap={showModalswap} className="trading_wrapper ModalWrapper_trade">
              <div className="d-flex w-100">
              <h4>Swaps</h4>
              <div
              className="CloseModalButton_trade"
                aria-label="Close modal"
                onClick={() => setShowModalswap((prev) => !prev)}
              >
                 <MdClose className="close_btn"/>
                </div>
               
              </div>
              <div className="ModalContent_trade">
                <Swapmovies showModalswap={showModalswap} setShowModalswap={setShowModalswap} League_id={League_id} trademdetailsforid={trademdetailsforid} setTrademdetailsforid={setTrademdetailsforid}/>
             
              </div>
            </div>
          </animated.div>
        </div>
      ) : null}
         {showModaldrop ? (
        <div ref={modalRef} className="Background_trade">
          <animated.div style={animation}>
            <div showModaldrop={showModaldrop} className="trading_wrapper_pickuping ModalWrapper_trade">
              <div className="d-flex w-100">
              <h4>Pickups</h4>
              <div
                className="CloseModalButton_trade"
                aria-label="Close modal"
                onClick={() => setShowModaldrop((prev) => !prev)}
              >
               <MdClose className="close_btn"/>
              </div>
              </div>
              <div className="ModalContent_trade">
                <Dropmovie showModaldrop={showModaldrop} setShowModaldrop={setShowModaldrop} League_id={League_id} trademdetailsforid={trademdetailsforid} setTrademdetailsforid={setTrademdetailsforid}/>
              </div>
            </div>
          </animated.div>
        </div>
      ) : null}
 {showModalTrading ? (
        <div ref={modalRef} className="Background_trade">
          <animated.div style={animation}>
            <div showModalTrading={showModalTrading} className="trading_wrapper ModalWrapper_trade">
              <div className="d-flex w-100">
              <h4> {togglestate?"Trades":"CounterOffer"}</h4>
              <div
              className="CloseModalButton_trade"
                aria-label="Close modal"
                onClick={() => {setShowModalTrading((prev) => !prev); setTogglestate(true)}}
              >
                <MdClose className="close_btn"/>
                </div>
              </div>
              <div className="ModalContent_trade"> 
                <Trading1   setNotificationget={setNotificationget} setNotificationlength={setNotificationlength}  playername ={playername} setShowModalTrading={setShowModalTrading} trademovies={trademovies} trademdetailsforid={trademdetailsforid} setTrademdetailsforid={setTrademdetailsforid} togglestate={togglestate}setTogglestate={setTogglestate} notificationlength={notificationlength}  showtradeaccpet={showtradeaccpet} setShowTradeaccpet={setShowTradeaccpet}/>
              </div>
            </div>
          </animated.div>
        </div>
      ) : null}
       {showtradeaccpet ? (
        <div ref={modalRef} className="Background_trade">
          <animated.div style={animation}>
            <div showtradeaccpet={showtradeaccpet} className="trading_wrapper ModalWrapper_trade">
              <div className="d-flex w-100">
              <h4>{!togglepickup && !toggleadjust?"Accommodate":togglepickup && !toggleadjust?"Pickups":"Adjustment"}</h4>
              <div
              className="CloseModalButton_trade"
                aria-label="Close modal"
                onClick={() => setShowTradeaccpet((prev) => !prev)}
              >
                <MdClose className="close_btn"/>
                </div>
              </div>
              <div className="ModalContent_trade"> 
               <Tradeaccept togglepickup={togglepickup} setTogglepickup={setTogglepickup} toggleadjust={toggleadjust} setToggleAdjust={setToggleAdjust} playername ={playername} setShowModalTrading={setShowModalTrading} trademovies={trademovies} trademdetailsforid={trademdetailsforid} setTrademdetailsforid={setTrademdetailsforid} togglestate={togglestate}setTogglestate={setTogglestate} notificationlength={notificationlength} setNotificationlength={setNotificationlength} showtradeaccpet={showtradeaccpet} setShowTradeaccpet={setShowTradeaccpet}/>
              </div>
            </div>
          </animated.div>
        </div>
      ) : null}
      
    </div>
  );
};
