import React,{useState,useEffect} from "react";
import ScrollButton from "../../../components/scrollbutton/ScrollButton";
import Subheader from "../../../components/subheader/Subheader";
import { Footer } from "../../../components/footer/footer";
import { ReactComponent as YourIcon } from "../../../assets/mdi_calendar.svg";
import { ReactComponent as YourIcon1 } from "../../../assets/time.svg";
import "../../dashboard/Dashboard.css";
import firstitem from "../../../assets/dummy-movie.png";
import Pagination from "../../../components/pagination/Pagination";
import CountDownTimer from "../../../components/countdowntimer/CountDownTimer";
import Navbarr from "../../../components/header/Navbarr";
import { cookieNames, imgpath} from "../../../Config/config";
import {upcomingleagueGet,joinleaguebtnPost} from "../../../api/handleapi";
import {  useNavigate } from "react-router-dom";
import { Errormsg } from "../../../components/tostifymsg/Errrormsg";
import {  useDispatch } from 'react-redux';
import { setuplState } from '../../../feature/showSlice/uplstateSlice';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import Dummyads from "../../../components/google-AdSense/Dummyads";
import Cookies from 'js-cookie';
import swal from "sweetalert";
import { alreadyjoinMsg, errorstatuscheck, reloginMsg, successfullyJoinedMsg, successfullyTitle } from "../../../components/language/Language";
import { handle401Error } from "../../../components/handle401Error";
import Googleadshorizontal from "../../../components/google-AdSense/Googleadshorizontal";


function Myleagueupcoming() {
  const [fetchdata , setFetchData] = useState(false);
  const [btntoggledraft, setBtntoggledraft] = useState(); 
  const dispatch = useDispatch();
  const Navigate=useNavigate();
  const [hiddenButtons, setHiddenButtons] = useState({});
  const id=Cookies.get("id");
  const [postupcoming, setPostupcoming] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const itemsPerPage = 4; // Replace with your actual items per page
  const totalPages = Math.ceil(postupcoming?.length / itemsPerPage);
  const reversedData = postupcoming?.slice().reverse(); // Create a copy of the array and reverse it
  const userid=Cookies.get("id")
  const handlePageClick = ({ selected }) => {
    setPageNum(selected+1);
  };
  const currentPageData = reversedData?.slice((pageNum - 1) * itemsPerPage, pageNum * itemsPerPage);

useEffect(() => {
     const upcomingFun = async (id) => {
      try {
        const response = await upcomingleagueGet(id); // You might want to pass some parameters to get
        const updateres = response?.data?.map((data, index) => {
          return {
            ...data,
            draftenabled: true,
          };
        });
        setPostupcoming(updateres);
      } catch (error) {
        if(error?.message==errorstatuscheck){         
          await Errormsg(reloginMsg);
          await handle401Error(cookieNames);
          await Navigate("/");
      
        }
      }
    };
    upcomingFun(id);
  },[]);


  useEffect(() => {
      const intervalId = setInterval(() => {
      let currentTime = moment.tz("Europe/London").toDate(); // Replace "Europe/London" with your desired timezone
    let currentTimeString = moment(currentTime).format("YYYY-MM-DDTHH:mm:ss");  // this now variable used for local environment
  
    const now = new Date().toLocaleString(undefined, { timeZone: 'utc' }); // this now variable used for UAT environment
    setBtntoggledraft(new Date(now).getTime());
  }, 1000);
},[])


useEffect(() => {
  const updatedstate = postupcoming?.map((data, index) => {
    if (new Date(data?.startDateTime).getTime()<=btntoggledraft) {
      return {        
        ...data,
        draftenabled: false,  
      };
    }
    return data;
  });
  setPostupcoming(updatedstate);
}, [btntoggledraft])


  const onSubmitForm = (LeagueId,entries,leaguename,visibility,templateTypeid,iimage,cdate,sdate) => {
    Navigate(`/Draft/${LeagueId}`);
      if(LeagueId){
        dispatch(
          setuplState({
            LeagueId:LeagueId,
            LeagueName: leaguename,
            Entries: entries,
            Visibility:visibility,
            templateTypeId:templateTypeid,
            poster:iimage,
            createdDate:cdate,
            startDateTime:sdate,
          })
        );
      }
     };

  

     const join_l_post = async (LeagueId,leagueName) => {
      var bodyFormData = new FormData();
      bodyFormData.append("UserId", userid);
      bodyFormData.append("LeagueId", LeagueId );
      try{
        const response = await joinleaguebtnPost(bodyFormData)
            setFetchData(!fetchdata)
            setHiddenButtons(prevState => ({ ...prevState, [LeagueId]: true }));             
            swal({
              title: successfullyTitle,
              text: `${successfullyJoinedMsg} ${leagueName}`,
              icon: "success",
              buttons: "ok",
              closeOnClickOutside:false,
              dangerMode: false
            })
            
      }
        catch(error){
          swal({
            title: "",
            text: `${alreadyjoinMsg} ${leagueName}`,
            icon: "error",
            buttons: "ok",
            closeOnClickOutside:false,
            dangerMode: false
          })
      }
    };


  return (
    <div>
      <Navbarr/>
      <div className="after-nav-dsubheader containers con_div">
      <Googleadshorizontal props="pt-2 pb-2 text-center"/>
        <Subheader />
        <div className="Dasboard-cards">
          <div className="afterdashboard_nav">
            
        {currentPageData?.length > 0 ? 
        currentPageData?.map((data, index) => (
        <div key={index}>
          <div className="card d-Cardf">
            <div className="after_row_div">
              <div className="row">
                <div className="col-xl-1 col-lg-3 col-md-12 col-sm-12 imag-cardclass">
                <img src={imgpath + data?.image} alt="img" className="dleague-card-img"  
       onError={(e) => {
    e.target.onerror = null;
    e.target.src = firstitem }}/>
                </div>
                <div className="col-xl-11 col-lg-10 col-md-12 col-sm-12 league-card-content">
                  <div>
                    <p className="card-title ">{data?.leagueName}</p>
                    <div className="tags">
                      <span className="btn btn-primary PP-btn ">
                      {data?.visibility}
                      </span>
                      <span className="btn btn-primary PP-btn ">
                        Upcoming
                      </span>
                    </div>
                  </div>

                  <div>
                    <p className="card-title upcoming_time_class">
                    <CountDownTimer key={index} createdatetime={new Date(data?.createdDate)} startdatetime={new Date(data?.startDateTime)} setBtntoggledraft={setBtntoggledraft} LeagueId={data?.id}/>
                     </p>
                  </div>
                  <div>
                    <p className="card-title">                    
                      {data?.templateName}
                      </p>
                    <p className="card-title"> No of Players: {data?.totalPlayers}/{data?.entries}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="dleague-card-hr" />
            <nav className="dleague-card-nav">
              <div className="dleague-card-nav-span">
              <div className="datetimeleague">
                <div className="time_details">
           
                
                  <div className="time_with_date">
                  <span>Created: </span>
                  <div className="cal-time">
                  {" "}
                  <YourIcon />
             
                 
                  <span className="data m-1">
                 
                    { moment(new Date(data?.createdDate + 'Z')).format("dddd, MMMM D, YYYY")}
                     </span>
             
                </div>
                <div className="v">
                  <YourIcon1 />
                  <span className="time m-1">
                 
                    { moment(new Date(data?.createdDate + 'Z')).format("h:mm a")}
                    </span>
                </div>
                  </div>
                </div>

                <div className="time_details">
                  <div className="time_with_date">
                  <span>League End: </span>
                  <div className="cal-time">
                  {" "}
                  <YourIcon />
             
                 
                  <span className="data m-1">
                 
                    { moment(new Date(data?.endDateTime + 'Z')).format("dddd, MMMM D, YYYY")}
                     </span>
             
                </div>
                <div className="v">
                  <YourIcon1 />
                  <span className="time m-1">
                 
                    { moment(new Date(data?.createdDate + 'Z')).format("h:mm a")}
                    </span>
                </div>
                  </div>
                </div>
                </div>
                <div className="buttons_draftandjoin d-flex gap-2">

                <div>                
                  <button disabled={data?.draftenabled}  className="btn btn-primary JL-btn"   onClick={()=>onSubmitForm(data?.id,data?.entries,data?.leagueName,data?.visibility,data?.templateTypeId,data?.image,data?.createdDate,data?.startDateTime)}>
                                    Draft 
                  </button>
                </div>
                {!data?.isJoined && !hiddenButtons[data?.id] && 
  <div>
    <button  className="btn btn-primary JL-btn " onClick={()=>join_l_post(data?.id,data?.leagueName)} disabled ={data?.totalPlayers>=data?.entries?true:false}> 
    {data?.totalPlayers>=data?.entries?"Entry Full":"Join League"}
    </button>
  </div>}
                </div>
              </div>
            </nav>
          </div>
          </div>
         )):<div >
         <div className="no_league_found">No data found</div>
                  </div>} 
         </div>
         {postupcoming?.length==0?"":<div>
            <Pagination pageCount={totalPages}  handlePageClick={handlePageClick} />
          </div>}
        </div>
      </div>
      <Footer />

      <ScrollButton />
    </div>
  );
}

export default Myleagueupcoming;
