import React, { useState,useEffect } from 'react'
import  "./Trading.css";
import {accepttradeRequest,rejecttradeRequest,counteroffertradeRequest, notificationGet} from "../../../../../api/handleapi"
import { ReactComponent as YourIcon6 } from "../../../../../assets/Vectorwhite.svg";
import swal from 'sweetalert';
import { ReactComponent as YourIcon4 } from "../../../../../assets/close.svg";
import { ReactComponent as YourIcon5 } from "../../../../../assets/check.svg";
import { useDispatch } from "react-redux";
import {playermoviesGet} from "../../../../../api/handleapi"
import {setTrademoviedetailsforid} from '../../../../../feature/showSlice/trademoviedetailsforidSlice';
import Cookies from 'js-cookie';
import { openModalmdetails } from '../../../../../feature/showSlice/showmodaltrademoviedetailsSlice';
import { useCategory } from '../../../../../components/useCategory';
import { alreadyacceptMsg, apiErrorMsg, APIerrorTitle, counterofferMsg, CounteroffervalidationMsg, successfullyTitle, tradereqAcceptMg, traderequestrejectMsg } from '../../../../../components/language/Language';


function Trading1({ setNotificationget, playername,setShowModalTrading,trademovies,togglestate,setTogglestate,notificationlength,setNotificationlength,showtradeaccpet, setShowTradeaccpet}) {


const suserid=trademovies?.requestFrom?.firstUserId
const userid=trademovies?.tradeRequestFor?.secondUserId
const Leagueid=trademovies?.tradeRequestFor?.leagueId

const [ftrade,setFtrade]=useState({
  FirstUserId :"",
  FMovieId :"",
  FCategoryId :"",
  FMovieName :"",
})

const [strade,setStrade]=useState({
  SecondUserId  :"",
  SMovieId  :"",
  SCategoryId  :"",
  SMovieName  :"",
})


const notificationGetcall = async (userid) => {
  try {
    const response = await notificationGet(userid); // You might want to pass some parameters to notificationget
    setNotificationget(response?.data?.data==undefined||null?[]:response?.data?.data);
    const lengthnotification=response?.data?.data;
    setNotificationlength(lengthnotification?.length==null||undefined?0:lengthnotification?.length)
  } catch (error) {
   return;
  }
};


const Categoryoptions=useCategory();
const [counterofferloginpmovie,setCounterofferloginpmovie]=useState();
const [counteroffersecondpmovie,setCounteroffersecondpmovie]=useState();

const [countermovieslist,setcounterMoviesList] = useState([]);
const [countermovieslistt,setcounterMoviesListt] = useState([]);
  const dispatch=useDispatch();


    const movieslistFunforloginp = async (Leagueid,userid) => {
      try {
        const response = await playermoviesGet(Leagueid,userid); 
        Cookies.set("League_idforcounter",Leagueid);
        setCounterofferloginpmovie(response?.data?.data?.logedInPlayerMovies)
         } catch (error) {
        return;
      }
    }



      const movielistFunforotherp = async(Leagueid,suserid) => {
        try {
          const response = await playermoviesGet(Leagueid,suserid); 
          Cookies.set("secondpid",response?.data?.data?.playerId)
          Cookies.set("League_idforcounter",Leagueid);
           Cookies.set("secondpname",response?.data?.data?.playerName)
          setCounteroffersecondpmovie(response?.data?.data?.logedInPlayerMovies)
             }
           catch (error) {
            return;
        }
      };
  

  const onsubmitCounter = async() => {
    await movieslistFunforloginp(Leagueid,userid);
    await  movielistFunforotherp(Leagueid,suserid);
    await setTogglestate(false);
  };

  const acceptTrade = async() => {

    var bodyFormData = new FormData();
    bodyFormData.append("LeagueId", trademovies?.requestFrom?.leagueId);
    bodyFormData.append("FirstUserId", trademovies?.tradeRequestFor?.secondUserId);
    bodyFormData.append("FMovieId",trademovies?.tradeRequestFor?.sMovieId);
    bodyFormData.append("FCategoryId",trademovies?.tradeRequestFor?.sCategoryId);
    bodyFormData.append("FMovieName", trademovies?.tradeRequestFor?.sMovieName);
    bodyFormData.append("SecondUserId", trademovies?.requestFrom?.firstUserId);
    bodyFormData.append("SMovieId", trademovies?.requestFrom?.fMovieId);
    bodyFormData.append("SCategoryId",  trademovies?.requestFrom?.fCategoryId);
    bodyFormData.append("SMovieName",trademovies?.requestFrom?.fMovieName);
     try {
      const response =  await accepttradeRequest(bodyFormData);
      if (response?.status === 200) {
        swal({
          title: successfullyTitle,
          text: tradereqAcceptMg,
          icon: "success",
          buttons: "Ok",
          closeOnClickOutside:false,
          dangerMode: false,
        }).then((value) => {
          if(value){
            setShowModalTrading(false);
            movieslistFunforloginp(Leagueid,userid);
            setNotificationlength(notificationlength-1)
            notificationGetcall(Cookies.get("id"))
               
          }})
      } 
    } 
    catch (error) {
      if (error.response.data?.message!=undefined) {
              
                swal({
                  title: "",
                  text: error.response.data.message,
                  icon: "",
                  buttons: "ok",
                  closeOnClickOutside:false,
                  dangerMode: false
                }).then((value) => {
                  if(value){
                    setShowModalTrading(false);
                  }})
              }         
              else if (error.response.data?.message===undefined) {
                swal({
                    title: "",
                    text: alreadyacceptMsg,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  }).then((value) => {
                    if(value){
                      setShowModalTrading(false);
                    }})
              }else {
               return;
              }
 
    }
  }
  const rejectTrade = async() => {
    var bodyFormData = new FormData();
    bodyFormData.append("LeagueId", trademovies?.requestFrom?.leagueId);
    bodyFormData.append("FirstUserId", trademovies?.tradeRequestFor?.secondUserId);
    bodyFormData.append("FMovieId",trademovies?.tradeRequestFor?.sMovieId);
    bodyFormData.append("FCategoryId",trademovies?.tradeRequestFor?.sCategoryId);
    bodyFormData.append("FMovieName", trademovies?.tradeRequestFor?.sMovieName);
    bodyFormData.append("SecondUserId", trademovies?.requestFrom?.firstUserId);
    bodyFormData.append("SMovieId", trademovies?.requestFrom?.fMovieId);
    bodyFormData.append("SCategoryId",  trademovies?.requestFrom?.fCategoryId);
    bodyFormData.append("SMovieName",trademovies?.requestFrom?.fMovieName);
     try {
      const response =  await rejecttradeRequest(bodyFormData);
      if (response?.status === 200) {
        swal({
          title: "",
          text: traderequestrejectMsg,
          icon: "success",
          buttons: "Ok",
          closeOnClickOutside:false,
          dangerMode: false,
        }).then((value) => {
          if(value){
            setShowModalTrading(false);
            setNotificationlength(notificationlength-1)
            notificationGetcall(Cookies.get("id"))
                    }})
      } 
    } 
    catch (error) {
      if (error.response.data?.message!=undefined) {
            return;
              }         
              else {
                return;
              }
    }
  }
  const openModalMdetails = (movieId) => {
    dispatch(openModalmdetails(true)); 
    dispatch(setTrademoviedetailsforid(movieId));
 
};
const secondusername=Cookies.get("secondpusername");
  const firstpid=Cookies.get("id");
  const secondpid=Cookies.get("secondpid");
  const Leagueidforcounter=Cookies.get("League_idforcounter");


  const counter_trade = async() => {
    var bodyFormData = new FormData();
    bodyFormData.append("LeagueId", Leagueidforcounter);
    bodyFormData.append("FirstUserId", ftrade?.FirstUserId);
    bodyFormData.append("FMovieId", trademovies?.tradeRequestFor?.sMovieId);
    bodyFormData.append("FCategoryId",  trademovies?.tradeRequestFor?.sCategoryId);
    bodyFormData.append("FMovieName", trademovies?.tradeRequestFor?.sMovieName);
    bodyFormData.append("SecondUserId", strade?.SecondUserId);
    bodyFormData.append("SMovieId", strade?.SMovieId);
    bodyFormData.append("SCategoryId", strade?.SCategoryId);
    bodyFormData.append("SMovieName", strade?.SMovieName);
    bodyFormData.append("FSMovieId", ftrade?.FMovieId);
    bodyFormData.append("FSCategoryId", ftrade?.FCategoryId);
    bodyFormData.append("FSMovieName", ftrade?.FMovieName);
     try {
      const response =  await counteroffertradeRequest(bodyFormData);
      if (response?.status === 200) {
        swal({
          title: successfullyTitle,
          text: counterofferMsg,
          icon: "success",
          buttons: "Ok",
          closeOnClickOutside:false,
          dangerMode: false,
        }).then((value) => {
          if(value){
            setShowModalTrading(false);
            Cookies.remove("League_idforcounter");
            Cookies.remove("secondpname");
            setNotificationlength(notificationlength-1)
          }})
      } 
    } 
    catch (error) {
      if (error.response.data?.message!=undefined) {
                swal({
                  title: "",
                  text: error.response.data.message,
                  icon: "error",
                  buttons: "ok",
                  closeOnClickOutside:false,
                  dangerMode: false
                })
              }         
              else if (error?.response?.data?.message===undefined) {
                swal({
                    title: "",
                    text: CounteroffervalidationMsg,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  })
              }else {
                swal({
                  title:APIerrorTitle,
                  text: apiErrorMsg,
                  icon: "error",
                  buttons: "ok",
                  closeOnClickOutside:false,
                  dangerMode: false
                })
              }
    }
  }
  const handleIconClick = (movieid) => {
    let temp_movielist = [...countermovieslist]

    let updatedData = temp_movielist?.map((each)=>{
      if(each.movieId==movieid){

        setFtrade({ FirstUserId :firstpid,FMovieId:each?.movieId,FCategoryId:each?.categoryId,FMovieName:each?.movieName})
        return {...each,checked:!each.checked}
      }
   
      return {...each,checked:false}
  })
    setcounterMoviesList(updatedData);
  };

  const handleIconClickk = (movieId) => {


    let temp_movielist = [...countermovieslistt]

    let updatedData = temp_movielist?.map((each)=>{
      if(each?.movieId==movieId){
        setStrade ({
                  SecondUserId:secondpid,
                  SMovieId  :each?.movieId,
                  SCategoryId  :each?.categoryId,
                  SMovieName  :each?.movieName,
                });
        return {...each,checked:!each.checked}
      }
      return {...each,checked:false}
  })

    setcounterMoviesListt(updatedData);
  };
  
  useEffect(() => {
  const updatedMoviesArray = counterofferloginpmovie?.map((movie) => ({
    ...movie,
    checked: false,
}));
const updatedMoviesforfinals = updatedMoviesArray?.filter(movie => movie?.finalScore === 0 );

  setcounterMoviesList(updatedMoviesforfinals);
  const updatedMoviesArrayy = counteroffersecondpmovie?.map((movie) => ({
    ...movie,
    checked: false,
}));
  const secondpupdatedMoviesforfinals = updatedMoviesArrayy?.filter(movie => movie?.finalScore === 0 );
  setcounterMoviesListt(secondpupdatedMoviesforfinals);

  },[togglestate]);

  return (
    (togglestate?
    <div>
  <div className="row">
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div className="heading_live_listt"><h6>My Movies List</h6></div>
              <div className="position_card">
  <div className="table_containerr_trade scrollbarcstm"> 
    <table className="playermovie_list">
  <thead  >
    <tr className="trade_movieslist_headings">
      <td >Movie</td>
      <td >Category</td>
      <td >Score</td>
      <td >Actions</td>
    </tr>
  </thead>
  <tbody >

    <tr className="Player_title">
          <td>
             <div className='d-flex gap-2'>
          
             {trademovies?.tradeRequestFor?.sMovieName.length > 20 ? trademovies?.tradeRequestFor?.sMovieName.substring(0, 20) + '...' : trademovies?.tradeRequestFor?.sMovieName}
            
             </div>
             </td>   
      <td>{Categoryoptions?.find(cat => cat?.id == trademovies?.tradeRequestFor?.sCategoryId)?.category}</td>
      <td>{trademovies?.tradeRequestFor?.finalScore==null||undefined?0:trademovies?.tradeRequestFor?.finalScore}</td>
      <td className="campareicons">
      <input type="checkbox" checked />
      </td>
    </tr>
   
       
  </tbody>
</table>
            </div>
          </div>

    </div>
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div className="heading_live_listt">
        <h6> 
        {playername?.charAt(0).toUpperCase()+ playername?.slice(1)}'s Movies List</h6>
      </div>
          
        <div className="position_card">
  <div className="table_containerr_trade scrollbarcstm"> 
    <table className="playermovie_list">
  <thead  >
    <tr className="trade_movieslist_headings">
      <td >Movie</td>
      <td >Category</td>
      <td >Score</td>
      <td >Actions</td>
    </tr>
  </thead>
  <tbody >
    <tr className="Player_title"  >
          <td>
             <div className='d-flex gap-2'>
           
             {trademovies?.requestFrom?.fMovieName?.length > 20 ? trademovies?.requestFrom?.fMovieName.substring(0, 20) + '...' : trademovies?.requestFrom?.fMovieName}
             
             </div>
             </td>   
      <td>{Categoryoptions?.find(cat => cat?.id == trademovies?.requestFrom?.fCategoryId)?.category}</td>
      <td>{trademovies?.requestFrom?.finalScore==undefined||null?0:trademovies?.requestFrom?.finalScore}</td>
      <td className="campareicons">
     
      <input type="checkbox"  checked/>
      </td>
    </tr>
       
  </tbody>
</table>
            </div>
          </div>
    </div>
  </div>
  <div className='trading1_btns'>
  <div className='Trade_modal_btn' onClick={rejectTrade}>
  <button className="ttrade-btns-reject"><YourIcon4 className="trade_icon" /> Reject</button>
  </div>
  <div className='Trade_modal_btn' onClick={acceptTrade}>
  <button className="ttrade-btns-accept"><YourIcon5  className="trade_icon"/> Accept</button>
  </div>
  <div className='Trade_modal_btn' onClick={onsubmitCounter}>
  <button className="ttrade-btns"><YourIcon6  className="trade_icon"/> Counter Offer</button>
  </div>
</div>
</div>
 :
 
 <div>
 <div className="row">
   <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
     <div className="heading_live_listt"><h6>My Movies List</h6></div>
           <div className="position_card">
 <div className="table_containerr_trade scrollbarcstm"> 
   <table className="playermovie_list">
 <thead  >
   <tr className="trade_movieslist_headings">
     <td >Movie</td>
     <td >Category</td>
     <td >Score</td>
     <td >Actions</td>
   </tr>
 </thead>
 <tbody >

 {
 countermovieslist?.length!=0?
 countermovieslist?.map((data,index)=>(
  
   <tr className="Player_title" key={index} >
  
         <td>
            <div className='d-flex gap-2'>
           
            <span className='moviesnamefordetails' onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 30 ? data?.movieName.substring(0, 30) + '...' : data?.movieName}</span>
            </div>
            </td>   
     <td>{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
     <td>{data?.finalScore}</td>
   
     <td className="campareicons checkcounter">
     <input type="checkbox" className='form-check-input' checked={data?.checked}  onClick={() => handleIconClick(data?.movieId)}/>
       </td>
   </tr>
   )):<div className='no_dropm_found'>No movies found</div>
   } 
      
 </tbody>
</table>
           </div>
         </div>
   </div>
   <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
     <div className="heading_live_listt">
       <h6>
       {Cookies.get("secondpname")?.charAt(0).toUpperCase()+ Cookies.get("secondpname")?.slice(1)}'s Movies List</h6>
     </div>
           <div className="position_card">
 <div className="table_containerr_trade scrollbarcstm"> 
   <table className="playermovie_list">
 <thead  >
   <tr className="trade_movieslist_headings">
     <td >Movie</td>
     <td >Category</td>
     <td >Score</td>
     <td >Actions</td>
   </tr>
 </thead>
 <tbody >
   {countermovieslistt?.length!=0?
 countermovieslistt?.map((data,index)=>(
   <tr className="Player_title" key={index} >
         <td >
         <div className='d-flex gap-2'>
        
            <span className='moviesnamefordetails'onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 30 ? data?.movieName.substring(0, 30) + '...' : data?.movieName}</span>
            </div>
         </td>
     <td >{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
     <td >{data?.finalScore}</td>
     <td className="campareicons checkcounter">
     
      <input type="checkbox" className='form-check-input'  checked={data?.checked}  onChange={()=>handleIconClickk(data?.movieId)} />
      </td>
   </tr>
   )):<div className='no_dropm_found'>No movies found</div>
   } 
 </tbody>
</table>
           </div>
         </div>
   </div>
 </div>
 <div className='Trade_modal_btn' onClick={counter_trade}>
  <button className="ttrade-btns"><YourIcon6  className="trade_icon"/> Counter Offer</button>
  </div>
</div>)
  )
}

export default Trading1