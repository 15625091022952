import React from 'react';
import "./LoadMore.css"
const LoadMore = ({ onLoadMore }) => {
  return (
    <div className="loadmbtn">
      <button type="button" className="btn btn-primary" onClick={onLoadMore}>
        Load More
      </button>
    </div>
  );
};

export default LoadMore;
