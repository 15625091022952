import React from 'react'
import './Loader.css'
function Loader() {
  return (
    
        <div className='lodder-container'>
            <div className="spinner"></div>
        </div>
    
  )
}

export default Loader;