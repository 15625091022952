import React, { useState,useEffect } from "react";
import Navbarr from "../../../components/header/Navbarr";
import { Footer } from "../../../components/footer/footer";
import ScrollButton from "../../../components/scrollbutton/ScrollButton";
import "./Moviedetails.css";
import {upcomingmoviesGet} from "../../../api/handleapi";
import {moviedetailsGet,movieaddWatchlistost} from "../../../api/handleapi"
import { setWatchlistcount } from '../../../feature/showSlice/watchlistcountSlice';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {movieposterpath, settings } from "../../../Config/config"
import {Errormsg} from "../../../components/tostifymsg/Errrormsg";
import { Succesmsg } from "../../../components/tostifymsg/Succesmsg";
import { openModal } from '../../../feature/showSlice/showModalSlice';
import { useDispatch,useSelector } from 'react-redux';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firstitem from "../../../assets/dummy-movie.png";
import imdblogo from "../../../assets/IMDB_Logo_2016.png";
import {  Link } from "react-router-dom";
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import { useCategory } from "../../../components/useCategory";
import Loader from "../../../components/loader/Loader";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { addedtoWatchlist, errorstatuscheck, loginfirslyMsg } from "../../../components/language/Language";


function Moviedetails() {

const { id } = useParams();
const [loading,setLoading]=useState(false)
const movieId=id;
  const [loadingup, setLoadingup] = useState(true);
  const [movie_d,setMovie_d]=useState();
  const [upcomingm,setUpcomingm]=useState([]);
  const [hiddenButtons, setHiddenButtons] = useState({});
  const watchlistcount = useSelector((state) => state.watchlistcount);

  const dispatch = useDispatch();
  const handleOpen = () => {
    dispatch(openModal());
  };
  const Categoryoptions=useCategory();
  const [showdiv, setShowdiv] = useState("SHOWING_SUMMARY");
  const activefor = () => {

    document.querySelectorAll(".btn.cat").forEach((button) => {

      button.addEventListener("click", function () {

        // Remove the 'active' class from all buttons
        document.querySelectorAll(".btn.cat").forEach((button) => {

          button?.classList?.remove("active");
        });
        // Add the 'active' class to the clicked button
        this.classList?.add("active");
      });
    });
  };


   useEffect(() => {
    activefor();
  });
  const moviedetailsFun = async (movieId) => {
    try {
      setLoading(true);
      const response = await moviedetailsGet(movieId); // You might want to pass some parameters to get
      setMovie_d(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return;
    }
  };
  useEffect(() => {
    moviedetailsFun(movieId);
      }, []);

      const upcomingmoviesFun = async () => {
        try {
          const response = await upcomingmoviesGet(); // You might want to pass some parameters to get
          setUpcomingm(response?.data?.data);
          setLoadingup(false);
        } catch (error) {
          if(error?.message==errorstatuscheck){
           return;
          }
         
        }
      };
      useEffect(() => {
        upcomingmoviesFun();
          }, []);
    
          const addWatchlist = async(movieId) => {
            var bodyFormData = new FormData();
            bodyFormData.append("movieId", movieId);
            bodyFormData.append("UserId", Cookies.get("id"));
            
            try {
              const response =  await movieaddWatchlistost(bodyFormData);
              if (response?.status === 200) {
               setHiddenButtons(prevState => ({ ...prevState, [movieId]: true }));  
                Succesmsg(addedtoWatchlist);
                dispatch(setWatchlistcount(watchlistcount+1));
              } 
            } catch (error) {
              
              if (error.response) {
                if (error.response.data.message) {
                  // Show an alert with the message
                  Errormsg(error?.response?.data?.message);
              }
              
                 
                 await Cookies.set("navivalue", `/Moviedetails/${movieId}`);
                 await  Errormsg(loginfirslyMsg);
                 await  handleOpen()
                // The request was made and the server responded with a status code
                
              } 
            }
          };


  const openSummry = async() => {
   await setShowdiv("SHOWING_SUMMARY");
   
    await activefor();

  };

  const openCast = async() => {
    await setShowdiv("SHOWING_CAST");
  
    await activefor();

  };
const handlemovieIdChange=async(movieid_upcoming)=>{
  await moviedetailsFun(movieid_upcoming)
  await upcomingmoviesFun()

}

  return (
    <div>
      <Navbarr />
      <ToastContainer />
      <div className="m_details ">
        <div className="containers con_div">
          <div className=" row g-0 row_cards">
            <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12">
           <div className="moviedetails_banner">
           
      {movie_d?.poster?.includes("https://m.media-amazon.com/images") ? 
    <img src={movie_d?.poster} alt="img" className="photo_div_images moviesd_bannerimg" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} /> : 
    <img src={movieposterpath+ movie_d?.poster} alt="img" className="photo_div_images moviesd_bannerimg" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} />
  }
           </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12">
            {movie_d && 
            <div className="movie-details-content ">
 <div   className="card-body M_details_Card M_de_cardbody ">
                <h4 className="card-title mt-3">
                {movie_d?.name}</h4>
                <div className="bbtnaora">
                {movie_d?.category?.split(',')?.map((categoryId) => {
        const category = Categoryoptions?.find((opt) => opt?.id == categoryId);
        if (category) {
          return (
            <span className="btn btn-primary aadbtn_category" key={category?.id}>
              {category?.category}
            </span>
          );
        }
        return null;
      })}
                 
                </div>
                          
                <div className="M_de_card_scores row">
                  <span className="details_head_Para col-md-4">
                    <h4 className="t_score">
                      {movie_d?.tomatoMeter}
                      </h4>
                    <p className="para_t_Score">TOMATOMETER</p>
                  </span>
                  <span className="details_head_Para col-md-4">
                    <h4 className="t_score">
                     
                      {movie_d?.audienceScore}
                      </h4>
                    <p className="para_t_Score">AUDIENCE SCORE</p>
                  </span>
                  <span className="details_head_Para col-md-4">
                    <h4 className="t_score">
                      
                      {movie_d?.metaCritic}
                      </h4>
                    <p className="para_t_Score">METASCORE</p>
                  </span>
                </div>
                <div className="M_de_card_scoress  row">
                <span className="details_head_Para col-md-4">
                    <a  className="imdbrating_tag" target="_blank" href={`https://www.imdb.com/title/${movie_d?.movieId}/ratings/?ref_=tt_ov_rt`}>
                    <h4 className="t_score">
                    <img src={imdblogo} alt="img" className="imdblogo_d" />{" "}
                      {movie_d?.imDbRating}/10
                      </h4>
                      </a>
                    <p className="para_t_Score">IMDb RATING</p>
                  </span>
                 
                  <span className="details_head_Para col-md-4">
                    <h4 className="t_score">
                    {movie_d?.letterBoxdScore}
                      </h4>
                    <p className="para_t_Score">LETTERBOXD SCORE</p>
                  </span>
                  <span className="details_head_Para col-md-4">
                    <h4 className="t_score">
                    
                      {movie_d?.letterboxdBonus}
                      </h4>
                    <p className="para_t_Score">LETTERBOXD BONUS</p>
                  </span>
                </div>
                <div className="M_de_card_scoress row">
                <span className="details_head_Para col-md-4 ">
                  
                    <h4 className="t_score">
                      {movie_d?.boxOffice}
                      </h4>
                     
                    <p className="para_t_Score">BOX OFFICE SCORE</p>
                  </span>
                 
                  <span className="details_head_Para col-md-4" >
                    <h5 className="t_score">
                    {movie_d?.boxOfficeBonus}
                      </h5>
                    <p className="para_t_Score">BOX OFFICE BONUS</p>
                  </span>
                  <span className="details_head_Para col-md-4">
                    <h5 className="t_score">
                    {movie_d?.h2HBonusBoxOffice>0?5:0}
                    
                      </h5>
                    <p className="para_t_Score">HEAD 2 HEAD BONUS (BOX OFFICE)</p>
                  </span>
                </div>
                <div className="M_de_card_scores_last row">
                <span className="details_head_Para col-md-4" >
                  
                    <h4 className="t_score">
                  
                    {movie_d?.h2HBonusLetterBoxd >0?5:0}
                    
                      </h4>
                     
                    <p className="para_t_Score">HEAD 2 HEAD BONUS (LETTERBOXD)</p>
                  </span>
                 
                  <span className="details_head_Para col-md-4">
                    <h5 className="t_score">
                    {movie_d?.oscarBonus}
                      </h5>
                    <p className="para_t_Score">OSCAR BONUS</p>
                  </span>
                </div>
                <div className="row justify-content-between mt-4" >
<div className="col-md-6">

                <div className="head_w_para mt-3">
                  <h6 className="H_forbold">Production Status</h6>

                  <p className="para_t_Score">{movie_d?.production==null || undefined?"N/A":movie_d?.production}</p>
                
                </div>
                <div className="head_w_para mt-3">

                  <h6 className="H_forbold">Release Date</h6>
                  <p className="para_t_Score">
                    { (moment(new Date(movie_d?.releaseDate + 'Z')).format("dddd, MMMM D, YYYY")!=
"Invalid date"? moment(new Date(movie_d?.releaseDate + 'Z')).format("dddd, MMMM D, YYYY"):"N/A")}
                    </p>
      
                </div>
                <div className="head_w_para mt-3">
                <h6 className="H_forbold">Runtime</h6>
                  <p className="para_t_Score">
                    {movie_d?.runTime == null || undefined?"N/A":movie_d?.runTime}
                    </p>
                </div>
                <div className="head_w_para mt-3">
                <h6 className="H_forbold">Rating</h6>
                <p className="para_t_Score">{movie_d?.rating==null || undefined?"N/A":movie_d?.rating}</p>
                </div>
                </div>
                <div className="col-md-4"> 
                <span className="details_head_Para details_total_para">
                    <h1 className={movie_d?.totalScore < 30 ? 'total_score_red' : movie_d?.totalScore <= 39.9 ? 'total_score_yellow' : 'total_score_green'}
                    >
                    {movie_d?.totalScore}
                                         </h1>
                    <h5 className="para_t_Score ">TOTAL SCORE</h5>
                  </span>
                  </div>
                </div>
                <button className="moviedetails_addtowatchlist" onClick={() => addWatchlist(movieId)}>
                  {" "}
                  Add to Watchlist
                </button>
              </div>

            </div>
             
            } 
            </div>
          </div>
          <div className="card-header c_header">
            <div className="div_items">
              <button
                
                className={`btn cat active`}
                onClick={() => {
                  openSummry();
                }}
              >
                Description
              </button>                      
              <button   className={`btn cat`} onClick={() => openCast()}>
                Cast & Crew
              </button>
            </div>
          </div>
          {showdiv === "SHOWING_SUMMARY" && (
            <div className="card M_details_Se_card sumary_div">
              <div className="card-body">
                <p className="card-text">
                          {movie_d?.description} 
                </p>
             
                <div className="head_w_para">
                  <h6 className="H_forbold">Director</h6>

                  <p className="para_t_Score">{movie_d?.director==null || undefined ?"N/A":movie_d?.director}</p>
                </div>
                <div className="head_w_para">
                  <h6 className="H_forbold">Writers</h6>
                  <p className="para_t_Score">
                  {movie_d?.writer==null || undefined?"N/A":movie_d?.writer}
                  </p>
                </div>
                
              </div>
            </div>
          )}
          {showdiv === "SHOWING_CAST" && (
            <div className="card M_details_Se_card cast_crew_div">
              <div className="card-body row g-0 cast_crew_body">
              <ul>
  {movie_d?.cast ? movie_d.cast.split(',').map(actor => (
  <li>
    {actor.trim()}
  </li>
)) : <li>N/A</li>}
</ul>
              
              </div>
              
            </div>
          )}
        
         
        
        </div>
        <div className="Upcom_M_con">
          <div >
            <div className="h_topp">
              <h4>Upcoming Movies</h4>
            </div>
            <div>
            {loadingup ? (
            <div className="spinner-border text-primary" role="status">
  <span className="visually-hidden">Loading...</span>
</div>):upcomingm?.length>0?
            <Slider {...settings}>
            {upcomingm?.map((item, index) => (
              <div className="cccarddm mmmovielistcard moviecard" key={index}>
                  
              <Link className="movieheading_ele" to={`/Moviedetails/${item?.id}`}>
  {item?.poster?.includes("https://m.media-amazon.com/images") ? 
    <img src={item?.poster} alt="img" className="w-100 cccardimg" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} onClick={()=>{handlemovieIdChange(item?.id);window.scrollTo(0, 0)}}/> : 
    <img src={movieposterpath + item?.poster} alt="img" className="w-100 cccardimg" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} onClick={()=>{handlemovieIdChange(item?.id);window.scrollTo(0, 0)}}/>
  }
</Link>
                {!hiddenButtons[item?.id] &&<button className="btn mmcard-btn card-cstm-btn" onClick={() => addWatchlist(item?.id)}>
                  {" "}
                  Add to Watchlist
                </button>}
                <div className="card-body Mcbody movies_cardbody moviebody">
                <Link  className="movieheading_ele"  to={`/Moviedetails/${item?.id}`}>
                <h5 onClick={()=>{handlemovieIdChange(item?.id);window.scrollTo(0, 0)}}>{item?.name}</h5>
</Link>
                
                  <span className="text-offw"> Release year : {item?.releaseYear}</span>
                  <div className="mdetails">
                  <ul className="Tren_m_ul">                     
            {item.category && item.category.split(',').map((categoryId, index) => (
  <l key={index} className="text-offw">
    {Categoryoptions?.find(cat => cat?.id == categoryId)?.category}
  </l>
))}

                    </ul>
                  </div>
                </div>
              </div>))}
              </Slider>:<p className="text-white m-0">No Movies found</p>}
            </div>
          </div>
        </div>
        <br />
      </div>
      <Footer />
      {loading?<Loader/>:null}
      <ScrollButton />
    </div>
  );
}

export default Moviedetails;
