import React from "react";
import { useNavigate } from "react-router-dom";
import "./BannerImage2.css";


export function BannerImage2() {
  const navigate=useNavigate();
  const watchMore=()=>{
    navigate("/Movieslist")
    window.scrollTo(0, 0);
  }
  return (
    
    <div className="main-bsimg ">
      
      <div className="containers con_div">
  
        <div className="Bannersecond">
          <div className="row">
           
            <div className="col-lg-9 col-md-9 col-sm-12">
            <div className="banner-content">
            
            </div>
            </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="banner-content">
          <button className="sbbutton" onClick={()=>watchMore()}>
            <b>Watch More</b>
          </button>
          </div>
          </div>
      
          </div>
       
        </div>
      </div>
    </div>
  );
}
