import { descriptionValition, endDateValidation, entriesvalidation, entriesvalidtionMsg, imageuploadformatvalidationMsg, imageuploadvalidation, leagueNamevalidationMsg, selectcategoryvalidation, selectcategoryvalidationMsg, startDateValidation, templateValidtion, visibilityvalidationMsg } from "../../components/language/Language";

export const lnameValidation = (LeagueName) => {
  if (!LeagueName) {
    return leagueNamevalidationMsg;
  }
  return "";
};
export const descriptionValidation = (Description) => {
  if (!Description) {
    return descriptionValition;
  } 
  return "";
};

export const entriesValidation = (Entries) => {
  if (!Entries) {
    return entriesvalidtionMsg;
  } 
  else if (Entries.value < 2) {
    return entriesvalidation;
  } else if (!new RegExp(/^(10|[2-9])$/).test(Entries)) {
    return entriesvalidation;
  }
  return "";
};

export const startdatetimeValidation = (StartDateTime) => {
  if (!StartDateTime) {
    return startDateValidation;
  }
  return "";
};
export const enddatetimeValidation = (EndDateTime) => {
  if (!EndDateTime) {
    return endDateValidation;
  }  
  return "";
};
export const imagesfileValidation = (Imagesfile) => {
  if (!Imagesfile) {
    return imageuploadvalidation;
  } else if (!/\.(jpg|png)$/i.test(Imagesfile.name)) {
    return imageuploadformatvalidationMsg;
  }
  return "";
};

export const visibilityValidation = (Visibility) => {
  if (!Visibility) {
    return visibilityvalidationMsg;
  }
  return "";
};
export const selectcategoryidsValidation = (SelectedCategoryIds) => {
  if (SelectedCategoryIds.length<1) {
    return selectcategoryvalidation;
  } 
  else if(SelectedCategoryIds.length>15) {
    return selectcategoryvalidationMsg;
}
  return "";
};
export const templatetypeidValidation = (TemplateTypeId) => {
  if (!TemplateTypeId) {
    return templateValidtion;
  }
  return "";
};
