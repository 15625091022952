import { useState } from "react";
import Cookies from 'js-cookie';
import {
messageValidation,
senderNameValidation,
senderEmailValidation,
} from "../../components/validation/Validation";
const touchErrors = (errors) => {
  return Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {});
};
export const Contactusformvalidation = (contactus) => {
  const [errors, setErrors] = useState({
    message: {
      dirty: false,
      error: false,
      message: "",
    },
    sendername: {
      dirty: false,
      error: false,
      message: "",
    },

    senderemail: {
      dirty: false,
      error: false,
      message: "",
    },
  });

  const validateForm = ({
    contactus,
    field,
    errors,
    forceTouchErrors = false,
  }) => {
    let isValid = true;

    // Create a deep copy of the errors
    let nextErrors = JSON.parse(JSON.stringify(errors));

    // Force validate all the fields
    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const {
     message,
     sendername,
     senderemail
    } = contactus;

    if (
      nextErrors.message.dirty &&
      (field ? field === "message" : true)
    ) {
      const mesgMessage = messageValidation(message, contactus);
      nextErrors.message.error = !!mesgMessage;
      nextErrors.message.message = mesgMessage;
      if (!!mesgMessage) isValid = false;
    }
    if (nextErrors.sendername.dirty && (field ? field === "sendername" : true) &&  (!Cookies.get("name"))) {
      const snameMessage = senderNameValidation(sendername, contactus);
      nextErrors.sendername.error = !!snameMessage;
      nextErrors.sendername.message = snameMessage;
      if (!!snameMessage) isValid = false;
    }
    if (
      nextErrors.senderemail.dirty &&
      (field ? field === "senderemail" : true)&&(!Cookies.get("email"))
    ) {
      const emailMessage = senderEmailValidation(senderemail, contactus);
      nextErrors.senderemail.error = !!emailMessage;
      nextErrors.senderemail.message = emailMessage;
      if (!!emailMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({ contactus, field, errors: updatedErrors });
  };

  return {
    validateForm,
    onBlurField,
    errors,
  };
};
