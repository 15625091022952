import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import { styled, ThemeProvider } from "styled-components";
const theme = {
  colors: {
    btn: "white", // color definitions
   
  },
};
function ScrollButton() {
  const [isVisible, setIsVisible] = useState(false);
  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {
    let heightToHidden = 0;
    const winScroll =
     document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  });


  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {isVisible && (
          <div className="top-btn" onClick={goToBtn}>
            <FaArrowUp className="top-btn--icon" />
          </div>
        )}
      </Wrapper>
    </ThemeProvider>
  );
}
const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  .top-btn {
    font-size: 1.4rem;
    width: 3rem;
    height: 3rem;
    color: black;
    background-color: ${({ theme }) => theme.colors.btn};
    box-shadow: ${({ theme }) => theme.colors.shadow};
    border-radius: 50%;
    position: fixed;
    bottom: 0.5rem;
    right: 2rem;
    z-index: 999;
    display: flex;
    justify-content: center;
     transition: all 300ms linear;
    align-items: center;
    cursor: pointer;
    &--icon {
      animation: gototop linear infinite alternate-reverse;
      
    }
    @keyframes gototop {
      0% {
        transform: translateY(-0.5rem);
        
      }
      100% {
        transform: translateY(0.5rem);
        
      }
    }
   
  }
`;
export default ScrollButton;
