import React,{useEffect, useState} from 'react'
import "./Joinlcategory.css"
import { useSelector} from 'react-redux';
import { joinLmovieppost ,joinLmoviepickgett,draftleaguetimeGet} from '../../../../api/handleapi';
import { Succesmsg } from "../../../../components/tostifymsg/Succesmsg";
import { Errormsg } from "../../../../components/tostifymsg/Errrormsg";
import Cookies from 'js-cookie';
import { useCategory } from '../../../../components/useCategory';
import { categorynotmatchforDraft, moviePickAuthcheck, moviesscoregetMsgfordraft, pickSuccessful } from '../../../../components/language/Language';

function Joinl_category({indexs,setIndex,setShowModalcategory,categoryDataMovieCountClick,fetchdata,setFetchData,setHiddenButtons,value,setValue,draftordertime,setDraftordertime}) {
  const Categoryoptions=useCategory();
  const [matchedValues,setMatchedValues] = useState();
  const [newArray, setnewArray] = useState([]);
const moviepickData = useSelector((state) => state.moviepickData);
const signalr = useSelector((state) => state.signalrData);
const signalrData = useSelector((state) => state.signalrData?.orderedPlayers);
const leaguelived = useSelector((state) => state.leaguelived);
const userid=Cookies.get("id")
const categoryv=leaguelived?.category;
const categoryArraybasedonleague = categoryv?.split(",").map(value => parseInt(value));
let categoryArray_m = moviepickData?.Category?.split(",").map(value => parseInt(value));
const categoryArray=matchedValues?.filter((value) => matchedValues?.includes(value));

  
for (let i = 0; i < categoryArraybasedonleague.length; i++) {
  if (categoryArraybasedonleague[i] == 2094) {
      categoryArray_m?.push(2094,2065,2066);
     
  } else if (categoryArraybasedonleague[i] == 2095) {
      
          categoryArray_m?.push(2095,2065,2066);
      
  }
  else{
    categoryArray_m?.push(2065,2066)
  }
}



useEffect(() => {
function compareArrays(categoryArraybasedonleague, categoryArray_m) {
  const result = [];
  for (let i = 0; i < categoryArraybasedonleague?.length; i++) {
    const value = categoryArraybasedonleague[i];

    if (categoryArray_m.includes(value)) {
      result.push(value);
    }
  }

  setMatchedValues(result);
}
compareArrays(categoryArraybasedonleague, categoryArray_m)
},[categoryArraybasedonleague, categoryArray_m])


const categoryArr = categoryArray?.filter((value) => value!== undefined);



useEffect(() => {
  const GetUserMovies = signalrData?.filter((item,index)=>item?.id == userid)[0];

  const selectMoviesList = [];
  [...new Set(categoryArr)].map((item) => {
   
    let listCount = GetUserMovies?.movies?.filter((e) => e.category == item)?.length; 
    let categoryCount = categoryArr?.filter((e) => e == item)?.length;
    if(listCount < categoryCount)
    {
      selectMoviesList.push(item)
    }
  });
  setnewArray(selectMoviesList)
},[categoryArr,userid])





const moviepPost = async(CategoryId) => {

  let countCategory = categoryDataMovieCountClick?.filter((ids) => ids == CategoryId)?.length;
  const GetUserMovies = signalrData?.filter((item,index)=>item?.id == userid )[0]

  let clickornot = GetUserMovies?.movies?.filter((item) => item?.category == CategoryId)?.length;

  if(clickornot >= countCategory){
    return;
  }
  let categoryName;
  Categoryoptions?.forEach((category) => {
    if (category?.id == CategoryId) {
        categoryName = category?.category;
        return categoryName;
    }
});

const msg=`${moviepickData?.Username} selected ${moviepickData?.MovieName} in ${categoryName}`
  var bodyFormData = new FormData();
  bodyFormData.append("LeagueId", moviepickData?.LeagueId);
  bodyFormData.append("PlayerId", moviepickData?.PlayerId);
  bodyFormData.append("MovieId", moviepickData?.MovieId );
  bodyFormData.append("CategoryId",CategoryId);
  bodyFormData.append("MovieName", moviepickData?.MovieName);
  try {
      const response = await joinLmovieppost(bodyFormData)  ;
   await moviepickMsg(moviepickData?.LeagueId,userid,msg);
   await draftordertimeFun(moviepickData?.LeagueId,true)
   await Succesmsg(pickSuccessful)
   await  setShowModalcategory(false);
   await setFetchData(!fetchdata)
   await setHiddenButtons(prevState => ({ ...prevState, [moviepickData?.MovieId]: true })); 
 
  }
  catch(error){

     if (error.response) {
      if(error?.response?.data?.message===moviePickAuthcheck){
        setShowModalcategory(false);
        Errormsg(moviesscoregetMsgfordraft);      
      }
      else{
        setShowModalcategory(false);
        Errormsg(error?.response?.data?.message);      
      return;
      }
      } 
  }

};

const moviepickMsg = async (id,userid,msg) => {

  try {
    const response = await joinLmoviepickgett(id,userid,msg);
    // You might want to pass some parameters to get
  } catch (error) {
   return ;
  }
};


const draftordertimeFun = async (LeagueId,value) => {
  try {
    const response = await draftleaguetimeGet(LeagueId,value); 
    setDraftordertime(response?.data?.data?.remainingTime,'draftorder time remain check')
  
  } catch (error) {
    return;
  }
};



  return (
    <div className='main_category'>
      <div className='category_heading'>
        <h4>
          Select Category
        </h4>
      </div>
      <div className='row'>
   <div className='col-md-12'>  
   <div  className='parent'>
{newArray?.length <= 0 ? (
  <div className="no-matches">
   <b>{categorynotmatchforDraft}</b>
  </div>
) : (
  newArray?.map((data, index) => (
    <div key={index} className="btns_category">
      {Categoryoptions?.map((item, iindex) => {
        return item?.id == data ? (
          <div>
            {item?.id && (
              <button
                className="category_btn"
                onClick={() => moviepPost(item?.id)}
              >
                {item?.category}
              </button>
            )}
          </div>
        ) : null;
      })}
    </div>
  ))
)}

             </div>
   </div>          
            </div>
    </div>
  )

}
export default Joinl_category;