import { createSlice } from '@reduxjs/toolkit';

export const secondpmovieSlice = createSlice({
  name: 'secondpmovie', // Slice name
  initialState: [], // Initial state (empty array in this case)
  reducers: {
    setSecondpmovie: (state, action) => {
      // Update the state with the received data
      return action.payload; // Assuming action.payload contains the new data
    },
  },
});

// Export the action creator
export const { setSecondpmovie } = secondpmovieSlice.actions;

// Export the reducer
export default secondpmovieSlice.reducer;