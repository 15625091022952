import React from "react";
import ScrollButton from "../../components/scrollbutton/ScrollButton";
import Navbarr from "../../components/header/Navbarr";
import { Footer } from "../../components/footer/footer";
import Accordionpage from "./Accordion";
import "./Howitwork.css";
import Latestnewsbanner from "../../components/latestnewsbanner/Latestnewsbanner";
import Dummyads from "../../components/google-AdSense/Dummyads";
import Googleads from "../../components/google-AdSense/Googleads";
import Googleadshorizontal from "../../components/google-AdSense/Googleadshorizontal";


function Howitwork() {
  return (
    <div>
      <Navbarr />
      <Latestnewsbanner text={"How It Works"}/>
      <div className="accodion_content con_div">
      {/* <Dummyads/> */}
      {/* <Googleads props="mt-2 mb-2"/> */}
      <Googleadshorizontal props="mt-2 mb-2 text-center"/>
        <Accordionpage/>
        </div>
      <Footer />
      <ScrollButton />
    </div>
  );
}

export default Howitwork;
//
