import React, { useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import { MdClose } from "react-icons/md";
import Invites from "./Invites";
import "./Modalinvites.css";


export const Modalinvites= ({ showModalInvites, setShowModalInvites,c_league ,league_id}) => {

  const modalRef = useRef();


  const animation = useSpring({
    config: {
      duration: 350,
    },
    opacity: showModalInvites ? 1 : 0,
    transform: showModalInvites ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModalInvites) {
        setShowModalInvites(false);
      }
    },
    [setShowModalInvites, showModalInvites]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    const disableScroll = (e) => {
      e.preventDefault(); // Prevent the default scroll behavior
      window.scroll(0, 0); // Keep the window at the top position
    };

    if (showModalInvites) {
      document.body.style.overflow = "hidden"; // Disable scrolling on the body
      window.addEventListener("scroll", disableScroll); // Prevent scrolling on the window
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling on the body
      window.removeEventListener("scroll", disableScroll); // Enable scrolling on the window
    }

    return () => {
      document.body.style.overflow = "auto"; // Make sure to enable scrolling on the body when the component is unmounted
      window.removeEventListener("scroll", disableScroll); // Enable scrolling on the window when the component is unmounted
    };
  }, [showModalInvites]);

  return (
    <div>
      {showModalInvites ? (
        <div ref={modalRef} className="Background_invites">
          <animated.div style={animation}>
            <div showModalInvites={showModalInvites} className="trading_wrapper_invite ModalWrapper_invites">
              <div className="d-flex w-100">
              <h3>Invite Users</h3>
              <div
              className="CloseModalButton_invites"
                aria-label="Close modal"
                onClick={() => setShowModalInvites((prev) => !prev)}
              >
                <MdClose className="close_btn"/>
                </div>
              </div>
              <div className="ModalContent_invites">
                <Invites setShowModalInvites={setShowModalInvites} c_league={c_league} league_id={league_id}/>
              </div>
            </div>
          </animated.div>
        </div>
      ) : null}
    </div>
  );
};
