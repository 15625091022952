import { createSlice } from '@reduxjs/toolkit';

export const togglestateSlice = createSlice({
  name: 'togglestate', // Slice name
  initialState: 
    true
  , // Initial state (empty array in this case)
  reducers: {
    setTogglestate: (state, action) => {
      // Update the state with the received data
      return action.payload;
    },
  },
});

// Export the action creator
export const { setTogglestate } = togglestateSlice.actions;

// Export the reducer
export default togglestateSlice.reducer;