import React,{useState,useEffect} from 'react'
import { useSelector } from "react-redux";
import Cookies from 'js-cookie';
import { dataadClient, dataadFormat, dataadSlot, datafullwidthResponsive } from '../../Config/config';
function Googleads({props}) {
  const paymentdetail = useSelector((state) => state.paymentdetail);
  const [isSubscriptionOver, setIsSubscriptionOver] = useState(true);
  const subscriptionenddate=Cookies.get("Paymentdetail");
  const Tokencheck=Cookies.get('token');

  useEffect(() => {
    try{
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
   catch(err){ 
 return;
   }  
  }, []);
  
  useEffect(() => {
    const currentDate = new Date();
    if(paymentdetail?.subscriptionEndDate!=null|| paymentdetail?.subscriptionEndDate!=undefined ||subscriptionenddate!=null||undefined)
      {
        setIsSubscriptionOver(
          currentDate.toISOString() <= subscriptionenddate?false:true
        );
      }
   else{
    setIsSubscriptionOver(true);
   }
   
  }, [paymentdetail?.subscriptionEndDate,subscriptionenddate]);


  useEffect(()=>{
    if(Tokencheck==null||undefined)
      {
        setIsSubscriptionOver(true);
  
      }
  },[])
  
  return (
    (isSubscriptionOver? <div className={props}>
  <ins className="adsbygoogle googleads_style"
data-ad-client={dataadClient}
data-ad-slot={dataadSlot}
data-ad-format={dataadFormat}
data-full-width-responsive={datafullwidthResponsive}
>
</ins>
  </div>:null)
  )
}

export default Googleads