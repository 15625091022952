import { createSlice } from '@reduxjs/toolkit';

export const registeruserinfoSlice = createSlice({
  name: 'registeruserinfo', // Slice name
  initialState: {   
  }
  , // Initial state (empty array in this case)
  reducers: {
    setRegisteruserinfo: (state, action) => {
      // Update the state with the received data
      return action.payload;
    },
  },
});

// Export the action creator
export const { setRegisteruserinfo } = registeruserinfoSlice.actions;

// Export the reducer
export default registeruserinfoSlice.reducer;