import React from "react";
import "./Submitbtn.css";
function Submitbtn({onSubmitForm,props}) {
  return (
      <div>
        <button className="cleague-btns" type="submit" onClick={onSubmitForm}>
        {props}
        </button>
    </div>
  );
}

export default Submitbtn;
