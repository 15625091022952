import { createSlice } from '@reduxjs/toolkit';

export const postPickSlice = createSlice({
  name: 'moviepickData', // Slice name
  initialState: {
  }, // Initial state (empty array in this case)
  reducers: {
    setmoviePickData: (state, action) => {
      // Update the state with the received data
      return action.payload;
    },
  },
});

// Export the action creator
export const { setmoviePickData } = postPickSlice.actions;

// Export the reducer
export default postPickSlice.reducer;