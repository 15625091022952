import React, { useEffect, useState } from "react";
import { setSignalrData } from '../../../../feature/showSlice/signalrDataSlice';
import { setmoviePickData } from '../../../../feature/showSlice/postPickSlice';
import { setLeaguelived } from '../../../../feature/showSlice/leaguelivedSlice';
import ScrollButton from "../../../../components/scrollbutton/ScrollButton";
import Subheader from "../../../../components/subheader/Subheader";
import { Footer } from "../../../../components/footer/footer";
import firstitem from "../../../../assets/dummy-movie.png";
import { ReactComponent as YourIcon } from "../../../../assets/mdi_calendar.svg";
import { ReactComponent as YourIcon1 } from "../../../../assets/time.svg";
import "./Joinleague.css";
import Navbarr from "../../../../components/header/Navbarr";
import Accordion from 'react-bootstrap/Accordion';
import { useSelector, useDispatch } from 'react-redux';
import {joinLmoviepickget, leaguedetailsGet,WatchlistmoviesdraftGet,templatedetailsGet,joinLmsgget,movieaddWatchlistost,draftleaguetimeGet,joinLmoviepickgett} from "../../../../api/handleapi";
import * as signalR from '@microsoft/signalr';
import  {CategoryModal}  from "./CategoryModal";
import { movieposterpath,imgpath, REACTAPPSignalRPREFIX, cookieNames} from "../../../../Config/config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Errormsg } from "../../../../components/tostifymsg/Errrormsg";
import { Succesmsg } from "../../../../components/tostifymsg/Succesmsg";
import { useNavigate } from "react-router-dom";
import profileimg1 from "../../../../assets/large profileicon.svg";
import { setWatchlistcount } from '../../../../feature/showSlice/watchlistcountSlice';
import Cookies from 'js-cookie';

import { useParams } from 'react-router-dom';
import { useCategory } from "../../../../components/useCategory";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { addedtoWatchlist, errorstatuscheck, reloginMsg, waitforturnMsg } from "../../../../components/language/Language";
import { handle401Error } from "../../../../components/handle401Error";
import Googleadshorizontal from "../../../../components/google-AdSense/Googleadshorizontal";

//Draft page
function Joinleague() {
  const Categoryoptions=useCategory();
  const Navigate=useNavigate();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [fetchdata , setFetchData] = useState(false);
  const signalrData = useSelector((state) => state.signalrData);
  const leaguelived=useSelector((state)=>state.leaguelived)
  const [showModalcategory, setShowModalcategory] = useState(false);
 
  const [draftordertime, setDraftordertime] = useState("00:00");
  const { id } = useParams();
  const LeagueId=id
  const [templatename,setTemplatename]=useState();
  const username=Cookies.get("username")
  const userid=Cookies.get("id")
const [showallplayer,setShowallplayer]=useState(false);
const [hubConnection, setHubConnection] = useState(null);
const dispatch = useDispatch();
const watchlistcount = useSelector((state) => state.watchlistcount);
const [active, setActive] = useState(0);
const [showwatch,setShowwatch]=useState(false);
const [showwatchlist,setShowwatchlist]=useState([]);
const [message,setMessage]=useState([]);
const categoryv=leaguelived?.category;
const [pickid,setPickid]=useState();
const [indexs,setIndex]=useState();
const [indexnotpick,setIndexnotpick]=useState([]);
const categoryArray = categoryv?.split(",").map(value => parseInt(value));
const [hiddenButtons, setHiddenButtons] = useState({});
const categoryArr = categoryArray?.filter((value) => value !== undefined);
const [callCount, setCallCount] = useState(0);
const [nextcount,setNextcount]=useState(0);
const [movex, setmovex] = useState(0)


const [inputText, setInputText] = useState("");

let inputHandler = (e) => {
  //convert input text to lower case
  var lowerCase = e.target.value.toLowerCase();
  setInputText(lowerCase);
};

const openModalcategory = (name,id,M_category) => {
  setShowModalcategory((showModalcategory) => !showModalcategory);
  if(name){
  dispatch(
    setmoviePickData({
      LeagueId:LeagueId,
      PlayerId: userid,
      MovieId: id,
      MovieName: name,
      Username:username,
      Category:M_category,
    })
  );
}
};
  
useEffect(() => {
  const createHubConnection = async () => {
      const hubConnect = new signalR.HubConnectionBuilder()
        .withUrl(REACTAPPSignalRPREFIX,{
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
          })
          .withAutomaticReconnect()
          .build();
      try {
          await hubConnect.start();         
      } catch (err) {
          return;
      }
      setHubConnection(hubConnect);
  }
  createHubConnection();
}, []);

useEffect(() => {
  if(hubConnection) {
      hubConnection.on('SendNotificationToUser', (data) => {
        
             dispatch(setSignalrData(data));
             data?.players?.map((v,i) => {
            if(v?.isActive === true ){            
              setPickid(v?.id);
              setIndex(i)
            }
            if(v?.notPicked===true){
              setIndexnotpick(pre =>[ ...pre, i]);
            }
          });
      });
     
  }
}, [hubConnection]);

const moviepickMsg = async (LeagueId,userid) => {
  try {
const response = await joinLmoviepickget(LeagueId,userid);
} catch (error) {
 return;
}
};


useEffect(() => {
  moviepickMsg(LeagueId,userid);
}, [hubConnection]);


useEffect(() => {
  const msgGet = async (LeagueId) => {
    try {
      const response = await joinLmsgget(LeagueId); 
      setMessage(response?.data?.data);
    if(indexs==signalrData?.players?.length-1){
    setDraftordertime("00:00")
    setIndex(undefined);
    setmovex(0);
       }
    } catch (error) {
       return;
    }
  };
  msgGet(LeagueId);
}, [signalrData?.message]);


useEffect(() => {
  const leaguedFun = async (LeagueId) => {
    try {
      const response = await leaguedetailsGet(LeagueId); 
      dispatch(setLeaguelived(response?.data?.data));
      templateFun(response?.data?.data?.templateTypeId)
    } catch (error) {
      if(error?.message==errorstatuscheck){         
        await Errormsg(reloginMsg);
     await handle401Error(cookieNames)
        await Navigate("/");
      }
    }
  };
  leaguedFun(LeagueId);
    },[signalrData]);


    const templateFun = async (template_id) => {
      try {
        const response = await templatedetailsGet(template_id); 
        setTemplatename(response?.data?.data?.templateName)
      } catch (error) {
        if(error?.message==errorstatuscheck){         
          await Errormsg(reloginMsg);
          await handle401Error(cookieNames)
          await Navigate("/");
        }
       
      }
    };


useEffect(() => {
  templateFun()
}, [])

const uniquePlayers = [...new Map(signalrData?.players?.map(player => [`${player.id}`, player]))].map(([id, player]) => player);
const noofjoinplayers=uniquePlayers?.length;
Cookies.set("noofjoinplayers",noofjoinplayers)


const [categoryDataMovieCountClick, setcategoryDataMovieCountClick] = useState(categoryArray);

const watchlFun = async (userid,LeagueId) => {
  try {
    const response = await WatchlistmoviesdraftGet(userid,LeagueId); // You might want to pass some parameters to Dashboardget
    setShowwatchlist(response?.data?.data);
    
  } catch (error) {
    return;
  }
};

useEffect(() => {
  watchlFun(userid,LeagueId);
    }, [fetchdata]);

//movie add to watchlist
const createPost = async(movieId) => {
  var bodyFormData = new FormData();
  bodyFormData.append("movieId", movieId);
  bodyFormData.append("userId", userid);
   try {
    const response =  await movieaddWatchlistost(bodyFormData);
    if (response?.status === 200) { 
       Succesmsg(addedtoWatchlist)
       dispatch(setWatchlistcount(watchlistcount+1));
       watchlFun(userid)

    } 
  } 
  catch (error) {
    if (error.response) {
              if (error.response.data.message) {
                // Show an alert with the message
                Errormsg(error.response.data.message);
            }
            }         
          else {
              // Something else happened while setting up the request
              return;
            }
  }}
  
const filteredData = signalrData?.movie?.filter((el) => {
  const nameMatch = el.name.toLowerCase().includes(inputText);
  const categoryMatch = el.category?.includes(selectedCategory);
  if (inputText !== '' && selectedCategory !== 'All') {
    // Scenario 1: Filter by movie name and category
    return nameMatch && categoryMatch;
  } else if (inputText !== '' && (!selectedCategory || selectedCategory === 'All')) {
    // Scenario 2: Filter by movie name only
    return nameMatch;
  } else if (inputText === '' && selectedCategory !== 'All') {
    // Scenario 3: Filter by category only
    return categoryMatch;
  } else {
    // If both inputText and selectedCategory are empty, return all movies
    return true;
  }
})


const filteredWatchlist = showwatchlist?.filter((el) => {
  const nameMatch = el.name.toLowerCase().includes(inputText);
  const categoryMatch = el.category?.includes(selectedCategory);
  if (inputText !== '' && selectedCategory !== 'All') {
    // Scenario 1: Filter by movie name and category
    return nameMatch && categoryMatch;
  } else if (inputText !== '' && (!selectedCategory || selectedCategory === 'All')) {
    // Scenario 2: Filter by movie name only
    return nameMatch;
  } else if (inputText === '' && selectedCategory !== 'All') {
    // Scenario 3: Filter by category only
    return categoryMatch;
  } else {
    // If both inputText and selectedCategory are empty, return all movies
    return true;
  }
})


const filteredMovies = signalrData?.movies?.filter((movie) =>
movie.category?.includes(selectedCategory)
)

const open = (name,id,M_category) => {
  if(indexs<signalrData?.players?.length)
  {
    if(pickid==userid){
      openModalcategory(name,id,M_category);
  }
  else{
    Errormsg(waitforturnMsg)
  } 
  }
else{
  openModalcategory(name,id,M_category);
}
};

const [value,setValue]=useState(false);
const [indexselse,setIndexselse]=useState(0);


 const draftordertimeFun = async (LeagueId,value) => {
    try {
      const response = await draftleaguetimeGet(LeagueId,value); 
      setDraftordertime(response?.data?.data?.remainingTime,'draftorder time remain check');
      if(value===true){
           setShowModalcategory(false);
      }

    } catch (error) {
       return;
    }
  };

        useEffect(() => {
          if(indexs !== undefined){
            const intervalId = setInterval(() => {
              draftordertimeFun(LeagueId,value);
            }, 1000);
        
            // Clear the interval when the component is unmounted or when indexs changes
            return () => clearInterval(intervalId);
          } else {
           
            return;
                    }
        }, [indexs]);

        useEffect(() => {
          if(draftordertime==null){
         if(value==false){
       draftordertimeFun(LeagueId,true)
      const notpickmsg="Not pick";
    try {
  const response = joinLmoviepickgett(LeagueId,userid,notpickmsg);
  } 
  catch (error) {
    return;
  }  
      
}

}       
            }); 


const transformvariable=JSON.stringify(indexs);
let transformx = Number(transformvariable)


var transformxx=`translateX(${"-"+ transformx * 120  + "px"})`;

 
const widthdraftorder=signalrData?.players?.length * 120 + "px";

const preSlide = () => {
    if(callCount < indexs){ 
    setmovex((prev) => prev + 120)
    setCallCount(callCount+1);
    setNextcount((nextcount) => nextcount - 1);
  }
 if(indexs==undefined){
  if(nextcount>0){   
    setmovex((prev) => prev + 120)
  setCallCount(callCount+1);
  setNextcount((nextcount) => nextcount - 1);
  
  }
 }
}

const nextSlide=()=>{
if(callCount!=0){
   if(callCount>nextcount){
    setNextcount((nextcount) => nextcount + 1);
    setCallCount(callCount-1);
   setmovex((prev) => prev - 120)
  }
 }
if(indexs==undefined){
if(nextcount<signalrData?.players?.length-1){
  setNextcount((nextcount) => nextcount + 1);
  setCallCount(callCount-1);
  setmovex((prev) => prev - 120) 
  
}}
}

const openWatchlist=()=>{
  watchlFun(userid,LeagueId)
  setShowwatch(true)
  }
     
   
const allPlayers = signalrData?.orderedPlayers?.map((item, index) => item);
const loginUser = allPlayers?.find((item) => item.id == userid);
const updatedOrderedPlayers = [loginUser];

for (let i = 0; i < allPlayers?.length; i++) {
     const currentPlayer = allPlayers[i];
  if (currentPlayer.id !== loginUser?.id) {
    updatedOrderedPlayers.push(currentPlayer);
  }
}

   return (
    <div>
      <Navbarr/>
      <div className="after-nav-dsubheader containers con_div">
        <Subheader />
        <ToastContainer />
        <CategoryModal  indexs={indexs} setIndex={setIndex} showModalcategory={showModalcategory} setShowModalcategory={setShowModalcategory} categoryDataMovieCountClick={categoryDataMovieCountClick}fetchdata={fetchdata}setFetchData={setFetchData}setHiddenButtons={setHiddenButtons}  value={value} setValue={setValue} draftordertime={draftordertime} setDraftordertime={setDraftordertime}/>
        <div className="detailp-league-card">
          <div className="card d-Cardf">
            <div className="row g-0 main-row ">
              <div className="col-md col-sm imag-cardclass">
                <img src={imgpath+leaguelived?.image }   onError={(e) => {
    e.target.onerror = null;
    e.target.src = firstitem }} alt="img" className="detleague-card-img" />
              </div>
              <div className="col-md col-sm d_l_c_content">
                <div className="three_Item">
                  <div className="league_time">
                    <p className="card-title">
                      {leaguelived?.leagueName}
                      </p>
                    <div className="custom-detail">
                      <p className="card-title">
                      
                        {templatename}
                        </p>
                 <p className="card-title"> No of Players: {noofjoinplayers ||leaguelived?.count}/{leaguelived?.entries}</p> 
                    </div>
                  </div>
                  <div className="mt-20">
                    <a
                      href="##"
                      className="btn btn-primary detail-PP-btn "
                    >
                       {leaguelived?.visibility}
                    </a> </div>
                   <div className="leaguedatetime2">
                  <div className="dleague-card-tdate">
                  <span>Created:</span>
                    <div className="view_live_time" >
                    
                      <YourIcon />
                      <span className="data"> 
                      { moment(new Date(leaguelived?.createdDate + 'Z')).format("dddd, MMMM D, YYYY")}
                       </span>
                    </div>
                    <div className="view_live_time">
                      <YourIcon1 />
                      <span className="time font_time">
                      { moment(new Date(leaguelived?.createdDate + 'Z')).format("h:mm a")}
                         </span>
                    </div>
                  </div>
                  <div className="dleague-card-tdate">
                  <span>League End:</span>
                    <div className="view_live_time" >
                      <YourIcon />
                      <span className="data"> 
                      { moment(new Date(leaguelived?.endDateTime + 'Z')).format("dddd, MMMM D, YYYY")}
                       </span>
                    </div>
                    <div className="view_live_time">
                      <YourIcon1 />
                      <span className="time font_time">
                      { moment(new Date(leaguelived?.endDateTime + 'Z')).format("h:mm a")}
                         </span>
                    </div>

                  </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="dleague-card-hr" />
            <nav className="dleague-card-nav">
              <p className="cdetail-text-content">
              {leaguelived?.description}
              </p>
            </nav>
          </div>
        </div>
     
        <Googleadshorizontal props="pt-2 pb-2 text-center"/>
        <div className="dpage-input-f">
          <div className="col">
        <div className="card position_card">
          <nav className="navbar  nav-main">
           <div className="draft-order-with-time">
           <div className="draft_heading "> Draft Order</div>
            <div  iv className="custom-hell">  
      
                           {indexs!=undefined?
                           <div className="date-1 custom-c-space">
                          <h6>   {draftordertime}</h6>
                          <h5 className="days ">Min  {"   "} Sec</h5>                                               
                        </div>:
                       
                        <div className="date-1 custom-c-space">
                          <h6>00:00</h6>
                          <h5 className="days ">Min  {"   "} Sec</h5>                                                
                          </div>                          
                          }                          
                    </div>
           </div>
                    {indexs==undefined?<div className="ordercompleted">
                      Draft has concluded 
                    </div>:""}
              </nav>
              <div className="draft-wrapper">
                <button   disabled={ indexs==undefined && nextcount===0} onClick={()=>preSlide()} className="nextprebtn">
                <i className="fa-solid fa-angle-left"></i>
                </button>
              <div className="draft-main">
              <div className="Draft_orderitem" style={{width: widthdraftorder,
              left: indexs==undefined ? indexselse+movex : indexs+movex,
              transform:indexs==undefined?`translateX(${0 * 120  + "px"})`:transformxx
}} >                    
              {signalrData?.players?.map((data, index) => (              
              <div key={index} className={`ggg ${(index==indexs?"draft_order_active":"draft_order_deactive")}  ${(indexnotpick.includes(index) ? "draft_order_notpick" : "")}`}>
              <div>
             <img
              src={imgpath+data?.image} onError={(e) => {
                e.target.onerror = null;
                e.target.src = profileimg1 }}
              className="profileiconn"
              href="##"
              alt="proimg"/>
              </div>
              <div className="name_profile">
                {data?.name?.charAt(0).toUpperCase()+ data?.name?.slice(1)}
                
                </div>
             </div>
           ))}  
              </div>
              </div>
                <button disabled={indexs==undefined && nextcount === signalrData?.players?.length} onClick={()=>nextSlide()} className="nextprebtn">
                <i className="fa-solid fa-angle-right"></i>
                </button>
              </div>
            </div>
            </div>
        </div>
<div className="row After_draft_div">
  <div className="col-md-12 col-lg-4 col-xl-3 ">
        <div className="s_m_list">
          <nav className="navbar  nav-main">
               Selected Movies
              </nav>
              <div className="s_m_accodion">
                <Accordion className='main_accordion' defaultActiveKey={active}>
                
                  {updatedOrderedPlayers?.map((item,index)=>(
                  <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header className='item_accordion'>
               <div className="Position_seconddivvvvvv">
             <div>
             <img
              src={imgpath+item?.image}
              className="profileicon"
              href="##"
              alt="proimg" onError={(e) => {
                e.target.onerror = null;
                e.target.src = profileimg1 }}/>
             </div>
             <div className="profile_name">
              {item?.name?.charAt(0).toUpperCase()+ item?.name?.slice(1)}
              </div>
              </div>
              </Accordion.Header>
              <Accordion.Body className="check_body">
                {Categoryoptions?.map((data, _index) => {
    return [...new Set(categoryArray)]?.map((id, index) => {
      if(id == data?.id){
        var count = categoryArray.filter((e) => e == id)?.length;
        var ListItem = item?.movies?.filter((list) => list.category == id) || []
        return <div>
              <h6>{data?.category}</h6>
                {
                  ListItem?.length < count ? 
                  [...new Array(count)].map((list,i) => {
                    return <div className="d-flex first_Sec_Sec" >
                    <p>{i+1}</p>
                      <p >{ListItem[i]?.name || "Empty"}</p>
                  
                   </div>
                  }) : ListItem.map((list,i) => {
                    return <div className="d-flex first_Sec_Sec" >
                    <p>{i+1}</p>
                      <p>{ListItem[i]?.name || "Empty"}</p>
                  
                  </div>
                  })
                }
            </div>}
    })
})}                 <div className="d-flex total_div">
                   <div>
              Total Movies
             </div>
            <div>{item?.movies?.length}</div>
              </div> 
        </Accordion.Body>
        </Accordion.Item>))}
    </Accordion>
    </div>
            </div>         
            </div>
            <div className="col-md-12 col-lg-8 col-xl-6">
        <div className=" position_card">
          <nav className="navbar  nav-main">
                <div>Movies list</div>
                
              </nav>
              <div className="Position_seconddivvvv searchcategorydraft">
                <div className="btnaoraa">
                    <button  className={`btn btn-primary adbtn  ${showwatch ? '' : 'active'}` } onClick={()=>setShowwatch(false)}>
                      All Movies
                    </button>
                    <button className={`btn btn-primary aanibtn ${showwatch ? 'active' : ''}` } onClick={()=>openWatchlist()}>
                     Watchlist
                    </button>
                  </div>
                  <div className="second_position">
                  <div className="searchcatfil"> 
                    
                    <select
                  className="form-select draft-input-groupp"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  value={selectedCategory}
                >
                  <option selected value="All">Select Category</option>
                    {Categoryoptions?.sort((a, b) => a?.category.localeCompare(b?.category))?.filter((item) => !['2065', '2066', '2094', '2095']?.includes(item?.id.toString()))?.map((matchingData, item) => (
                  <option key={item} value={matchingData?.id}>
                 {matchingData?.category}
                </option>
                  ))}
                  
                </select>
                
                </div>
                <div className="search_div">
                  <input className="form-control mr-sm-2 joinl_search" type="search" placeholder="Enter Movie" aria-label="Search" onChange={inputHandler}/></div>
                  </div>
                 
                  </div>
                  <div className="Position_seconddivvvvv">
                    <div className="joinleaguemovies">
                    <table className="w-100 joinleaguetable">
                      <thead>
                        <tr>
                          <th colSpan={2}>Movies</th>
    
                          <th style={{whiteSpace:"nowrap" }}>Category</th>
                          <th style={{whiteSpace:"nowrap"}}>Release Date</th>
                        </tr>
                      </thead>
                      {showwatch?null:<tbody>

                        {filteredData?.length > 0 ? (
                      filteredData?.map((data,index) => (  
                        
                  <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                             
  {data?.poster?.includes("https://m.media-amazon.com/images") ? 
    <img src={data?.poster} alt="img"  className="profile_icon draft_movie_img" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} /> : 
    <img src={movieposterpath + data?.poster} alt="img"  className="profile_icon draft_movie_img" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} />
  }

                       <p className="mb-0">{data?.name.length > 25 ? data?.name.substring(0, 25) + '...' : data?.name} </p>
                          </div>
                        </td>
                        <td>
                          <div className="joinactionbtns">
                       <button className="btn btn-primary aadbtn" onClick={()=>open(data?.name, data?.id,data?.category)} >
                              Pick
                            </button>
                            <button className="btn btn-primary aanibtn" onClick={()=>createPost(data?.id)}>
                            Add to Watchlist
                          </button>
                        </div>
                        </td>
                        <td>
                          <div className="listcategory">
                        {data.category && data.category.split(',').map((categoryId, index) => (
                          <span key={index} className="listcategory">
                            {Categoryoptions?.find(cat => cat?.id == categoryId)?.category}
                            </span>
                          ))}
                          </div>
                        </td>
                        <td>
                        { (moment(new Date(data?.releaseDate + 'Z')).format("MMMM D, YYYY")!=
"Invalid date"? moment(new Date(data?.releaseDate + 'Z')).format("MMMM D, YYYY"):"N/A")}
                          
                          </td>
                      </tr> 
                      
                      ))) : (
                        <div className="no_Movies_found">
                          <p>No Movies found</p>
                        </div>
                      )}
                      </tbody>}
                      {showwatch?<tbody>

                        {filteredWatchlist?.length > 0 ? (
                      filteredWatchlist?.map((data,index) => (
                        < >
                      {!hiddenButtons[data?.id] &&<tr>
                        <td>
                          <div className="d-flex align-items-center">
                           
                         
                             
  {data?.poster.includes("https://m.media-amazon.com/images") ? 
    <img src={data?.poster} alt="img"  className="profile_icon" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} /> : 
    <img src={movieposterpath + data?.poster} alt="img"  className="profile_icon" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} />
  }

                              <p className="mb-0">{data?.name.length > 25 ? data?.name.substring(0, 25) + '...' : data?.name}</p>
                          </div>
                        </td>
                      
                        <td>
                            <div className="joinactionbtnss">
                          <button className="btn btn-primary aadbtn" onClick={()=>open(data?.name, data?.id,data?.category)} >
                              Pick
                            </button>
                        </div>
                        </td>
                        <td>
                          <div className="listcategory">
                          {data?.category && data?.category.split(',').map((categoryId, index) => (

                          <span key={index} className="listcategory ">
                            {Categoryoptions?.find(cat => cat?.id == categoryId)?.category}
                            </span>
                          ))}
                          </div>
                        </td>
                        <td> 
                        { (moment(new Date(data?.releaseDate + 'Z')).format("MMMM D, YYYY")!=
"Invalid date"? moment(new Date(data?.releaseDate + 'Z')).format("MMMM D, YYYY"):"N/A")}
                
                          </td>
                      </tr> }
                      </>
                      ))) : (
                        <div className="no_Movies_found">
                          <p>No Movies found</p>
                        </div>
                      )}
                      </tbody>:null}
                    </table>
                    </div>
                  </div>
            </div>
         
            </div>
            <div className="col-md-12 col-lg-12 col-xl-3">
        <div className="card position_card player_info">
          <nav className="navbar  nav-main">
                Player Info
              </nav>
              <div className="Position_seconddivvv_info  ">
              <div className="btnaoraa">
                    <button className={`btn btn-primary aaanibtn ${showallplayer ? '' : 'active'}` } onClick={()=>setShowallplayer(false)}>
                      Pick List
                    </button>
                    <button className={`btn btn-primary aanibtn ${showallplayer ? 'active' : ''}` } onClick={()=>setShowallplayer(true)}>
                     All Players
                    </button>
                  </div>
              </div>
              {showallplayer?null:<div className="picklist_items">
              {message?.map((data,index)=>(
(data?.message=="Not pick"?"":
<div className="Position_Picklist_items" key={index}> 
<div >
<img
src={imgpath+data?.playerImage}
className="profileiconn"
href="##"
alt="proimg" onError={(e) => {
  e.target.onerror = null;
  e.target.src = profileimg1 }}/>
    </div>
    <div className="text_context">
    {data?.playerName?.charAt(0).toUpperCase()+ data?.playerName?.slice(1)}
    <p>{data?.message}
    </p>
     </div>
</div>
)
      )).reverse()}            
              </div>}
          {showallplayer?
          <div  className="allplayers_list"> 
            <table className="w-100 players_list">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Profile</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
          {uniquePlayers.map((player, index) => (
          <tr>
                <td>
                {index + 1}
                </td>
                <td>
         <div className="playerinfo_img">
         <img
        src={imgpath+player?.image}
        className="profileiconn playerimg"
        href="##"
        alt="proimg"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = profileimg1 }}
      />
         </div>
                </td>
                    <td>
                   <span className="user_name">
                    {player?.name?.charAt(0).toUpperCase()+ player?.name?.slice(1)}
                    </span> 
                </td>
               </tr>))} 

          </tbody>
          </table>
          </div>
          :null}
            </div>
            </div>
            </div>
      </div>
      <Footer />
      <ScrollButton />
    </div>
  );
}

export default Joinleague;
