import React, { useState, useEffect } from "react";
import "./Invites.css";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box } from "@mui/material";
import {invitesGet,invitesendPost} from "../../../api/handleapi"
import Submitbtn from "../../../components/submit/Submitbtn";
import { invitessentMsg, inviteUsersMsg, leagueinvitationEmailsubject, successfullyTitle, WrongTitle } from "../../../components/language/Language";
import { leagueinviteURl } from "../../../Config/config";
import Cookies from 'js-cookie';
const Invites = ({setShowModalInvites,c_league}) => {

const navigate = useNavigate();
const leagueid=Cookies.get("cleagueid");
const [searchVal, setSearchVal] = useState('');
  const [list, setList] = useState([]);
  const [recipientNames, setRecipientNames] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendinvite, setSendinvite] = useState({
    ToEmail: [],
    Subject: leagueinvitationEmailsubject,
    Body:`` ,
    LeagueId:leagueid,
  });


  const invitesFun = async () => {
    try {
      const response = await invitesGet(); // You might want to pass some parameters to Dashboardget
      setList(response?.data?.data);
    } catch (error) {
      return;
    }
  };
   


  const handleInvites = async () => {
    setLoading(true);
     recipientNames.map(async (value,index)=>{ 
    var bodyForm = new FormData();
    bodyForm.append("ToEmail", sendinvite?.ToEmail[index]);
    bodyForm.append("subject", leagueinvitationEmailsubject);
    bodyForm.append("Body", `Hi ${value},
    You've been invited to a league! Your league name is ${c_league?.LeagueName}.
    Please click the link to accept the invitation and join the league  ${leagueinviteURl}`);
    bodyForm.append("LeagueId",leagueid);
    try {
      const response =  await invitesendPost(bodyForm);
          if (response?.status === 200) {
        handleCloseModal();
        Cookies.remove("cleagueid")

      } 
    } catch (error) {
      setLoading(false);
          return;
    }
    })
  };

  const onSubmitForm = (e) => {
    if (sendinvite?.ToEmail?.length ===0) {
      swal({
        title: WrongTitle,
        text: inviteUsersMsg,
        icon: "error",
        buttons:"Ok",
        closeOnClickOutside:false,
        dangerMode: true,
      })
     
    }
    else if(sendinvite?.ToEmail?.length > c_league?.Entries)
    {
      swal({
        title: WrongTitle,
        text: `Select only ${c_league?.Entries} Emails! Based on league Entries`,
        icon: "error",
        buttons:"Ok",
        closeOnClickOutside:false,
        dangerMode: true,
      })
      const truncatedEmails = sendinvite?.ToEmail?.slice(0, c_league?.Entries);
      setSendinvite({ ToEmail: truncatedEmails });
      setError(false); 
      // Clear the error message
      // Disable the input field
    }
    else{
      handleInvites();
    }
  };

  const handleCloseModal = () => {
    setShowModalInvites(false);
    setLoading(false);
    swal({
      title: successfullyTitle,
      text: invitessentMsg,
      icon: "success",
      buttons: "My Upcoming leagues"  ,
      closeOnClickOutside:false,
      dangerMode: false,
    }).then((value) => {
      if(value){
        navigate("/Myleagueupcoming");

  }});
}
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  
  const handleInputChange = (e, newValue) => {
    const filteredEmails = newValue?.filter((email) => email === '' || isValidEmail(email, newValue));

    const selectedRecipients = filteredEmails?.map((email) => {
      const user = list?.find((user) => user.email === email);
      return user ? user.firstName : '';
    });
    if (sendinvite.ToEmail.length <= c_league.Entries) {
      setSendinvite({ ToEmail: filteredEmails });
      setRecipientNames(selectedRecipients);
      setError(filteredEmails.length !== newValue.length);
     
    }
    else if (filteredEmails.length > c_league.Entries) {
      const truncatedEmails = filteredEmails.slice(0, c_league.Entries);
      const truncatedRecipients = selectedRecipients.slice(0, c_league.Entries);
      setSendinvite({ ToEmail: truncatedEmails });
      setRecipientNames(truncatedRecipients);
      setError(true); // Clear the error message
   
    }
  };

  const filteredList = list?.filter((item) => {
    return item.email.toLowerCase().includes(searchVal.toLowerCase());
  });


const inputHandlerCheck = (e)=>{
if(e.target.value.length >= 3){
  invitesFun();
}
else{
  setList([])
  return false
}
}


  return (
     <div className="invites_main">
<div className="Checkbox_div ">
  <Box
  sx={{
    ".MuiFilledInput-underline":{
      paddingBottom:"7px",
      paddingTop:"23px"
    },
    ".MuiChip-outlined":{
      borderColor:"#286ac0",
      backgroundColor:"#e7f1ff"
    },
    ".MuiChip-deleteIcon":{
      color:"#286ac0"
    },
    ".MuiChip-deleteIcon:hover":{
      color:"#286ac0"
    }
    }}>
<Autocomplete
        multiple
        id="tags-filled"
        options={list?.map((item) => item?.email)}
        freeSolo
        value={sendinvite?.ToEmail}
        onChange={handleInputChange}
        renderTags={(value, getTagProps) =>
          value?.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="Search"
            onInput={(e)=>inputHandlerCheck(e)}
            placeholder="Emails"
            error={error}
          helperText={error ? 'Enter valid email address! Based on Entries' : ''}
          />
        )}
      />
      </Box>
      </div>
      <div className="invites_btn">
      {loading ? (
            <div className="spinner-border text-primary " role="status">
              <span className="visually-hidden">Loading...</span>
          </div>
          ):
        <Submitbtn onSubmitForm={onSubmitForm} props={"SUBMIT"}/>}
      </div>
    </div>
   
  );
};

export default Invites;