import { commentpostvalidationMsg, confirmpasswordvalidationMsg, confirmpconditionvalidationMsg, confirmpformatvalidationMsg, dobformatvalidationMsg, dobvalidationMsg, emailformatvalidationMsg, emailvalidationMsg, fNameIncorrectvalidationMsg, fNamevalidationMsg, gendervalidationMsg, imageuploadformatvalidationMsg, lnameincorrectvalidationMsg, lNamevalidationMsg, messageValition, namevalidationMsg, newpasswrodvalidationMsg, oldpasswordvalidationMsg, PasswordfomatvalidationMsg, PasswordvalidationMsg, userimagevalidationMsg } from "../language/Language";

export const fnameValidation = (firstName) => {
  if (!firstName) {
    return fNamevalidationMsg;
  } 

  else if (!new RegExp(/^^[a-zA-Z]+['\s]?[a-zA-Z ]{0,39}$/).test(firstName)) {
    return fNameIncorrectvalidationMsg;
  }
  return "";
};
export const lnameValidation = (lastName) => {
  if (!lastName) {
    return lNamevalidationMsg;
  } 
    else if (!new RegExp(/^[a-zA-Z]+['\s]?[a-zA-Z ]{0,39}$/).test(lastName)) {
    return lnameincorrectvalidationMsg;
  }
  return "";
};
export const genderValidation = (gender) => {
  if (!gender) {
    return gendervalidationMsg;
  }
  return "";
};
export const dobValidation = (dob) => {
  if (!dob) {
    return dobvalidationMsg;
  } else if (dob.length < 8) {
    return dobformatvalidationMsg;
  }
  return "";
};

export const emailValidation = (email) => {
  if (!email) {
    return emailvalidationMsg;
  } else if (
    !new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(email)
  ) {
    return emailformatvalidationMsg;
  }
  return "";
};

export const passwordValidation = (password) => {
  if (!password) {
    return PasswordvalidationMsg;
  } else if (
    !new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).{8,}$/).test(
      password
    )
  ) {
    return PasswordfomatvalidationMsg;
  }
  return "";
};
export const confirmPasswordValidator = (confirmPassword, form) => {
  if (!confirmPassword) {
    return confirmpasswordvalidationMsg;
  } else if (confirmPassword.length < 8) {
    return confirmpformatvalidationMsg;
  } else if (confirmPassword !== form.password) {
    return confirmpconditionvalidationMsg;
  }
  return "";
};

export const oldpasswordValidation = (oldPassword) => {
  if (!oldPassword) {
    return oldpasswordvalidationMsg;
  } else if (
    !new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).{8,}$/).test(
      oldPassword
    )
  ) {
    return PasswordfomatvalidationMsg;
  }
  return "";
};

export const newpasswordValidation = (newPassword) => {
  if (!newPassword) {
    return newpasswrodvalidationMsg;
  } else if (
    !new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).{8,}$/).test(
      newPassword
    )
  ) {
    return PasswordfomatvalidationMsg;
  }
  return "";
};
export const confirmPasswordValidatorr = (confirmPassword, form) => {
  if (!confirmPassword) {
    return confirmpasswordvalidationMsg;
  } else if (confirmPassword.length < 8) {
    return PasswordfomatvalidationMsg;
  } else if (confirmPassword !== form.newPassword) {
    return confirmpconditionvalidationMsg;
  }
  return "";
};


export const imagesfileValidation = (Imagesfile) => {
  if (!Imagesfile) {
    return userimagevalidationMsg; 
  } else if (!/\.(jpg|png)$/i.test(Imagesfile.name )) {
    return imageuploadformatvalidationMsg;
  }
  return "";
};

export const commentValidation = (comment) => {
  if (!comment) {
    return commentpostvalidationMsg;
  }
 
  return "";
};
export const messageValidation = (message) => {
  if (!message) {
    return messageValition;

  }  
  return "";
};
export const senderNameValidation = (senderName) => {
  if (!senderName) {
    return namevalidationMsg;
  } 
  else if (!new RegExp(/^^[a-zA-Z]+['\s]?[a-zA-Z ]{1,40}$/).test(senderName)) {
    return fNameIncorrectvalidationMsg;
  }
  return "";
};

export const senderEmailValidation = (senderEmail) => {
  if (!senderEmail) {
    return emailvalidationMsg;
  } else if (
    !new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(senderEmail)
  ) {
    return emailformatvalidationMsg;
  }
  return "";
};



