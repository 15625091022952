import { createSlice } from '@reduxjs/toolkit';

export const leaguelivedSlice = createSlice({
  name: 'leaguelived', // Slice name
  initialState: {
  }, // Initial state (empty array in this case)
  reducers: {
    setLeaguelived: (state, action) => {
      // Update the state with the received data
      return action.payload;
    },
  },
});

// Export the action creator
export const { setLeaguelived } = leaguelivedSlice.actions;

// Export the reducer
export default leaguelivedSlice.reducer;