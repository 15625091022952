import { createSlice } from '@reduxjs/toolkit';

export const showModalSlice = createSlice({
  name: 'showModal',
  initialState: false,
  reducers: {
    openModal: (state) => true,
    closeModal: (state) => false,
  },
});

export const { openModal, closeModal } = showModalSlice.actions;

export default showModalSlice.reducer;