import React,{useState} from 'react'
import Forgotpassword from '../../pages/forgotpassword/Forgotpassword'
import LoginForm from '../../pages/login/LoginForm'
import RegisterForm from '../../pages/register/RegisterForm'
import { Modalc } from './Modalc';
import { useSelector } from 'react-redux';
import Subscription from '../../pages/subscriptionpayment/Subscription';



function Reuseformodal({openforgot,setOpenforgot,toggleform,setToggleform, showotp,setShowotp,closeModal} ) {
    const showModal = useSelector((state) => state.showModal);
    const [showModalTwo, setShowModalTwo] = useState(false);
    const [showsubscriptionpayment,setShowsubscriptionpayment]=useState(false);
  return (
    <div>
   <Modalc  openforgot={openforgot} setOpenforgot={setOpenforgot} toggleform={toggleform}setToggleform={setToggleform} showotp={showotp}setShowotp={setShowotp} showModalTwo={showModalTwo} setShowModalTwo={setShowModalTwo} closeModal={closeModal}  showsubscriptionpayment={showsubscriptionpayment}setShowsubscriptionpayment={setShowsubscriptionpayment} 
   content={<> { showModal && openforgot===false ?<LoginForm  openforgot={openforgot} setOpenforgot={setOpenforgot}  setShowModalTwo={setShowModalTwo} closeModal={closeModal}  setShowsubscriptionpayment={setShowsubscriptionpayment}/> :<Forgotpassword openforgot={openforgot} setOpenforgot={setOpenforgot} toggleform={toggleform}setToggleform={setToggleform} showotp={showotp}setShowotp={setShowotp}/>}   </>} 
    contentr={<> {  showModalTwo && showsubscriptionpayment==false ?<RegisterForm    setShowModalTwo={setShowModalTwo} closeModal={closeModal} /> :<Subscription setShowModalTwo={setShowModalTwo} setShowsubscriptionpayment={setShowsubscriptionpayment} />}     </>}
 
      />
    </div>
  )
}

export default Reuseformodal;