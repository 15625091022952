import React, { useState } from "react";
import Navbarr from "../../components/header/Navbarr";
import { BannerImage } from "./BannerImage";
import Trandingmovie from "./Trandingmovie";
import Newrelease from "./Newrelease";
import { BannerImage2 } from "./BannerImage2";
import Topnews from "./Topnews";
import { Footer } from "../../components/footer/footer";
import ScrollButton from "../../components/scrollbutton/ScrollButton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Succesmsg } from "../../components/tostifymsg/Succesmsg";
import { Errormsg } from "../../components/tostifymsg/Errrormsg";

function Homeindex() {

  return (
    <div>
      <Navbarr />
      <BannerImage />
      <Trandingmovie   Succesmsg={Succesmsg} Errormsg={Errormsg} />
      <Newrelease Succesmsg={Succesmsg} Errormsg={Errormsg} />
      <BannerImage2 />
      <Topnews  />
      <Footer />
      <ScrollButton />
      <ToastContainer/>
    </div>
  );
}

export default Homeindex;
