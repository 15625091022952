import moment from 'moment';
import React, { useEffect, useState } from 'react';

function CountDownTimer({ createdatetime, startdatetime,setBtntoggledraft,LeagueId}) {
  const [time, setTime] = useState("");

useEffect(() => {
  const mstart = moment.utc(createdatetime);
  const mend = moment.utc(startdatetime);
  const dur = moment.duration(mend.diff(mstart));

  const updateRemainingTime = () => {
    
   
    const now = new Date().toLocaleString(undefined, { timeZone: 'utc' });  // this now veriable used for UAT environment
    const  remaining  = moment.duration(mend.diff(now));
     if(remaining.days()>0 || remaining.hours()>0 || remaining.minutes()>0|| remaining.seconds()>0){
      setTime(
        `(${remaining.days()} day : ${remaining.hours()} Hrs : ${remaining.minutes()} Mins : ${remaining.seconds()} Sec)`
      );

    }
    else{
      setTime(
        `(${0} day : ${0} Hrs : ${0} Mins : ${0} Sec)`
      );
    }
  };
 
  updateRemainingTime(); // Update the remaining time immediately

  const timer = setInterval(updateRemainingTime, 1000); // Update the remaining time every second

  return () => clearInterval(timer);
 // Clear the interval when the component unmounts
}, [createdatetime, startdatetime]);

  return (
    <div id="timer">
     <span>{time}</span>
      </div>
         );
}

export default CountDownTimer;