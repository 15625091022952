import { createSlice } from '@reduxjs/toolkit';

export const draftorderrSlice = createSlice({
  name: 'draftorderr', // Slice name
  initialState: {

    
  }
  , // Initial state (empty array in this case)
  reducers: {
    setDraftorderr: (state, action) => {
      // Update the state with the received data
      return action.payload;
    },
  },
});

// Export the action creator
export const { setDraftorderr } = draftorderrSlice.actions;

// Export the reducer
export default draftorderrSlice.reducer;