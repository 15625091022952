import React from 'react'
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { ReactComponent as YourIcons } from "../../assets/iconoir_search.svg";
function Searchmovies({inputHandler}) {
  return (
   
    <div className="col">
      <Col xs="auto">
     <div className="searchi-filed">
     <Form.Control
       type="text"
       placeholder="Find movies..."
       className="searchbar"
       onChange={inputHandler}
     />
     <div className="search-icon">
     <YourIcons className="your-icons" />
     </div>
     </div>
   </Col>
   </div>
  
  )
}

export default Searchmovies