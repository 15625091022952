import { useEffect,useState } from "react";
import { allcategoryDetailsget } from "../api/handleapi";
export function useCategory(){
    const [categorieslist,setCategorieslist]=useState([]);
    const categoryFun = async () => {
        try {
          const response = await allcategoryDetailsget(); 
          setCategorieslist(response?.data?.data);
        
        } catch (error) {
          return;
        }
      };

    useEffect(() => {
        categoryFun();
    }, [])
    return categorieslist;
}

