import React,{useEffect, useState} from 'react'
import  "./Trading.css";
import Tradebtn from "./Tradebtn";
import { ReactComponent as YourIcon3 } from "../../../../../assets/Vector.svg";
import swal from 'sweetalert';
import { useSelector } from "react-redux";
// import { Categoryoptions } from '../../../../../Config/config';
import {trademoviePost,counteroffertradeRequest} from "../../../../../api/handleapi";
import {  useDispatch } from 'react-redux';
import {  closeModal } from '../../../../../feature/showSlice/showmodaltradeSlice';
import Cookies from 'js-cookie';
import { openModalmdetails } from '../../../../../feature/showSlice/showmodaltrademoviedetailsSlice';
import {setTrademoviedetailsforid} from '../../../../../feature/showSlice/trademoviedetailsforidSlice';
import { useCategory } from '../../../../../components/useCategory';
import { apiErrorMsg, APIerrorTitle, counterofferMsg, CounteroffervalidationMsg, successfullyTitle, traderequestsent, tradevalidationMsg } from '../../../../../components/language/Language';



function Trading({League_id,trademovies}) {
  const [checkedId,setcheckedId]=useState();
  const dispatch = useDispatch();
  const firstpid=Cookies.get("id");
  const secondpid=Cookies.get("secondpid");
  const League_idforcounter=Cookies.get("League_idforcounter");
  const secondusername=Cookies.get("secondpusername");
  const [movieslist,setMoviesList] = useState([]);
  const [movieslistt,setMoviesListt] = useState([])
  const [ftrade,setFtrade]=useState({
    FirstUserId :"",
    FMovieId :"",
    FCategoryId :"",
    FMovieName :"",
  })

  const openModalMdetails = (movieId) => {
      dispatch(openModalmdetails(true)); 
      dispatch(setTrademoviedetailsforid(movieId));
   
};
const Categoryoptions=useCategory();
  const [strade,setStrade]=useState({
    SecondUserId  :"",
    SMovieId  :"",
    SCategoryId  :"",
    SMovieName  :"",
  })

  const secondpmovie=useSelector((state) => state.secondpmovie);
  const loginpmovie=useSelector((state) => state.loginpmovie);

  useEffect(() => {
  const updatedMoviesArray = loginpmovie?.map((movie) => ({
    ...movie,
    checked: false,
}));
  const updatedMoviesforfinals = updatedMoviesArray?.filter(movie => movie.finalScore === 0 );
  setMoviesList(updatedMoviesforfinals);
  const updatedMoviesArrayy = secondpmovie?.map((movie) => ({
    ...movie,
    checked: false,
}));
  const secondpupdatedMoviesforfinals = updatedMoviesArrayy?.filter(movie => movie.finalScore === 0);
  setMoviesListt(secondpupdatedMoviesforfinals);
  },[]);

  const handleIconClick = (movieid) => {
    let temp_movielist = [...movieslist]
    let updatedData = temp_movielist?.map((each)=>{
      if(each.movieId==movieid){
        setFtrade({ FirstUserId :firstpid,FMovieId:each?.movieId,FCategoryId:each?.categoryId,FMovieName:each?.movieName})        
        return {...each,checked:!each.checked}
        
      }
      return {...each,checked:false}
  })
   
    setMoviesList(updatedData);

  };

  const handleIconClickk = (movieId,catid,mname) => {
    let temp_movielist = [...movieslistt]
    let updatedData = temp_movielist?.map((each)=>{
      if(each?.movieId==movieId){
        setStrade ({
                  SecondUserId:secondpid,
                  SMovieId  :each?.movieId,
                  SCategoryId  :each?.categoryId,
                  SMovieName  :each?.movieName,
                });
        return {...each,checked:!each.checked}
      }
      return {...each,checked:false}
  })
    setMoviesListt(updatedData);
  };
  const tradeMovie = async() => {
    if(ftrade?.FMovieName==="" && strade?.SMovieName==="" )
    {
      swal({
        title: "",
        text: tradevalidationMsg,
        icon: "error",
        buttons: "ok",
        closeOnClickOutside:false,
        dangerMode: false
      })
    }
    else{
      var bodyFormData = new FormData();
      bodyFormData.append("LeagueId",League_id );
      bodyFormData.append("FirstUserId", ftrade?.FirstUserId);
      bodyFormData.append("FMovieId", ftrade?.FMovieId);
      bodyFormData.append("FCategoryId", ftrade?.FCategoryId);
      bodyFormData.append("FMovieName", ftrade?.FMovieName);
      bodyFormData.append("SecondUserId", strade?.SecondUserId);
      bodyFormData.append("SMovieId", strade?.SMovieId);
      bodyFormData.append("SCategoryId", strade?.SCategoryId);
      bodyFormData.append("SMovieName", strade?.SMovieName);
       try {
        const response =  await trademoviePost(bodyFormData);
        if (response?.status === 200) {
          swal({
            title: successfullyTitle,
            text: traderequestsent,
            icon: "success",
            closeOnClickOutside:false,
            buttons: "ok",
            dangerMode: false
          }).then((value) => {
            if(value){
              dispatch(closeModal(false));
            }})
        } 
      } 
      catch (error) {
        if (error.response.data?.message!=undefined) {
                  swal({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  })
                }         
                else if (error.response.data?.message===undefined) {
                  swal({
                      title: APIerrorTitle,
                      text: apiErrorMsg,
                      icon: "error",
                      buttons: "ok",
                      closeOnClickOutside:false,
                      dangerMode: false
                    })
                }else {
                  return;
                }
      }
    }
 
  }
  const counterTrade = async() => {
    var bodyFormData = new FormData();
    bodyFormData.append("LeagueId", League_idforcounter);
    bodyFormData.append("FirstUserId", ftrade?.FirstUserId);
    bodyFormData.append("FMovieId", ftrade?.FMovieId);
    bodyFormData.append("FCategoryId", ftrade?.FCategoryId);
    bodyFormData.append("FMovieName", ftrade?.FMovieName);
    bodyFormData.append("SecondUserId", strade?.SecondUserId);
    bodyFormData.append("SMovieId", strade?.SMovieId);
    bodyFormData.append("SCategoryId", strade?.SCategoryId);
    bodyFormData.append("SMovieName", strade?.SMovieName);
     try {
      const response =  await counteroffertradeRequest(bodyFormData);
      if (response?.status === 200) {
        swal({
          title: "",
          text: counterofferMsg,
          icon: "success",
          buttons: "Ok",
          closeOnClickOutside:false,
          dangerMode: false,
        }).then((value) => {
          if(value){
            dispatch(closeModal(false));
            Cookies.remove("League_idforcounter");
          }})
      } 
    } 
    catch (error) {
      if (error.response.data?.message!=undefined) {
            
                swal({
                  title: "",
                  text: error.response.data.message,
                  icon: "error",
                  buttons: "ok",
                  closeOnClickOutside:false,
                  dangerMode: false
                })
              }         
              else if (error.response.data?.message===undefined) {
                swal({
                    title: "",
                    text: CounteroffervalidationMsg,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  })
              }else {
                return;
              }
    }
  }

  const tradeSubmit = () => {
   if(trademovies?.requestFrom?.fCategoryId!=null||undefined){
    counterTrade();
  }
    else{
      tradeMovie();
    }
  };
 
  return (
    <div>
  <div className="row">
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div className="heading_live_listt"><h6>My Movies List</h6></div>
            <div className="position_card">
  <div className="table_containerr_trade scrollbarcstm"> 
    <table className="playermovie_list">
  <thead  >
    <tr className="trade_movieslist_headings">
      <td >Movie</td>
      <td >Category</td>
      <td >Score</td>
      <td >Actions</td>
    </tr>
  </thead>
  <tbody >

  {
  movieslist?.length!=0?
  movieslist?.map((data,index)=>(
    <tr className="Player_title" key={index} >
          <td>
             <div className='d-flex gap-2'>
             <span className='moviesnamefordetails' onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 30 ? data?.movieName.substring(0, 30) + '...' : data?.movieName}</span>
             </div>
             </td>   
      <td>{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
      <td>{data?.finalScore}</td>
      <td className="campareicons">
      
              <input type="checkbox" checked={data?.checked} onClick={() => handleIconClick(data?.movieId)}/>
        </td>
    </tr>
    )):<div className='no_dropm_found'>No movies found</div>
    } 
  </tbody>
</table>
            </div>
          </div>
    </div>
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div className="heading_live_listt">
        <h6> 
          
            {Cookies.get("secondpusername")?.charAt(0).toUpperCase()+ Cookies.get("secondpusername")?.slice(1)}'s Movies List</h6>
      </div>
            <div className="position_card">
  <div className="table_containerr_trade scrollbarcstm"> 
    <table className="playermovie_list">
  <thead>
    <tr className="trade_movieslist_headings">
      <td>Movie</td>
      <td>Category</td>
      <td>Score</td>
      <td>Action</td>
    </tr>
  </thead>
  <tbody >
    {movieslistt?.length!=0?
  movieslistt?.map((data,index)=>(
    <tr className="Player_title" key={index} >
          <td >
          <div className='d-flex gap-2'>
             
            
             <span className='moviesnamefordetails'onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 30 ? data?.movieName.substring(0, 30) + '...' : data?.movieName}</span>
             </div>
          </td>
      <td >{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
      <td >{data?.finalScore}</td>
    <td className="campareicons">
       <input type="checkbox"  checked={data?.checked}   onChange={()=>handleIconClickk(data?.movieId,data?.categoryId,data?.movieName)} />
      
      </td> 

     
    </tr>
    )):<div className='no_dropm_found'>No movies found</div>
    } 
  </tbody>
</table>
            </div>
          </div>
    </div>
  </div>
  <div className='Trade_modal_btn' onClick={tradeSubmit}>
    <Tradebtn Trade={"Trade"}/>
  </div>
</div>

  )
}

export default Trading