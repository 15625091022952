import React from 'react'
import clsx from "clsx";
import { useState,useRef,useEffect } from "react";
import { useEmailFormValidator } from "./UseEmailFormValidation";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import logo from "../../assets/Gaffer Transparent Logo1.png";
import styles from "../login/LoginForm.module.css";
import {  useDispatch } from 'react-redux';
import {  closeModal } from '../../feature/showSlice/showModalSlice';
import { ReactComponent as YourIcon2 } from "../../assets/eye-solid.svg";
import { ReactComponent as YourIcon } from "../../assets/eye-slash-solid.svg";
import { UsePasswordformvalidation } from "./UsePasswordValidation";
import {forgotpasswordEmailpost,varifyotpPost,resetpasswordPost} from "../../api/handleapi"
import Loader from '../../components/loader/Loader';
import { apiErrorMsg, APIerrorTitle, changepasswordMsg, correctpasswordMsg, Enterotpmsg, forgotemailsuccessAuthcheck, forgotpassAuthcheck, forgotpasssucessAuthcheck, otpAuthcheck, otpcorrectionMsg, otpsenttoemailMsg, otpsuccessAuthcheck, otpverifyMsg, otpverifyTitle, successfullyTitle, tryagainMsg } from '../../components/language/Language';


function Forgotpassword({setOpenforgot,toggleform,setToggleform,showotp,setShowotp}) {
    const dispatch = useDispatch();
    const [form, setForm] = useState({ email: ""});
    const [formm, setFormm] = useState({ password: "",    confirmPassword: "", });
    const { errors, validateForm, onBlurField } = useEmailFormValidator(form);
    const { errorss, validateFormm, onBlurFieldd } = UsePasswordformvalidation(formm);
    const [showPassword, setShowPassword] = useState(false);
    const [showcPassword, setShowcPassword] = useState(false);
    const [disablebtn,setDisablebtn]=useState(false);
    const [loading,setLoading]=useState(false)
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };
      const togglecPasswordVisibility = () => {
        setShowcPassword(!showcPassword);
      };
   
    const onUpdateField = (e) => {
      const field = e.target.name;
      const nextFormState = { ...form, [field]: e.target.value };
      setForm(nextFormState);
      if (errors[field].dirty)
        validateForm({
          form: nextFormState,
          errors,
          field,
        });
    };
    
    const onSubmitForm = (e) => {
      e.preventDefault();
      const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
      if (!isValid) {
        return;
      }   
      forgotpassforemailsend();
    };


    const onUpdateFieldd = (e) => {
        const field = e.target.name;
        const nextFormState = { ...formm, [field]: e.target.value };
        setFormm(nextFormState);
        if (errorss[field].dirty)
          validateFormm({
            formm: nextFormState,
            errorss,
            field,
          });
      };

    const onSubmitFormm = (e) => {
        e.preventDefault();
        const { isValid } = validateFormm({ formm, errorss, forceTouchErrors: true });
        if (!isValid) {
          return;
        }   
       forgotpassfornewConfirmsend();
      };
      
      const length = 6;
      const onOtpSubmit = () => { }
      const [otp, setOtp] = useState(new Array(length).fill(""));
      const otp_string = otp.join("");
      const inputRefs = useRef([]);
    
      useEffect(() => {
        if (inputRefs.current[0]) {
          inputRefs.current[0].focus();
        }
      }, []);
    

     
      const handleChange = (index, e) => {
        const value = e.target.value;
        if (isNaN(value)) return;
    
        const newOtp = [...otp];
        newOtp[index] = value.substring(value.length - 1);
        setOtp(newOtp);
    
        if (newOtp.length === length) {
          onOtpSubmit(newOtp.join(""));
        }
    
        if (value && index < length - 1 && inputRefs.current[index + 1]) {
          inputRefs.current[index + 1].focus();
        }
      };
    
      const handleClick = (index) => {
        inputRefs.current[index].setSelectionRange(1, 1);
    
        if (index > 0 && !otp[index - 1]) {
          inputRefs.current[otp.indexOf("")].focus();
        }
      };
    
      const handleKeyDown = (index, e) => {
        if (e.key === "Backspace" && !otp[index] && index > 0 && inputRefs.current[index - 1]) {
          inputRefs.current[index - 1].focus();
        }
      };
      
      const forgotpassforemailsend = async() => {
        try {
          setDisablebtn(true);
          setLoading(true)
          const response =  await forgotpasswordEmailpost(
          form?.email,
            );
          if (response?.status === 200) {
            setLoading(false)
            if(response?.data?.message===forgotemailsuccessAuthcheck){
              swal({
                title:otpsenttoemailMsg ,
                text: "",
                icon: "success",
                buttons: "OK",
                closeOnClickOutside:false,
                dangerMode: false,
              })
              .then(() => {
                sessionStorage.setItem("forgotforemail",form?.email)
                setToggleform(true);
                setDisablebtn(false);
              });   
            }
            else{
              return;
            }
            }       
          }
        catch (error) {
          setLoading(false)
         
          if (error.response) {
            swal({
              title: APIerrorTitle,
              text: apiErrorMsg,
              icon: "error",
              buttons: "OK",
              closeOnClickOutside:false,
              dangerMode: true,
            })
            .then(() => {
              setDisablebtn(false);
              
            });
            // The request was made and the server responded with a status code
          
          } 
        }
      };
      const forgotpassforOtpsend = async() => {
        try {
          setDisablebtn(true);
          setLoading(true)
          const response =  await varifyotpPost(
          sessionStorage.getItem("forgotforemail"),
          otp_string
            );
          if (response?.status === 200) {
            setLoading(false)
          
              if(response?.data?.message===otpsuccessAuthcheck){
          swal({
            title: otpverifyTitle ,
            text: otpverifyMsg,
            icon: "success",
            buttons: "OK",
            closeOnClickOutside:false,
            dangerMode: false,
          })
          .then(() => {
            setShowotp("otpverify");
            setToggleform(true);
            setDisablebtn(false);
          });   
         }
           else{
            return;
           }
            }       
          }
        catch (error) {
          setLoading(false)
          if (error.response) {
            if(error?.response?.data?.message===otpAuthcheck)
              {
                swal({
                  title: "",
                  text: otpcorrectionMsg,
                  icon: "error",
                  buttons: "OK",
                  closeOnClickOutside:false,
                  dangerMode: true,
                })
                .then(() => {
                  setDisablebtn(false);
                  setOtp(new Array(length).fill(""));
                  
                });
              }
           else{
            swal({
              title: APIerrorTitle,
              text: apiErrorMsg,
              icon: "error",
              buttons: "OK",
              closeOnClickOutside:false,
              dangerMode: true,
            })
            .then(() => {
              setDisablebtn(false);
              
            });
           }
           
            // The request was made and the server responded with a status code
    
          } 
        }
      };
      const forgotpassfornewConfirmsend = async() => {
        setLoading(true)
        var bodyFormData = new FormData();
       bodyFormData.append("Email",sessionStorage.getItem("forgotforemail"));
       bodyFormData.append("NewPassword", formm?.password);  
       bodyFormData.append("ConfirmPassword", formm?.confirmPassword);  
       try { 
         const response =  await resetpasswordPost(bodyFormData);
         if (response?.status === 200) {
          setLoading(false)
          if(response?.data?.message===forgotpasssucessAuthcheck){
          swal({
            title: successfullyTitle,
            text: changepasswordMsg,
            icon: "success",
             closeOnClickOutside:false,
             dangerMode: false,
           }).then(() => {
            sessionStorage.removeItem("forgotforemail");
            setOpenforgot(false);
            setShowotp(null);
            setToggleform(false);
            setFormm({
               password: "",
               confirmPassword: ""
             });
            });
          }
          else{
            return;
          }
        
          }             
       } catch (error) {
        setLoading(false)
         if (error.response) {
          if(error.response.data?.message===forgotpassAuthcheck){
            await swal({
              title: tryagainMsg,
              text: correctpasswordMsg,
              icon: "",
               closeOnClickOutside:false,
               dangerMode: true,
             }).then(() => {
              setFormm({
                 password: "",
                 confirmPassword: ""
               });
             });
          }
          else{
            await swal({
              title: APIerrorTitle,
              text: apiErrorMsg,
              icon: "error",
               closeOnClickOutside:false,
               dangerMode: true,
             }).then(() => {
              setFormm({
                 password: "",
                 confirmPassword: ""
               });
             });
          }
           
         } 
       }
   
     }; 

     const onSubmitFormforotp=()=>{
      if(otp_string?.length<6){
        swal({
          title: Enterotpmsg,
          text: "",
          icon: "",
           closeOnClickOutside:false,
           dangerMode: true,
         })
      }
      else{
        forgotpassforOtpsend();
      }
    }


  return (

    (
      toggleform === false && showotp === null || undefined ?
        <form className={styles.form} onSubmit={onSubmitForm}>
      <img className={styles.loginlogo} src={logo} alt="img" />
      <div className={styles.formGroup}>
        <h5 className={styles.wel_head} >
          <b>Forgot Password!</b>
        </h5>
        <label className={styles.formLabel}></label>
        <input
          className={clsx(
            styles.formField,
            errors.email.dirty && errors.email.error && styles.formFieldError
          )}
          type="text"
          autocomplete="off"
          aria-label="Email field"
          name="email"
          placeholder="Email"
          value={form?.email}
          onChange={onUpdateField}
          onBlur={onBlurField}
        />
        {errors.email.dirty && errors.email.error ? (
          <p className={styles.formFieldErrorMessage}>{errors.email.message}</p>
        ) : null}
      </div>

      
      <div className={styles.formActions}>
        <button
          className={styles.formSubmitBtn}
          type="submit"
          onClick={onSubmitForm}
          disabled={disablebtn}
        >
          Submit {" "}
        </button>
      </div>
      <p className={styles.para_term_policy}>
        {" "}
        By registering, you agree to Gaffer<sup className="Trademark">TM</sup>{" "}
        and{" "}
        <Link to="/Privacypolicy"
         onClick={() => {
          dispatch(closeModal(false));
          setOpenforgot(false);
          setShowotp(null);
          setToggleform(false);
        }}
        >
          <a className={styles.a_ele} href="/Privacypolicy">
            Privacy Policy
          </a>
        </Link>
      </p>
      {loading?<Loader/>:null}
    </form>:
    (  showotp===null||undefined && toggleform===true?
    <form className={styles.form} onSubmit={onSubmitFormm}>
     <img className={styles.loginlogo} src={logo} alt="img" />
     <div className={styles.formGroup}>
       <h5 className={styles.wel_head} >
         <b> Enter the OTP</b>
       </h5>
       <label className={styles.formLabel}></label>     
     </div>
     
     <div className='text-center'>
     {otp?.map((value, index) => (
        <input
          key={index}
          type="text"
          ref={(input) => (inputRefs.current[index] = input)}
          value={value}
          onChange={(e) => handleChange(index, e)}
          onClick={() => handleClick(index)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          className="otpInput"
        />
      ))}
      </div>
     <div className={styles.formActions}>
       <button
         className={styles.formSubmitBtn}
         type="submit"
         onClick={onSubmitFormforotp}
         disabled={disablebtn}
       >
         Submit {" "}
       </button>
     </div>
     <p className={styles.para_term_policy}>
       {" "}
       By registering, you agree to Gaffer<sup className="Trademark">TM</sup>{" "}
       and{" "}
       <Link to="/Privacypolicy"
        onClick={() => {
          dispatch(closeModal(false));
          setOpenforgot(false);
          setShowotp(null);
          setToggleform(false);
        }}
        >
         <a className={styles.a_ele} href="/Privacypolicy"
         >
           Privacy Policy
         </a>
       </Link>
     </p>
     {loading?<Loader/>:null}
   </form>:

       <form className={styles.form} onSubmit={onSubmitFormm}>
           
       <img className={styles.loginlogo} src={logo} alt="img" />
       <div className={styles.formGroup}>
         <h5 className={styles.wel_head} >
           <b>Gaffer</b><sup className="Trademark2">TM</sup>
         </h5>
         <label className={styles.formLabel}></label>     
       </div>
      
       <div className={styles.formGroup}>
          <input
            className={clsx(
              styles.formField,
              errorss.password.dirty &&
                errorss.password.error &&
                styles.formFieldError
            )}
            type={showPassword ? "text" : "password"}
            autocomplete="off"
            aria-label="Password field"
            name="password"
            placeholder="New Password*"
            value={formm?.password}
            onChange={onUpdateFieldd}
            onBlur={onBlurFieldd}
          />{" "}
          <div
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
             <YourIcon className={styles.icon_password_login}/>
            ) : (
              <YourIcon2 className={styles.iicon_password_login}  />
            )}
          </div>
          {errorss.password.dirty && errorss.password.error ? (
            <p className={styles.formFieldErrorMessage}>
              {errorss.password.message}
            </p>
          ) : null}
        </div>

        <div className={styles.formGroup}>
          <input
            className={clsx(
              styles.formField,
              errorss.confirmPassword.dirty &&
                errorss.confirmPassword.error &&
                styles.formFieldError
            )}
            type={showcPassword ? "text" : "password"}
            aria-label="confirm Password field"
            name="confirmPassword"
            placeholder="Confirm Password *"
            value={formm?.confirmPassword}
            onChange={onUpdateFieldd}
            onBlur={onBlurFieldd}
          />{" "}
          <div onClick={togglecPasswordVisibility}>
            {showcPassword ? (
              <YourIcon className={styles.icon_password_login}/>
            ) : (
                <YourIcon2 className={styles.iicon_password_login}/>
            )}
          </div>
          {errorss.confirmPassword.dirty && errorss.confirmPassword.error ? (
            <p className={styles.formFieldErrorMessage}>
              {errorss.confirmPassword.message}
            </p>
          ) : null}
        </div>
      
       <div className={styles.formActions}>
         <button
           className={styles.formSubmitBtn}
           type="submit"
           onClick={onSubmitFormm}
         >
           Submit {" "}
         </button>
       </div>
       <p className={styles.para_term_policy}>
         {" "}
         By registering, you agree to Gaffer<sup className="Trademark">TM</sup>{" "}
         and{" "}
         <Link to="/Privacypolicy"
          onClick={() => {
            dispatch(closeModal(false));
            setOpenforgot(false);
            setShowotp(null);
            setToggleform(false);
          }}
          >
           <a className={styles.a_ele} href="/Privacypolicy">
             Privacy Policy
           </a>
         </Link>
       </p>
       {loading?<Loader/>:null}
       </form>
   
  )
)
  )
}

export default Forgotpassword