import { useState,useEffect } from 'react';
import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Cookies from 'js-cookie';
export default function ViewdetailGraph({playersc,otherplayername,allplayerweeklyscores,isOtherDivOpen,playerweeklyscores,otherplayerweeklyscores}) {

  
const secondpusername=Cookies.get("secondpusername")
const [alldata,setAlldata]=useState([]);
const [pData,setPdata]=useState([]); //login player sores

const [uData,setUData]=useState([]); //other player scores weekly 



  const xLabels = [];
  for (const playerId of allplayerweeklyscores) {
    for (const score of playerId?.scores) {
      xLabels.push("Week " + score?.week);
    }
   }
  const yLabels = [];

  for (const playerId of allplayerweeklyscores) {
    for (const score of playerId?.scores) {
      yLabels.push(score?.finalScore);
    }
  }




const loginp_name=Cookies.get("name")
const allusername= playersc?.map((player) => player?.userName).flat();

useEffect(() => {
  const newDataArray = allplayerweeklyscores?.map((scoreObject, index) => {
        return {
          Score: scoreObject?.scores,
        };
      });
      
      const updatedData = newDataArray?.map((data) => data?.Score);
      const newDataArrayy = [];
      for (let i = 0; i < updatedData?.length; i++) {
        let scores = [];
        for (let j = 0; j < updatedData[i]?.length; j++) {
          scores.push(updatedData[i][j]?.finalScore);
        }
        newDataArrayy?.push(scores);
      }
      setAlldata(newDataArrayy)
}, [allplayerweeklyscores]);


useEffect(() => {
  const newDataArray = playerweeklyscores?.map((scoreObject, index) => {
    return {
      finalScore: scoreObject?.finalScore,
    
    };
  });
  const updatedData = newDataArray?.map((data) => data?.finalScore);
  setPdata(updatedData);
  const othernewDataArray = otherplayerweeklyscores?.map((scoreObject, index) => {
    return {
      finalScore: scoreObject?.finalScore,
    
    };
  });
  const otherupdatedData = othernewDataArray?.map((data) => data?.finalScore);
  setUData(otherupdatedData)
}, [playerweeklyscores,otherplayerweeklyscores])


  return (
    <div className='score_graph'>
      {isOtherDivOpen==false?<div>
      <LineChart
    width={900}
    height={555}
    series={isOtherDivOpen ? null : alldata?.map((data, index) => ({
      data: data,
      label:allusername[index]
    }))} 

    xAxis={[{ scaleType: 'point', data: xLabels,  label: 'Weekly'}]}
    yAxis={[{ data: yLabels ,title: 'Scores',  label: 'Scores'}]}
  />      </div>:<div>
  <LineChart
width={900}
height={555}
series={isOtherDivOpen ?[
  { data: pData?.length==undefined?[]:pData, label:loginp_name },
  { data: isOtherDivOpen?uData?.length==undefined?[]:uData:[], label: otherplayername},
]:null}

xAxis={[{ scaleType: 'point', data: xLabels , label: 'Weekly'}]}
yAxis={[{ data: yLabels ,title: 'Scores',label: 'Scores'}]}
/>
  </div>
}
  </div>
  );
}
