import React,{useState,useEffect} from "react";
import "./Subheader.css";
import { NavLink } from "react-router-dom";
import { useNavigate ,useLocation} from "react-router-dom";
import Cookies from 'js-cookie';
function Subheader() {
  const location = useLocation();
  const navigate = useNavigate();

  
  useEffect(() => {
    if (!Cookies.get('token')) {
      navigate("/");
    }
  });


  return (
    <div>
      <nav className="navbar   subheader-nav">
        <div className="card-body btns-subheader">
          <NavLink
            to="/Dashboard"
            activeClassName="active"
            className="btn btn-primary  subheader-btn"
            exact
          >
            Dashboard
          </NavLink>

          <div className="dropdown show">
            <a
               className={`btn btn-primary subheader-btn dropdown-toggle  ${['/Myleagues','/Myleaguedlive','/Myleagueupcoming','/Myleagueinvites','/Myleaguehistory','/myleagues','/viewdetails','/Draft'].some(path => location?.pathname == path) || location.pathname.startsWith('/Draft') || location.pathname.startsWith('/viewdetails') ? 'active' : ''}`}
              
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
                 
            >
              My League
            </a>

            <div
              className="dropdown-menu myleague_items_div"
              aria-labelledby="dropdownMenuLink"
            >
              <NavLink
                to="/Myleaguedlive"
                className="navLinkWithoutDecoration dropdown-item items_myleague"
                exact
              >
                Live Drafts
              </NavLink>
              <NavLink
                to="/Myleagueupcoming"
                className="navLinkWithoutDecoration dropdown-item items_myleague"
                exact
              >
                Upcoming
              </NavLink>

              <NavLink
                to="/Myleagueinvites"
                className="navLinkWithoutDecoration dropdown-item items_myleague"
                exact
              >
                Invites
              </NavLink>

              <NavLink
                to="/Myleaguehistory"
                className="navLinkWithoutDecoration dropdown-item items_myleague"
                exact
              >
                History
              </NavLink>
              <NavLink
                to="/myleagues"
                className="navLinkWithoutDecoration dropdown-item items_myleague"
                exact
              >
                MY LEAGUES
              </NavLink>
            </div>
          </div>
          <NavLink
            to="/Editprofile"
            
            exact
          
            className={`btn btn-primary subheader-btn  ${['/Editprofile', '/Changepassword', '/Watchlist', '/Subscriptionpayment'].some(path => location?.pathname === path) ? 'active' : ''}`}
          >
            Profile
          </NavLink>
          <NavLink
            to="/Createlegue"
            activeClassName="active"
            exact
            className="btn btn-primary subheader-btn "
          >
            Create League
          </NavLink>
        </div>
      </nav>
    </div>
  );
}

export default Subheader;
