import React from "react";
import ScrollButton from "../../components/scrollbutton/ScrollButton";
import Navbarr from "../../components/header/Navbarr";
import { Footer } from "../../components/footer/footer";
import Latestnewsbanner from "../../components/latestnewsbanner/Latestnewsbanner";
import "./Aboutus.css";
import Submitbtn from "../../components/submit/Submitbtn";
import { useNavigate } from "react-router-dom";
import Dummyads from "../../components/google-AdSense/Dummyads";
import Googleads from "../../components/google-AdSense/Googleads";
import Googleadshorizontal from "../../components/google-AdSense/Googleadshorizontal";
function Aboutus() {
    const navigate=useNavigate();
  return (
    <div>
      <Navbarr/>
      <Latestnewsbanner text={"About Us"}/>
      
      <div className="parent_aboutus">
        <div className="col-md-12">
          <div>
            <h4>Welcome to Gaffer<sup className="Trademark3">TM</sup>…your chance to participate in the success (or failure) of movies!</h4>
            <p className="para_aboutus1">
            Gaffer<sup className="Trademark">TM</sup> is simple: it’s fantasy sports, but for movies. At
Gaffer<sup className="Trademark">TM</sup>, we have crossed the streams of movies lovers and competition in a unique and
interactive way.
            
            </p>
            <p className="para_aboutus1">
            Gaffer<sup className="Trademark">TM</sup> allows movie fans to draft movies, watch how they perform amongst fans and critics, and earn points based on the movie’s successes (or, again, failures). See how all of your movies within a season add up and fight for the ultimate bragging rights as the annoying friend who watches everything!
            </p>
          </div>
         </div>
      </div>
      <div className="aboutus_secondbackground bg-dark">
       <div className="about-us-layer" >
        <h4 className="card-title text-white text-center">What are you waiting for?</h4>
        <p className="text-white pt-2 text-center">With a constant flow of new movies added every week or month, you'll always have a diverse selection of fresh and classic films to play league.</p>
        <Submitbtn props="Explore More" onSubmitForm={()=>navigate("/Movieslist")}/>
        </div>
        </div>
        <div className="pt-2 aboutus_fdiv">
        {/* <Dummyads/> */}
        {/* <Googleads props="mt-2 mb-2"/> */}
        <Googleadshorizontal props="mt-2 mb-2 text-center"/>
        <Footer />
         </div>

      <ScrollButton />
    </div>
  );
}

export default Aboutus;
