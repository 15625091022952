import Accordion from 'react-bootstrap/Accordion';
import "./Accordion.css"
function Accordionpage() {
  return (
    <Accordion className='main_accordion' defaultActiveKey="0">
      <Accordion.Item  eventKey="0">
        <Accordion.Header className='item_accordion'>Welcome</Accordion.Header>
        <Accordion.Body className="check_bodyy">
          <h4>Welcome to Gaffer<sup className="Trademark3">TM</sup>…your chance to participate in the success (or failure) of movies!</h4>
          <div className='pt-2'>        
          <p>
          Gaffer<sup className="Trademark">TM</sup> is simple: it's fantasy sports, but for movies. At Gaffer<sup className="Trademark">TM</sup>, we have crossed the streams of movies lovers and competition in a unique and interactive way.
</p>
<p>Gaffer<sup className="Trademark">TM</sup> allows movie fans to draft movies, watch how they perform amongst fans and critics, and earn points based on the movie’s successes (or, again, failures). See how all of your movies within a season add up and fight for the ultimate bragging rights as the annoying friend who watches everything!</p>
</div>
<p>We have partnered with our friends at IMDb to provide players with all of the information to pick
the best of the best movies for their league. Thanks to IMDb, you can comb through actors,
writers, directors, release dates, genres, whatever you may need to come to a conclusion
whether a movie has enough promise to join your roster.
</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header className='item_accordion'>How Do Movies Earn Points?</Accordion.Header>
        <Accordion.Body className="check_bodyy">
        <p>As mentioned, movies earn points based on a number of factors. We take the conglomerate of both audience and critic reactions to movies from several popular movie review sites (Rotten Tomatoes, Letterboxd, Metacritic, and of course, IMDb). For simplicity’s sake, we average all scores out to operate on a 10 point scale. For Letterboxd ratings, we multiply by 2 to operate on a 10 point scale. Similarly, we divide Metacritic and Rotten Tomatoes ratings by 10 to operate on a similar scale. </p>
<p>Additionally, we track how many people are watching the movies! We watch the performance of movies at the box office in addition to how many people are watching these movies at home. For box office performance, movies earn 1 point per every $100M they earn. 5 point bonuses are awarded for movies that cross the threshold of $500M and $1B at the box office. </p>
<p>Movies also receive bonuses for watches: not just at the theaters, but at home as well. We track Letterboxd to see what movies pass 500K watches, 1M watches, 1.5M watches, and so on, and reward 5 point bonuses respectively at each milestone.</p>
<p>Finally, we offer several opportunities to earn end of season points for certain lucky movies. In a nutshell, we track metrics and impressions from fans, critics, the box office, and everyone who watches movies.</p>
<p>
Additionally, we have instances where movies go to battle by releasing on the same weekend. In these special cases, movies battle Head 2 Head at the box office, on streaming, or both to see which movie has a more successful opening weekend. 5 bonus points are awarded to the winners of Head 2 Head matchups, as well as a .5 bonus to the overall winners of the box office each weekend. 
</p>
<p>Is your movie only releasing on a streaming service and not in theaters? No problem! In the case of these Head 2 Head matchups, we will track just how many people watch the movie and hand out bonus points for views and not just ticket sales!</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header className='item_accordion how_tostartitem'>How to Get Started </Accordion.Header>
          <Accordion className='main_accordion nestedc'>   
        <Accordion.Item  eventKey="1">
          <Accordion.Header className='item_accordion'>Create a League</Accordion.Header>
          <Accordion.Body className="check_bodyy">
            <p>First things first: you have to get in on the action. You can do this by creating your own league
and inviting your friends to join, or joining a league already created by one of your friends. Invite
up to 10 of your friends to join a league and choose to draft up to 10 movies each to put on your
roster!</p>
<p>We have given league creators 2 options when creating their own league: play our suggested
season format, or make your own!
</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className='item_accordion'>The RCR Template</Accordion.Header>
          <Accordion.Body className="check_bodyy">
            <p>Daunted by the task of crafting your own league rules and parameters? Use our RCR Template,
popularized by our friends over at the “Rough Cut Retrospective” podcast. Using this template,
we have curated an eclectic, year-long season that begins in January of the upcoming year and
runs all the way to the following January when Academy Award nominations are announced.
This template offers players the chance to draft movies to fill all kinds of category slots for all
kinds of movie fans. The category slots in the RCR Template are as follows:</p>
<p>Potential Blockbuster (any movie that you feel will earn big at the box office)</p>
<p> Oscar Bait (any movie that you think is receiving respect from the Academy)</p>
<p>A24/Neon Film (a movie from smaller scale, respected studios)</p>
<p>Franchise Film (a sequel, prequel, reboot, or continuation of an already established movie IP)</p>
<p>Animated Film (for the kids and kids at heart, an animated movie)</p>
<p>Horror Film (for the fans who want to get spooked)</p>
<p>Streaming Film (Netflix, Disney+, Amazon, you name it. Movies that aren’t going to theaters)</p>
<p>Wild Card (any movie of your choosing)</p>
<p>Wild Card (any movie of your choosing)</p>
<p>Wild Card (any movie of your choosing)</p>
<p>Bench (a place to put promising movies, but movies that you may be unsure about at the
time)</p>
<p>Bench (a place to put promising movies, but movies that you may be unsure about at the
time)</p>
<p>We have everything a well rounded film fan could ask for in this template: a box office star, a
flashy awards season hopeful, something for the Netflix and chillers, something for those with
eclectic tastes, just to name a few.</p>
<p>With the RCR Template format, we suggest starting your season in the first week or so of
January. Don’t miss out on drafting those sleeper January release movies and those early
Sundance Film Festival dandies. Next, we suggest setting the end date of your season on or
after Academy Award nominations are announced for the year you are drafting from. Why?
</p>
<p>Well, we want you to benefit from all a full year long season has to offer with our end of season
awards.</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header className='item_accordion'>End of Season Awards</Accordion.Header>
          <Accordion.Body className="check_bodyy">
            <p>The Blockbuster Award - for this award, every competitor’s movie drafted in the Potential
Blockbuster slot is competing to take home 25 bonus points for their team. At the end of the
season, the movie in the Potential Blockbuster slot that earned the most money at the box office
earns the bonus points!</p>
            <p>The Academy Award - for this award, every competitor’s movie drafted in the Oscar Bait slot is
competing to take home 25 bonus points for their team. At the end of the season, the movie in
the Oscar Bait slot that earned the most Oscar nominations will receive the bonus points! This is
why we suggest setting the end date of the season to coincide with Oscar nomination
announcements</p>
            <p>Film Bro Award - not every movie is destined for greatness. Sometimes, you miss the mark. So,
for the unlucky competitor, if your movie is the lowest scoring movie in the league, we’ll throw
you a pity 25 bonus points for your troubling times.
</p>
            <p>With the RCR Template, you get the full experience of a year of movies and all of the madness
that entails. You get the chance to watch movies compete to earn millions of dollars, earn
prestigious award nominations, and to truly create a well rounded, well curated team of
successful movies.</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header className='item_accordion'>Build Your Own Template</Accordion.Header>
          <Accordion.Body className="check_bodyy">
            <p>Not interested in our curated template? No problem! We give each league creator total
customization of the league they would like to build with their friends! You set the number of
players, the length of the season, the draft slots you want to fill, and the opportunity for end of
year awards.
</p>
            <p>The draft slots listed in the RCR Template are up for grabs to create your own unique league
build out. However, we’ve given you free reign to get genre specific as well! We offer a number
of draft categories, from Animated to Action, from Drama to Comedy, from War to Western, you
name it! Get as specific as you want!</p>
            <p>Want to only draft 5 movies each instead of 10? Go for it.
</p>
            <p>Want to just create a summer movie draft that runs from May to September? By all means.</p>
            <p>Want to draft all Wild Cards, no parameters? Go crazy</p>
            <p>Whatever your flavor, however you want to play, it’s up to you!</p>
            <p>Just know, should you choose not to incorporate Potential Blockbuster and Oscar Bait into your
league, this will disqualify the Blockbuster Award and the Academy Award. But don’t worry,
there is always the Film Bro Award to carry you to unexpected victory!</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header className='item_accordion'>How to Get Ready for a Draft</Accordion.Header>
        <Accordion.Body className="check_bodyy">
        <p>The league has been created. The rules have been set. The slots have been determined. The
draft date is quickly approaching. It’s time to prepare.
</p>
<p>To prepare for a draft, we recommend using our “Watchlist” to curate the probable movies you
would like to draft. As you are hunting for movies to fill the preset draft categories/slots, you will
notice that each movie is tagged with possible categories based on the type of movie it is. Use
these categories to plan your top picks and your backups to fill the slots in the live draft. Looking
for a Drama? Better add a few to the watchlist. Need to draft a Franchise Film? Better add a few
promising sequels to your watchlist.</p>
<p>At Gaffer<sup className="Trademark">TM</sup>, we aren’t fans of category fraud. Thanks to our categorizing system, a competitor
cannot just draft any old film into a category specific slot. Trying to draft a Comedy into a Horror
slot? Nice try. Trying to draft a Warner Bros movie into the A24/Neon slot. Guess again. The
only draft slots that are category fluid are Potential Blockbuster, Oscar Bait, Wild Card, and
Bench. Put whatever movie you want in there! This gives you some freedom to move certain
things around should you choose.</p>
<p>Better safe than sorry when adding movies to your watchlist. Movies get scooped up quick in
the draft. Make sure you have more than enough back ups come draft day.
</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header className='item_accordion'>Draft Day</Accordion.Header>
        <Accordion.Body className="check_bodyy">
        Draft day works like your typical fantasy football draft. There will be a set date and time your
draft will start based on what the creator decided. You’ll be on the clock, and this is what all of
the preparation has led to. Draft your movies, put them in the slots you prefer, move them
around on your roster as the draft goes on, follow along with the movies your competitors are
picking, and make sure you use that watchlist to help you remember what you were interested
in in the first place
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header className='item_accordion'>The Season</Accordion.Header>
        <Accordion.Body className="check_bodyy">
        The draft is complete. You have set your roster. You have seen your competitors’ picks. Now, it’s
time for the real fun to begin. There are many things you can do during the season to adjust
your roster and improve your prospects!
</Accordion.Body>
<Accordion>
        <Accordion.Item eventKey="1">
          <Accordion.Header className='item_accordion'>Trading</Accordion.Header>
          <Accordion.Body className="check_bodyy">
            <p>Each competitor in a league is welcome to propose, decline, counter offer, and accept trades
with other players. Trades can be as simple as a movie for a movie, or can get crazy by offering
2 or 3 movies for one coveted movie from your competitor.</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className='item_accordion'>Free Agency</Accordion.Header>
          <Accordion.Body className="check_bodyy">
            <p>Unhappy with a movie you drafted and you just want to get rid of it and not bother with trading?
You can drop it altogether and pick up a new movie from the master movie pool that wasn’t
drafted by anybody.
</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header className='item_accordion'>The Bench</Accordion.Header>
          <Accordion.Body className="check_bodyy">
            <p>The bench is a tool you can use to test the waters on a movie you are unsure about. A movie on
the bench accrues points, but the points do not factor into your team’s total points. A movie on
the bench is also not eligible for Head 2 Head points. However, a movie can be moved from the
bench on to the roster at any time, whether it has started accruing points or not.
</p>
<p>If a movie on the bench has accrued points, you can elect to swap that movie onto your main
roster as long as
<p>- it fits that category slot on the roster</p>
<p>- The movie you are swapping out from the roster has not accrued points yet.</p>
</p>
<p>If a movie on the bench has accrued points, you can also elect to drop it from your team
altogether and go find a new movie from the master movie pool to fill that slot.
</p>
<p>In a nutshell, movies on the bench don’t earn you points, but if you see that movie is performing
well, you might elect to move it onto your main roster. If you see that movie is performing poorly,
you can scrap it and go find a better prospect in free agency.
</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header className='item_accordion'>Locked Movies</Accordion.Header>
          <Accordion.Body className="check_bodyy">
            <p>It has been alluded to, but once a movie starts earning points, that movie is LOCKED IN to your
roster. You can do all of the trading, dropping, and bench swapping you want until a movie starts
receiving critical reception upon release.</p>
<p>The same applies for movies in free agency. Once a movie has received critical reception, it
cannot be picked up and put on your team from the master movie pool.
</p>
<p>The only exception is, as previously mentioned, movies on your bench.</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
        {/* </Accordion.Body> */}
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header className='item_accordion'>End of Season/New Leagues</Accordion.Header>
        <Accordion.Body className="check_bodyy">
        Depending on the makeup of your league, the end of your season may be approaching quickly.
On the other hand, you might be in it for the long haul and the season will take a year. If you are
feeling the need to start a new league with friends in the meantime, or want to get ready for next
year’s draft, you can always start a new league at any time. The advantage Gaffer<sup className="Trademark">TM</sup> has
compared to other fantasy leagues is that you can start your own unique league at any time.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header className='item_accordion'>Tips and Tricks</Accordion.Header>
        <Accordion.Body className="check_bodyy">
        <p> Don’t be afraid to switch your roster up. The chatter around movies changes throughout
the year. The buzz about some may be greater than others. New movies spring up out of
nowhere. Sometimes movies get pushed back to next year. Follow the news section and
try to keep a finger on the pulse of the industry. This will put you at a competitive
advantage.
</p>
<p>Use that bench to your advantage. Sometimes a movie bombs, and if you have the
category flexibility, you can avoid that by putting a movie on your bench to test the
waters. Don’t get stuck with a lame duck movie on your roster because you didn’t take
advantage of the bench.</p>
<p>Pay attention to weekly updates and notifications. It will let you know when Head 2 Head
matchups are coming up that may apply to you. Notifications will also let you know when
a movie release is coming up, trades that are occurring in your league, etc. Keep tabs on
the activity in your league. While you might be resting on your laurels, your competitors
might be making moves to be victorious.
</p>
<p>Be weary of movies with no confirmed release date. We allow you to draft any movie you
want, so long as it hasn’t accrued points yet. Sometimes these movies will have release
dates, but sometimes they might have a status of “pre-production”, “in production”, or
“post-production”. It’s up to you to roll the dice, but as a good rule of thumb,
“pre-production” and “in production” are usually not good signs that that movie is
releasing within the calendar year. As the season goes on, be aware of that and be
prepared to make a free agency pickup in the instance where that movie does not get a
release date before your draft end date.
</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header className='item_accordion'>Good Luck!</Accordion.Header>
        <Accordion.Body className="check_bodyy">
        <p> And that’s it! Those are all of the bells and whistles of Gaffer<sup className="Trademark">TM</sup>! It’s up to you how to use our app
and create the space for you and your movie loving friends! Strategize, cooperate,
communicate, bust most importantly, have fun being a part of the movies. This app was created
by movie lovers for movie lovers. We have worked tirelessly to bring you this final product, and
we will continually work to improve the experience for you all.</p>
<p>Good luck, have fun, see you at the movies....</p>
        </Accordion.Body>
      </Accordion.Item>
        </Accordion>
  );
}

export default Accordionpage;