import React from 'react'
import "./Tradebtn.css";
import { ReactComponent as YourIcon3 } from "../../../../../assets/Vectorwhite.svg";
function Tradebtn({Trade}) {
  return (
<button className="trade-btns" >
  <div className='d-flex p-2 align-items-center gap-2'>
  <div><YourIcon3 className="trade_icon"/></div>
  <div>{Trade}</div>
  </div>
  </button>
  )
}

export default Tradebtn