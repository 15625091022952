import { createSlice } from '@reduxjs/toolkit';

export const watchlistcountSlice = createSlice({
  name: 'watchlistcount', // Slice name
  initialState: 
    0
  , // Initial state (empty array in this case)
  reducers: {
    setWatchlistcount: (state, action) => {
      // Update the state with the received data
      return action.payload;
    },
  },
});

// Export the action creator
export const { setWatchlistcount } = watchlistcountSlice.actions;

// Export the reducer
export default watchlistcountSlice.reducer;