import React,{useState,useEffect} from 'react'
import { useCategory } from '../../../../../components/useCategory';
import { dropmoviewithpoolPost, playermoviesGet, swapwithbenchmoviePost, upcomingpollmoviesGet ,completeTraderequest} from '../../../../../api/handleapi';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { openModalmdetails } from '../../../../../feature/showSlice/showmodaltrademoviedetailsSlice';
import { setTrademoviedetailsforid } from '../../../../../feature/showSlice/trademoviedetailsforidSlice';

import Searchmovies from '../../../../../components/searchmovies/Searchmovies';
import swal from 'sweetalert';
import { moviesscoregetMsgforpickups, pickupAuthcheck, pickupMsg, pickupsMsg, successfullyTitle, swapMsg, swapsAuthcheck, swapsconAuthcheck, swapvalidationMsg } from '../../../../../components/language/Language';


function Tradeaccept({togglepickup,setTogglepickup,toggleadjust,setToggleAdjust,trademovies,showtradeaccpet}) {
    
    const Categoryoptions=useCategory();
    const [ftrade,setFtrade]=useState({
        FirstUserId :"",
        FMovieId :"",
        FCategoryId :"",
        FMovieName :"",
      })
      const [pollmovies,setPollmovies]=useState([]);
      const [categorynamepickup,setcategoryNamepickup]=useState([]);
      const [benchmovies,setBenchmovies] = useState([]);
      const [othermovie,setOthermovie] = useState([]);
      const [btrade,setBtrade]=useState({
        BenchMovieId:"",
        BenchMovieCategoryId:"",
      })
      const [trademovieselectcheck,setTrademovieselectcheck]=useState(false);

      const [pickupmoviecollect,setPickupmoviecollect]=useState(
        {
            pickmoviemovieid:"",
            pickmoviemoviename:"",
            pickmoviemoviecategory:"",
        }
      );
      const [othertrade,setOthertrade]=useState({
         MovieId:"",
        MovieCategoryId:"",
             })
      const [fpick,setFpick]=useState({
        MovieId :"",
        MovieCategoryId:"",
      })
      const [pollpick,setPollpick]=useState({
        PoolMovieId :"",
        PoolMovieCategoryId   :"",
      })  
    
    const [inputText, setInputText] = useState("");
     const Leagueid=trademovies?.tradeRequestFor?.leagueId
    const [countermovieslist,setcounterMoviesList] = useState([]);
    const [counterofferloginpmovie,setCounterofferloginpmovie]=useState();
       const userid=Cookies.get("id");
       const dispatch=useDispatch();
       const [selectedCategory, setSelectedCategory] = useState('');

         const movieslistFunforloginp = async (Leagueid,userid) => {
        try {
          const response = await playermoviesGet(Leagueid,userid); 
          setCounterofferloginpmovie(response?.data?.data?.logedInPlayerMovies)
           } catch (error) {
            return;
        }
      }


      useEffect(() => {
        movieslistFunforloginp(Leagueid,userid)
      }, [Leagueid])

      
        const openModalMdetails = (movieId) => {
        dispatch(openModalmdetails(true)); 
        dispatch(setTrademoviedetailsforid(movieId));
    };
    const handleIconClick = (movieid) => {
        let temp_movielist = [...countermovieslist]
        let updatedData = temp_movielist?.map((each)=>{
          if(each.movieId==movieid){
            setFtrade({ FirstUserId :userid,FMovieId:each?.movieId,FCategoryId:each?.categoryId,FMovieName:each?.movieName})
            return {...each,checked:!each.checked}
          }
       
          return {...each,checked:false}
      })
        setcounterMoviesList(updatedData);
      };


      useEffect(() => {
        const updatedMoviesArray = counterofferloginpmovie?.map((movie) => ({
          ...movie,
          checked: false,
      }));
      const updatedMoviesforfinals = updatedMoviesArray?.filter(movie => movie?.finalScore === 0 );
      
        setcounterMoviesList(updatedMoviesforfinals);
        setBenchmovies(updatedMoviesforfinals);
        setOthermovie(updatedMoviesforfinals)      
        },[counterofferloginpmovie,showtradeaccpet]);

        const pollmoviesGet = async (Leagueid) => {
            try {
          const response = await upcomingpollmoviesGet(Leagueid);
           setPollmovies(response?.data?.data)
          } catch (error) {
             return;
          }
          };
      
            useEffect(() => {
             pollmoviesGet(Leagueid);
            }, [Leagueid]);
            
        useEffect(() => {
            const updatedMoviesArrayy = pollmovies?.map((movie) => ({
              ...movie,
              checked: false,
          }));
          setPollmovies(updatedMoviesArrayy);
          },[]);
      
        const handleIconClickkforpool = (movieId,category) => {
            let temp_movielist = [...pollmovies]
            let movieCategoryId = fpick?.MovieCategoryId;
         
            let updatedData = temp_movielist?.map((each)=>{
              if(each?.id==movieId){
             
                if (category?.includes(movieCategoryId)) {
       
                  setPollpick ({
                    PoolMovieId:each?.id,
                    PoolMovieCategoryId:movieCategoryId
                   }); 
                   return {...each,checked:!each.checked}
                }
                else{
                  setPollpick ({
                  PoolMovieId:each?.id,
                 }); 
                return {...each,checked:!each.checked}
                }
              }
              return {...each,checked:false}
          })
            setPollmovies(updatedData);
          };
      
        let inputHandler = (e) => {
            //convert input text to lower case
            var lowerCase = e.target.value.toLowerCase();
            if (true) {
            setInputText(lowerCase);
            }
          };

          const filteredData = pollmovies?.filter((el) => {
           
            const nameMatch = el?.name?.toLowerCase()?.includes(inputText);
            const categoryMatch = el.category?.includes(selectedCategory);
            if (inputText !== '' && selectedCategory !== 'All') {
              // Scenario 1: Filter by movie name and category
              return nameMatch && categoryMatch;
            } else if (inputText !== '' && (!selectedCategory || selectedCategory === 'All')) {
              // Scenario 2: Filter by movie name only
              return nameMatch;
            } else if (inputText === '' && selectedCategory !== 'All') {
              // Scenario 3: Filter by category only
              return categoryMatch;
            } else {
              // If both inputText and selectedCategory are empty, return all movies
              return true;
            }
          })

      function categoryNameFind(id) {
        const categoryOption = Categoryoptions?.find((option) => option?.id == id);
        if (categoryOption) {
            setcategoryNamepickup(categoryOption?.category);
        }
      }
      
const pickupCall=(mid,mname,cateid)=>{
    setPickupmoviecollect({
        pickmoviemovieid:mid,
        pickmoviemoviename:mname,
        pickmoviemoviecategory:cateid,
    })
    categoryNameFind(cateid)
    setFpick({ MovieId:mid,MovieCategoryId:cateid})
    setTogglepickup(true);
}


  const pickupMovie = async() => {
    if(!pollpick?.PoolMovieId){
      swal({
        title: "",
        text:pickupsMsg ,
        icon: "error",
        closeOnClickOutside:false,
        buttons: "ok",
        dangerMode: false
      })
    }
    else{
    var bodyFormData = new FormData();
    bodyFormData.append("LeagueId",Leagueid );
    bodyFormData.append("PlayerId", userid );
    bodyFormData.append("MovieId", fpick?.MovieId);
    bodyFormData.append("MovieCategoryId",fpick?.MovieCategoryId);
    bodyFormData.append("PoolMovieId", pollpick?.PoolMovieId);
    let movieCategoryId = fpick?.MovieCategoryId;
    if ([2065, 2066, 2096, 2097]?.includes(movieCategoryId)) {
      movieCategoryId = fpick?.MovieCategoryId;
    } else {
      movieCategoryId = pollpick?.PoolMovieCategoryId;
    }
    bodyFormData.append("PoolMovieCategoryId", movieCategoryId);     
    try {
      const response =  await dropmoviewithpoolPost(bodyFormData);
      if (response?.status === 200) {
        movieslistFunforloginp(Leagueid,userid)
        pollmoviesGet(Leagueid)
        swal({
          title: successfullyTitle,
          text: pickupMsg,
          icon: "success",
          closeOnClickOutside:false,
          buttons: "ok",
          dangerMode: false
        }).then((value) => {
          if(value){
               return;           
          }})
      } 
    } 
    catch (error) {
      if (error.response.data?.message!=undefined && error.response.data?.message==pickupAuthcheck) {             
        swal({
                  title: "",
                  text:moviesscoregetMsgforpickups,
                  icon: "error",
                  buttons: "ok",
                  closeOnClickOutside:false,
                  dangerMode: false
                })                  
              }         
              else if (error.response.data?.message===undefined) {
                swal({
                    title: "",
                    text: `Select movies that are categorized as ${categorynamepickup} to pickup`,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  })
              }else {
                  return;
              }
    }
  }
  }


const handleIconClickadjustfirst = (movieid,categoryid) => {
    categoryNameFind(categoryid)
      let temp_movielist = [...benchmovies]
      let updatedData = temp_movielist?.map((each)=>{
        if(each?.movieId==movieid){
          setBtrade({ BenchMovieId:each?.movieId,BenchMovieCategoryId:each?.categoryId})
          return {...each,checked:!each.checked}
        }
        return {...each,checked:false}
    })
      setBenchmovies(updatedData);
    };
  
const handleIconClickkadjustsecond = (movieId) => {
    let temp_movielist = [...othermovie]
    let updatedData = temp_movielist?.map((each)=>{
      if(each?.movieId==movieId){
        setOthertrade ({
          MovieId:each?.movieId,
          MovieCategoryId:each?.categoryId,
          });
        return {...each,checked:!each.checked}
      }
      return {...each,checked:false}
  })
    setOthermovie(updatedData);
  };


  const swapMovie = async() => {
    var bodyFormData = new FormData();
    bodyFormData.append("LeagueId",Leagueid);
    bodyFormData.append("PlayerId",userid);
    bodyFormData.append("BenchMovieId",btrade?.BenchMovieId );
    bodyFormData.append("BenchMovieCategoryId",btrade?.BenchMovieCategoryId);
    bodyFormData.append("MovieId",othertrade?.MovieId );
    bodyFormData.append("MovieCategoryId",othertrade?.MovieCategoryId);
   
     try {
      const response =  await swapwithbenchmoviePost(bodyFormData);
      if (response?.status === 200) {
        movieslistFunforloginp(Leagueid,userid);
        swal({
          title: successfullyTitle,
          text: swapMsg,
          icon: "success",
          buttons: "ok",
          closeOnClickOutside:false,
          dangerMode: false
        }).then((value) => {
          if(value){
           
            return;
          }})
      } 
    } 
    catch (error) {
      if (error.response.data?.message==swapsAuthcheck ||error.response.data?.message==swapsconAuthcheck) {
       
        swal({
          title: "",
          text: `Select movies that are categorized as ${categorynamepickup} to swap`,
          icon: "error",
          buttons: "ok",
          closeOnClickOutside:false,
          dangerMode: false
        })
      }        
              else if (error.response.data?.message===undefined) {
                swal({
                    title: "",
                    text: swapvalidationMsg,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  })
              }else {
                return;
              }
    }
  }

  const completeTrade = async() => {
    var bodyFormData = new FormData();
    bodyFormData.append("LeagueId",Leagueid );
    bodyFormData.append("FirstUserId",userid );
    bodyFormData.append("FMovieId",ftrade?.FMovieId);
    bodyFormData.append("FCategoryId",ftrade?.FCategoryId);
    bodyFormData.append("FMovieName",trademovies?.requestFrom?.fMovieName);
    bodyFormData.append("SecondUserId",trademovies?.requestFrom?.firstUserId);    
    try {
      const response =  await completeTraderequest(bodyFormData);
      if (response?.status === 200) {
        movieslistFunforloginp(Leagueid,userid)
        swal({
          title: successfullyTitle,
          text: "",
          icon: "success",
          closeOnClickOutside:false,
          buttons: "ok",
          dangerMode: false
        }).then((value) => {
          if(value){
          
               return;           
          }})
      } 
    } 
    catch (error) {
      if (error.response.data?.message) {             
        swal({
                  title: "",
                  text:error.response.data?.message,
                  icon: "error",
                  buttons: "ok",
                  closeOnClickOutside:false,
                  dangerMode: false
                })                  
              }         
              else if (error.response.data?.message===undefined) {
                swal({
                    title: "",
                    text: "",
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  })
              }else {
                  return;
              }
    }
  }
 
  return (
    <div>
    <div className="row">
      {!togglepickup && !toggleadjust?
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div className="heading_live_listt"><h6>My Movies List</h6></div>
                <div className="position_card">
    <div className="table_containerr_trade scrollbarcstm"> 
      <table className="playermovie_list">
    <thead  >
      <tr className="trade_movieslist_headings">
        <td >Movie</td>
        <td >Category</td>
        <td >Score</td>
        <td >Actions</td>
        <td></td>
      </tr>
    </thead>
    <tbody >
  
    {
 countermovieslist?.length!=0?
 countermovieslist?.map((data,index)=>(
  
   <tr className="Player_title" key={index} >
  
         <td>
            <div className='d-flex gap-2'>
            <span className='moviesnamefordetails' onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 10 ? data?.movieName.substring(0, 10) + '...' : data?.movieName}</span>

            </div>
            </td>   
     <td>{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
     <td>{data?.finalScore}</td>
   
     <td className="campareicons checkcounter">
     <input type="checkbox" className='form-check-input' checked={data?.checked}  onClick={() => handleIconClick(data?.movieId)}/>
       </td>
       <td> <button className='pickupbtn_forAccommodate' onClick={()=>pickupCall(data?.movieId,data?.movieName,data?.categoryId)}> Pickup</button></td>
   </tr>
   )):<div className='no_dropm_found'>No movies found</div>
   } 
      
     
         
    </tbody>
  </table>
              </div>
            </div>
  
      </div>
:togglepickup && !toggleadjust?
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 Pickup_singleMovie">
        <div className="heading_live_listt"><h6>Pickup Movie</h6></div>
                <div className="position_card">
    <div className="table_containerr_trade scrollbarcstm"> 
      <table className="playermovie_list">
    <thead  >
      <tr className="trade_movieslist_headings">
        <td >Movie</td>
        <td >Category</td>
        <td >Score</td>
        <td >Actions</td>
        <td></td>
      </tr>
    </thead>
    <tbody >
   <tr className="Player_title">
         <td>
            <div className='d-flex gap-2'>
            <span className='moviesnamefordetails' onClick={()=>openModalMdetails(pickupmoviecollect?.pickmoviemovieid)}>{pickupmoviecollect?.pickmoviemoviename?.length > 10 ? pickupmoviecollect?.pickmoviemoviename?.substring(0, 10) + '...' : pickupmoviecollect?.pickmoviemoviename}</span>

            </div>
            </td>   
     <td>{Categoryoptions?.find(cat => cat?.id == pickupmoviecollect?.pickmoviemoviecategory)?.category}</td>
     <td>0</td>
   
     <td className="campareicons checkcounter">
     <input type="checkbox" className='form-check-input' checked/>
       </td>
   </tr>

     
         
    </tbody>
  </table>
              </div>
            </div>
  
      </div>:null}
     { !togglepickup && !toggleadjust?<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div className="heading_live_listt">
          <h6> 
          Trade Movie
          </h6>
        </div>
            
          <div className="position_card">
    <div className="table_containerr_trade scrollbarcstm"> 
      <table className="playermovie_list">
    <thead  >
      <tr className="trade_movieslist_headings">
        <td >Movie</td>
        <td >Category</td>
        <td >Score</td>
        <td >Actions</td>
      </tr>
    </thead>
    <tbody >
      <tr className="Player_title"  >
            <td>
               <div className='d-flex gap-2'>
               {trademovies?.requestFrom?.fMovieName?.length > 10 ? trademovies?.requestFrom?.fMovieName.substring(0, 10) + '...' : trademovies?.requestFrom?.fMovieName}
               </div>
               </td>   
        <td>{Categoryoptions?.find(cat => cat?.id == trademovies?.requestFrom?.fCategoryId)?.category}</td>
        <td>{trademovies?.requestFrom?.finalScore==undefined||null?0:trademovies?.requestFrom?.finalScore}</td>
        <td className="campareicons checkcounter">
       
        <input type="checkbox" className='form-check-input'  checked={trademovieselectcheck} onChange={()=>setTrademovieselectcheck(!trademovieselectcheck)
}/>
        </td>
      </tr>
    </tbody>
  </table>
              </div>
            </div>
      </div>:togglepickup && !toggleadjust?
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 Available_pollmovie">
      <div className="heading_live_listt">
        <h6> Available Poll Movies </h6>
      </div>
            <div className="position_card">
  <div className="table_containerr_trade pool_tables scrollbarcstm"> 
    <table className="playermovie_list ">
  <thead  >
    <tr className="trade_movieslist_headings">
      <td >
        <Searchmovies inputHandler={inputHandler}/>
             </td>
      <td >  
         <select
                  className="form-select pickups-input-groupp"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  value={selectedCategory}
                >
                  <option selected value="All">Select Category</option>
                                      {Categoryoptions?.sort((a, b) => a?.category?.localeCompare(b?.category))?.filter((item) => !['2065', '2066', '2096', '2097']?.includes(item?.id.toString()))?.map((matchingData, item) => (
                  <option key={item} value={matchingData?.id}>
                 {matchingData?.category}
                </option>
                  ))}
                  
                </select>
                
                  </td>
      <td >Actions</td>
    </tr>
  </thead>
  <tbody>
    {filteredData?.length !=0?
 
  filteredData?.map((data,index)=>(

    <tr className="Player_title" key={index} >
          <td>
             <div className='d-flex gap-2'>
             <span  className='moviesnamefordetails'onClick={()=>openModalMdetails(data?.id)}>{data?.name?.length > 10 ? data?.name.substring(0, 10) + '...' : data?.name}</span>          
             </div>
             </td>   
      <td><div className='listcategory'>
                {data?.category && data?.category.split(',').map((categoryId, index) => (
                          <span key={index} className="listcategory">
                            {Categoryoptions?.find(cat => cat?.id == categoryId)?.category}
                            </span>
                          ))}
                
                </div></td>
      <td className="campareicons">
      <input type="checkbox" className='form-check-input' checked={data?.checked} onChange={()=> handleIconClickkforpool(data?.id,data?.category)} />
      </td>
    </tr>
    )):<div className='no_dropm_found'>No movies found</div>
    } 
  </tbody>
</table>
            </div>
          </div>
    </div>:null}


    {toggleadjust?<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 adjustmentmovies">
        <div className="heading_live_listt"><h6>My Movies List</h6></div>
                <div className="position_card">
    <div className="table_containerr_trade scrollbarcstm"> 
      <table className="playermovie_list">
    <thead  >
      <tr className="trade_movieslist_headings">
        <td >Movie</td>
        <td >Category</td>
        <td >Score</td>
        <td >Actions</td>
      </tr>
    </thead>
    <tbody >
  
    {
 benchmovies?.length!=0?
 benchmovies?.map((data,index)=>(
  
   <tr className="Player_title" key={index} >
  
         <td>
            <div className='d-flex gap-2'>
            <span className='moviesnamefordetails' onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 10 ? data?.movieName.substring(0, 10) + '...' : data?.movieName}</span>

            </div>
            </td>   
     <td>{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
     <td>{data?.finalScore}</td>
   
     <td className="campareicons checkcounter">
     <input type="checkbox" className='form-check-input' checked={data?.checked}  onClick={() => handleIconClickadjustfirst(data?.movieId)}/>
       </td>
   </tr>
   )):<div className='no_dropm_found'>No movies found</div>
   } 
      
    </tbody>
  </table>
              </div>
            </div>
      </div>:""}
      {toggleadjust?<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 adjustmentmovies">
        <div className="heading_live_listt"><h6>My Movies List</h6></div>
                <div className="position_card">
    <div className="table_containerr_trade scrollbarcstm"> 
      <table className="playermovie_list">
    <thead  >
      <tr className="trade_movieslist_headings">
        <td >Movie</td>
        <td >Category</td>
        <td >Score</td>
        <td >Actions</td>
        <td></td>
      </tr>
    </thead>
    <tbody >
    {
 othermovie?.length!=0?
 othermovie?.map((data,index)=>(
   <tr className="Player_title" key={index} >
         <td>
            <div className='d-flex gap-2'>
            <span className='moviesnamefordetails' onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 10 ? data?.movieName.substring(0, 10) + '...' : data?.movieName}</span>

            </div>
            </td>   
     <td>{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
     <td>{data?.finalScore}</td>
   
     <td className="campareicons checkcounter">
     <input type="checkbox" className='form-check-input' checked={data?.checked}  onClick={() => handleIconClickkadjustsecond(data?.movieId)}/>
       </td>
   </tr>
   )):<div className='no_dropm_found'>No movies found</div>
   }        
    </tbody>
  </table>
              </div>
            </div>
      </div>:""}
    </div>
    {!togglepickup && !toggleadjust?<div className='trading1_btns'>
    <div className='Trade_modal_btn' >
    <button className="ttrade-btns" onClick={()=>setToggleAdjust(true)}>Adjust</button>
    </div>
    <div className='Trade_modal_btn'>
  <button className="ttrade-btns" onClick={completeTrade}>Complete</button>
  </div>
  </div>:togglepickup && !toggleadjust?
  <div className='trading1_btns'>
  <div className='Trade_modal_btn' >
  <button className="ttrade-btns" onClick={()=>setTogglepickup(false) }>Pickup</button>

  </div>
  </div>: <div className='trading1_btns'>
  <div className='Trade_modal_btn' >
  <button className="ttrade-btns" onClick={()=>setToggleAdjust(false)}>Adjust</button>

  </div>
  </div>}
  </div>

  )
}

export default Tradeaccept