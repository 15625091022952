import React, { useState ,useEffect} from "react";
import "./Topnews.css";
import {movieposterpath } from "../../Config/config"
import {topnewsGet} from "../../api/handleapi";
import firstitem from "../../assets/dummy-news.png";
import { Link } from "react-router-dom";


function Topnews() {
  const [topn,setTopn]=useState([]);
  const [loadingtopn,setloadingTopn]=useState(true);


useEffect(() => {
  const TopnewFun = async () => {
    try {
      const response = await topnewsGet(); // You might want to pass some parameters to Dashboardget
      setTopn(response?.data);
      setloadingTopn(false);
    } catch (error) {
     return;
    }
  };
  TopnewFun();
    }, []);

    
const options = { 
  year: 'numeric', 
  month: 'short', 
  day: 'numeric' ,
  time:'numeric',
}

  return (
    <div className="top-main">
      <div className=" containers con_div">
        <div className="firstclass">
          <p>Top News</p>
        </div>
        {loadingtopn?
        (
        <div className="spinner-border text-primary " role="status">
<span className="visually-hidden">Loading...</span>
</div>):topn?.length>0?
        <div className="row">
        {topn?.map((item, index) => (
          <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
          <div className="row" > 
            <div className= "col-xl-6 col-lg-12 col-md-12 col-sm-12 ">  
            <div className=" rounded-start">
            <Link  className="movieheading_ele"  to={`/Newsdetails/${item?.id}`} onClick={() => window.scrollTo(0, 0)}>
               <img
                src={movieposterpath+item?.image}
                className="img-fluid"
                alt="img1"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = firstitem }
                }
              />
              
              </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div className="card-body New_C_body">

              <Link  className="movieheading_ele"  to={`/Newsdetails/${item?.id}`} onClick={() => window.scrollTo(0, 0)}>
                <h5 className="card-title topnewheading" >
               
               {item?.name.length > 60 ? item?.name.substring(0, 60) + '...' : item?.name}
              
                </h5>
                </Link>
                <Link  className="movieheading_ele"  to={`/Newsdetails/${item?.id}`} onClick={() => window.scrollTo(0, 0)}>
                 <div className="card-text top-c-t text-offw news-des-listing newsdesdata mt-4 mb-4" dangerouslySetInnerHTML={{
          __html: item?.description.replace(/<br>/g, ' ')
        }} />
        
                </Link>
                <p className="card-text ">
                  <small className="text-body-third text-offw"> 
                    
                    {new Date(item?.createdDate).toLocaleDateString('en-US', options)}
                    </small>
                  <br />
                  <small className="text-body-third text-offw">{item?.type}</small>
                </p>
              </div>
            </div>

            </div>
          </div>
        ))}
        </div>:<p className="text-white">No News found</p>}
      </div>
    </div>
  );
}

export default Topnews;
